import { Button, Form, Grid, Input, InputNumber, Row } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
// import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { CustomModal, CustomTable } from "@/components/Custom/AntdCustom";
import SquareLight from "@/components/Light/SquareLight";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { WlWocContext } from "@/pages/NewQualityControl/index";
import { getRunTb } from "@/service/api/qcAPI";
import { formatAmount } from "@/util/format";
import { FullscreenOutlined } from "@ant-design/icons";

import ModalContainer from "./ModalContainer";

// util
// import { multipleIDtoName } from "@/util/format";
// import useAPI from "@/hooks/useAPI";
// import { getProduct } from "@/service/apis/ADM/pnManage";
// import { getMgmtcat } from "@/service/apis/ADM/client";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "itemcode" ? (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
          />
        ) : (
          <InputNumber
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={0}
            style={{ width: "100%" }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  // s_arrMap = 把各種ID轉成NM所需要的資料
  const { s_arrMap, s_searchData, set_s_searchData, c_lightData, set_c_lightData, is_full } = props;
  const { s_isInspection, thisWpc } = useContext(WlWocContext);
  const call_getRunTb = useAPI(getRunTb);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_columnsType, set_s_columnsType] = useState("");

  const tableColumns_all = [
    {
      title: "序",
      dataIndex: "itemno",
      align: "center",
      width: "80px",
      // render: () => <MenuOutlined />,
    },
    {
      title: "自動判定",
      width: "100px",
      dataIndex: "qcjudge",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "合格判定",
      width: "100px",

      dataIndex: "qcresult",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗工具",
      dataIndex: "gpID",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗要點",
      dataIndex: "inspspec",
      align: "center",
      width: "200px",
    },
    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      width: "200px",
    },
    {
      title: "中值",
      dataIndex: "SL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格上限",
      dataIndex: "USL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格下限",
      dataIndex: "LSL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      align: "center",
      width: "150px",
    },
    {
      title: "單位",
      width: "100px",
      dataIndex: "UOM",
      align: "center",
    },
  ];

  const tableColumns_count = [
    {
      title: "序",
      dataIndex: "recno",
      align: "center",
      width: "80px",
      // render: () => <MenuOutlined />,
    },
    {
      title: "自動判定",
      width: "100px",

      dataIndex: "qcjudge",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "合格判定",
      width: "100px",

      dataIndex: "qcresult",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗工具",
      dataIndex: "gpID",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗要點",
      dataIndex: "inspspec",
      align: "center",
      width: "200px",
    },
  ];

  const tableColumns_measure = [
    {
      title: "序",
      dataIndex: "recno",
      align: "center",
      width: "80px",
      // render: () => <MenuOutlined />,
    },
    {
      title: "自動判定",
      width: "100px",

      dataIndex: "qcjudge",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "合格判定",
      width: "100px",

      dataIndex: "qcresult",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗工具",
      dataIndex: "gpID",
      align: "center",
      width: "200px",
    },
    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      width: "200px",
    },
    {
      title: "中值",
      dataIndex: "SL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格上限",
      dataIndex: "USL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格下限",
      dataIndex: "LSL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      align: "center",
      width: "150px",
    },
    {
      title: "單位",
      width: "150px",
      dataIndex: "UOM",
      align: "center",
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  useEffect(() => {
    if (Object.keys(thisWpc).length === 0) {
      set_s_tableData([]);
    } else {
      call_getRunTb.request(thisWpc);
    }
    set_c_lightData({});
  }, [thisWpc]);

  useEffect(() => {
    const { status, data, msg } = call_getRunTb;
    if (status === "suc") {
      console.log("call_getRunTb.data = ", data);
      set_s_tableData(
        data?.map((x, i) => ({ ...x, key: x.itemno + x.qoN, UUID: x.itemno + x.qoN }))
      );
    }
    if (status === "err") {
      console.log(msg);
    }
  }, [call_getRunTb.status]);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => {
      console.log(123);
      // call_getProduct.request({ ...s_searchData, page: 1, pageSize: 200 }),
    },
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
    onDealSearch: () => {
      // call_getProduct.request(s_searchData);
    },
  }));

  useEffect(() => {
    // call_getProduct.request(s_searchData);
    // call_getMgmtcat71.request({ pageType: "71" });
  }, [s_searchData]);
  console.log("thisWpc.lifeF = ", thisWpc.lifeF);
  return (
    <>
      <div
        className={`flex w-full justify-between items-end ${
          thisWpc.lifeF === "2" ? "" : "pointer-events-none"
        } 
        }`}
      >
        <Text level={"h5"} className="-mb-1 pl-0 ml-0 ">
          檢驗項目
        </Text>

        <div className=" flex justify-between ">
          <div className=" flex gap-3 justify-between mr-3 ">
            <Button
              className={` border-[#c44b4b] self-center  ${
                props.flag === "modal" ? "hidden" : ""
              } ${s_columnsType === "" ? "bg-[#c44b4b] text-white" : "text-[#c44b4b] bg-white"}`}
              size="middle"
              type="primary"
              // icon={<FullscreenOutlined />}
              onClick={() => {
                set_s_columnsType("");
                set_c_lightData({});
              }}
            >
              全部
            </Button>
            <Button
              className={` border-[#c44b4b] self-center  ${
                props.flag === "modal" ? "hidden" : ""
              } ${
                s_columnsType === "measure" ? "bg-[#c44b4b] text-white" : "text-[#c44b4b] bg-white"
              }`}
              size="middle"
              type="primary"
              // icon={<FullscreenOutlined />}
              onClick={() => {
                set_s_columnsType("measure");
                set_c_lightData({});
              }}
            >
              {"計量型"}
            </Button>
            <Button
              className={` border-[#c44b4b] self-center  ${
                props.flag === "modal" ? "hidden" : ""
              } ${
                s_columnsType === "count" ? "bg-[#c44b4b] text-white" : "text-[#c44b4b] bg-white"
              }`}
              size="middle"
              type="primary"
              // icon={<FullscreenOutlined />}
              onClick={() => {
                set_s_columnsType("count");
                set_c_lightData({});
              }}
            >
              {"計數型"}
            </Button>
          </div>

          <Button
            className={`bg-[#7c79d2] border-[#7c79d2] self-center  ${
              props.flag === "modal" ? "hidden" : ""
            }`}
            size="middle"
            type="primary"
            icon={<FullscreenOutlined />}
            onClick={() => {
              set_s_isShowModal(true);
            }}
          />
        </div>
      </div>

      <CustomTable
        // columns={tableColumns.filter((x) => !x.hidden)}
        columns={
          s_columnsType === "measure"
            ? tableColumns_measure
            : s_columnsType === "count"
            ? tableColumns_count
            : tableColumns_all
        }
        // dataSource={s_tableData}

        // components={components}
        // is_full 放大版使用
        scroll={{ y: is_full ? 700 : 415, x: 200 }}
        dataSource={
          Object.keys(c_lightData).length > 0
            ? [c_lightData]
            : s_columnsType === ""
            ? s_tableData
            : s_tableData.filter((x) => x.insptype === s_columnsType)
        }
        // loading={call_getProduct.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        rowClassName={(record, index) =>
          record.UUID === c_lightData?.UUID
            ? "clickRowStyle"
            : index % 2 === 1 //隔行變色
            ? "once"
            : "twoe"
        }
        onRow={(record) => {
          return {
            onClick: () => {
              console.log(record);

              return Object.keys(c_lightData).length > 0
                ? set_c_lightData({})
                : set_c_lightData(record);
            },
          };
        }}
        pagination={false}
        className={` ${thisWpc.lifeF === "2" ? "" : "pointer-events-none"} 
      }`}
      />
      <CustomModal
        title={"檢驗項目"}
        width={"90%"}
        open={s_isShowModal}
        footer={null}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          call_getRunTb.request(thisWpc);
        }}
      >
        <ModalContainer
          // s_TabKey={s_TabKey}
          // s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          // s_pf={s_pf}
        />
      </CustomModal>
    </>
  );
});

export default HD;
