import apiBasic from "../APIBasic";
import { instance } from "../handlerAPI";

export const login = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/login`, data);
};

export const logout = () => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/logout`);
};

export const initPage = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/initPage`);
};

export const getModel = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getModel`, {
    params: _params,
  });
};

// all page途單索引
export const getAllWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWpc`, {
    params: _params,
  });
};

// chooseWpcPreNextWpc途單
export const chooseWpcPreNextWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/chooseWpcPreNextWpc`, {
    params: _params,
  });
};

export const getSipHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSipHd`, { params: _params });
};

export const getSipTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSipTb`, { params: _params });
};

export const chooseSip = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/chooseSip`, data);
};

export const addRunSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRunSipHd`, data);
};

export const getRunSipHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunSipHd`, { params: _params });
};

export const getRunSipTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunSipTb`, { params: _params });
};

export const getRunSipRec = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunSipRec`, { params: _params });
};

export const addRunSipRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRunSipRec`, data);
};

export const passTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/passTb`, data);
};
export const getWpcPwcID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcPwcID`, { params: _params });
};

export const getBatchOk = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBatchOk`, {
    params: _params,
  });
};

export const getMcPDA = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMcPDA`, {
    params: _params,
  });
};

export const addBatchOk = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addBatchOk`, data);
};
