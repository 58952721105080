import { Col, Divider, InputNumber, Modal, Row, Select, Space, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MyButton from "@/components/Button";
import CustomTable from "@/components/Custom/CustomTable.jsx";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import {
  addNg,
  addOk,
  getAllNg,
  getAllNgItem,
  getAllOk,
  getAllOkItem,
} from "@/service/api/mcAPI.js";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const MC = ({ thisWpc, call_getAllWpc }) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const call_getAllOkItem = useAPI(getAllOkItem);
  const call_addOk = useAPI(addOk);
  const call_getAllOk = useAPI(getAllOk);

  const call_getAllNgItem = useAPI(getAllNgItem);
  const call_addNg = useAPI(addNg);
  const call_getAllNg = useAPI(getAllNg);

  const [s_okItemOptions, set_s_okItemOptions] = useState([]);
  // 已登錄良品
  const [s_okRecordList, set_s_okRecordList] = useState([]);
  // 要送出的資料
  const [s_okCheckProducts, set_s_okCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });

  const [s_ngItemOptions, set_s_ngItemOptions] = useState([]);
  // 已登錄良品
  const [s_ngRecordList, set_s_ngRecordList] = useState([]);
  // 要送出的資料
  const [s_ngCheckProducts, set_s_ngCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });

  const okProductsConfirm = (e) => {
    if (s_okCheckProducts.count <= 0) {
      // message.error("數量不得小於0");
      message.error(t("WOS.error.countLessZero"));
      return;
    } else if (s_okCheckProducts.product === "") {
      // message.error("品項不得為空");
      message.error(t("WOS.error.productEmpty"));
      return;
    } else if (s_okCheckProducts.shift === "") {
      // message.error("班別不得為空");
      message.error(t("WOS.error.shiftEmpty"));
      return;
    } else {
      Modal.confirm({
        // title: <Text level="h4">確定進行良品登錄？</Text>,
        title: <Text level="h4">{t("WOS.mc.okitemNM") + t("util.util.register")}？</Text>,
        // content: <Text level="h4">請先確認輸入的數值無誤，再進行登錄</Text>,
        content: <Text level="h4">{t("WOS.util.checkrRgister")}</Text>,
        okText: t("util.util.ok"), // 確認
        okType: "primary",
        cancelText: t("util.util.cancel"), // 取消
        centered: true,
        width: 600,
        onOk() {
          call_addOk.request({
            UUID: thisWpc.UUID,
            woN: thisWpc.woN,
            itemID: s_okCheckProducts.product,
            okC: e === "add" ? s_okCheckProducts.count : s_okCheckProducts.count * -1,
            shift: s_okCheckProducts.shift,
          });
        },
      });
    }
  };

  const ngProductsConfirm = (e) => {
    if (s_ngCheckProducts.count <= 0) {
      // message.error("數量不得小於0");
      message.error(t("WOS.error.countLessZero"));
      return;
    } else if (s_ngCheckProducts.product === "") {
      // message.error("品項不得為空");
      message.error(t("WOS.error.productEmpty"));
      return;
    } else if (s_ngCheckProducts.shift === "") {
      // message.error("班別不得為空");
      message.error(t("WOS.error.shiftEmpty"));
      return;
    } else {
      Modal.confirm({
        // title: <Text level="h4">確定進行不良品登錄？</Text>,
        title: <Text level="h4">{t("WOS.mc.ngitemNM") + t("util.util.register")}？</Text>,
        // content: <Text level="h4">請先確認輸入的數值無誤，再進行登錄</Text>,
        content: <Text level="h4">{t("WOS.util.checkrRgister")}</Text>,
        okText: t("util.util.ok"), // 確認
        okType: "primary",
        cancelText: t("util.util.cancel"), // 取消
        centered: true,
        width: 600,
        onOk() {
          call_addNg.request({
            UUID: thisWpc.UUID,
            woN: thisWpc.woN,
            itemID: s_ngCheckProducts.product,
            ngC: e === "add" ? s_ngCheckProducts.count : s_ngCheckProducts.count * -1,
            shift: s_ngCheckProducts.shift,
          });
        },
      });
    }
  };

  useEffect(() => {
    call_getAllOkItem.request();
    call_getAllOk.request(thisWpc);
    call_getAllNgItem.request();
    call_getAllNg.request(thisWpc);
  }, []);

  useEffect(() => {
    if (call_getAllOkItem.status === "suc") {
      set_s_okItemOptions(
        call_getAllOkItem.data.map((x) => ({
          label: x.itemNM,
          value: x.itemID,
        }))
      );
    }
  }, [call_getAllOkItem.status]);

  useEffect(() => {
    if (call_addOk.status === "suc") {
      call_getAllOk.request(thisWpc);
    } else if (call_addOk.status === "err") {
      message.error(call_addOk.msg);
    }
  }, [call_addOk.status]);

  useEffect(() => {
    if (call_getAllOk.status === "suc") {
      set_s_okRecordList(
        call_getAllOk.data?.map((x) => {
          return { ...x, key: x.cumT };
        })
      );
      // call_getAllWpc();
    }
  }, [call_getAllOk.status]);

  useEffect(() => {
    if (call_getAllNgItem.status === "suc") {
      set_s_ngItemOptions(
        call_getAllNgItem.data.map((x) => ({
          label: x.itemNM,
          value: x.itemID,
        }))
      );
    }
  }, [call_getAllNgItem.status]);

  useEffect(() => {
    if (call_addNg.status === "suc") {
      call_getAllNg.request(thisWpc);
    } else if (call_addNg.status === "err") {
      message.error(call_addNg.msg);
    }
  }, [call_addNg.status]);

  useEffect(() => {
    if (call_getAllNg.status === "suc") {
      set_s_ngRecordList(
        call_getAllNg.data?.map((x) => {
          return { ...x, key: x.cumT };
        })
      );
      call_getAllWpc();
    }
  }, [call_getAllNg.status]);

  const okProductCol = [
    {
      title: t("WOS.mc.okitemNM"), // 良品項
      dataIndex: "itemNM",
      align: "center",
    },
    {
      title: t("WOS.util.shift"), // 班別
      dataIndex: "shift",
      align: "center",
      render: (text) => t(`WOS.util.shift_${text}`),
    },
    {
      title: t("util.util.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      render: (num) =>
        num > 0 ? <span>{num}</span> : <span style={{ color: "#d86363" }}>{num}</span>,
    },
    {
      title: t("util.util.lifeFT"), // 登錄時間
      dataIndex: "lifeFT",
      align: "center",
      width: "200px",
      render: (item) => moment(item).format("yyyy-MM-DD HH:mm"),
    },
  ];

  const ngProductCol = [
    {
      title: t("WOS.mc.ngitemNM"), // 不良品項
      dataIndex: "itemNM",
      align: "center",
    },
    {
      title: t("WOS.util.shift"), // 班別
      dataIndex: "shift",
      align: "center",
      render: (text) => t(`WOS.util.shift_${text}`),
    },
    {
      title: t("util.util.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      render: (num) =>
        num > 0 ? <span>{num}</span> : <span style={{ color: "#d86363" }}>{num}</span>,
    },
    {
      title: t("util.util.lifeFT"), // 登錄時間
      dataIndex: "lifeFT",
      align: "center",
      width: "200px",
      render: (item) => moment(item).format("yyyy-MM-DD HH:mm"),
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Text level="h2" mark>
          {/* 已登錄之良品品項 */}
          {t("WOS.mc.okProduct")}
        </Text>
      </Col>

      <Col span={24}>
        <Row gutter={[0, 0]}>
          <Col span={8}>
            <Space direction="vertical" className="w-full px-2">
              <Text level="h4">
                {/* 班別 */}
                {t("WOS.util.shift")}
              </Text>
              <Select
                size="large"
                className="w-full"
                value={s_okCheckProducts.shift}
                onChange={(e) => {
                  set_s_okCheckProducts({
                    ...s_okCheckProducts,
                    shift: e,
                  });
                }}
              >
                <Option value="D">
                  {/* 日班 */}
                  {t("WOS.util.shift_D")}
                </Option>
                <Option value="N">
                  {/* 夜班 */}
                  {t("WOS.util.shift_N")}
                </Option>
              </Select>
              <Text level="h4" style={{ display: "block" }}>
                {/* 良品品項 */}
                {t("WOS.mc.okitemNM")}
              </Text>
              <Select
                size="large"
                style={{ width: "100%" }}
                value={s_okCheckProducts.product}
                options={s_okItemOptions}
                onChange={(e) => {
                  set_s_okCheckProducts({
                    ...s_okCheckProducts,
                    product: e,
                  });
                }}
              />

              <Text level="h4" style={{ display: "block" }}>
                {/* 良品數量 */}
                {t("util.util.okC")}
              </Text>
              <InputNumber
                size="large"
                value={s_okCheckProducts.count}
                min={1}
                onChange={(e) => {
                  set_s_okCheckProducts({ ...s_okCheckProducts, count: e });
                }}
                style={{ width: "100%" }}
              />
              <Space size={20}>
                <MyButton
                  height={40}
                  width={135}
                  onClick={() => okProductsConfirm("add")}
                  loading={call_addOk.status === "load"}
                >
                  <Text level="h3">
                    <PlusOutlined />
                  </Text>
                </MyButton>
                <MyButton
                  height={40}
                  width={135}
                  color="#ff4d4f"
                  onClick={() => okProductsConfirm("reduce")}
                  loading={call_addOk.status === "load"}
                >
                  <Text level="h3">
                    <MinusOutlined />
                  </Text>
                </MyButton>
              </Space>
            </Space>
          </Col>
          <Col span={16}>
            <CustomTable
              columns={okProductCol}
              dataSource={s_okRecordList}
              scroll={{ y: 240 }}
              size="small"
              rowClassName={(record, index) =>
                index % 2 === 1 //隔行變色
                  ? "once"
                  : "twoe"
              }
              pagination={{
                showTotal: (total, range) => (
                  <Text level="h5" vice>
                    {t("util.util.showTotal", {
                      total: total,
                      rangeFirst: range[0],
                      rangeEnd: range[1],
                    })}
                    {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                  </Text>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        <Text level="h2" mark>
          {/* 已登錄之良品品項 */}
          {t("WOS.mc.noProduct")}
        </Text>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 0]}>
          <Col span={8}>
            <Space direction="vertical" className="w-full px-2">
              <Text level="h4">
                {/* 班別 */}
                {t("WOS.util.shift")}
              </Text>
              <Select
                size="large"
                className="w-full"
                value={s_ngCheckProducts.shift}
                onChange={(e) => {
                  set_s_ngCheckProducts({
                    ...s_ngCheckProducts,
                    shift: e,
                  });
                }}
              >
                <Option value="D">
                  {/* 日班 */}
                  {t("WOS.util.shift_D")}
                </Option>
                <Option value="N">
                  {/* 夜班 */}
                  {t("WOS.util.shift_N")}
                </Option>
              </Select>
              <Text level="h4" style={{ display: "block" }}>
                {/* 良品品項 */}
                {t("WOS.mc.ngitemNM")}
              </Text>
              <Select
                size="large"
                style={{ width: "100%" }}
                value={s_ngCheckProducts.product}
                options={s_ngItemOptions}
                onChange={(e) => {
                  set_s_ngCheckProducts({
                    ...s_ngCheckProducts,
                    product: e,
                  });
                }}
              />

              <Text level="h4" style={{ display: "block" }}>
                {/* 良品數量 */}
                {t("util.util.ngC")}
              </Text>
              <InputNumber
                size="large"
                value={s_ngCheckProducts.count}
                min={1}
                onChange={(e) => {
                  set_s_ngCheckProducts({ ...s_ngCheckProducts, count: e });
                }}
                style={{ width: "100%" }}
              />
              <Space size={20}>
                <MyButton
                  height={40}
                  width={135}
                  onClick={() => ngProductsConfirm("add")}
                  loading={call_addNg.status === "load"}
                >
                  <Text level="h3">
                    <PlusOutlined />
                  </Text>
                </MyButton>
                <MyButton
                  height={40}
                  width={135}
                  color="#ff4d4f"
                  onClick={() => ngProductsConfirm("reduce")}
                  loading={call_addNg.status === "load"}
                >
                  <Text level="h3">
                    <MinusOutlined />
                  </Text>
                </MyButton>
              </Space>
            </Space>
          </Col>
          <Col span={16}>
            <CustomTable
              columns={ngProductCol}
              dataSource={s_ngRecordList}
              scroll={{ y: 240 }}
              size="small"
              rowClassName={(record, index) =>
                index % 2 === 1 //隔行變色
                  ? "once"
                  : "twoe"
              }
              pagination={{
                showTotal: (total, range) => (
                  <Text level="h5" vice>
                    {t("util.util.showTotal", {
                      total: total,
                      rangeFirst: range[0],
                      rangeEnd: range[1],
                    })}
                    {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                  </Text>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MC;
