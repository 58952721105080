import apiBasic from "../APIBasic";
import { instance } from "../handlerAPI";

export const pass = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/pass`, data);
};

export const waive = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/waive`, data);
};

export const confirm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/confirm`, data);
};

export const cancel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/cancel`, data);
};

// ----------------------------- 2. -----------------------------
// 取得檢驗單
export const getQo = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQo`, {
    params: _params,
  });
};
// 新增檢驗單
export const addQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQo`, data);
};
// 開始檢驗
export const openQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/openQo`, data);
};
// 刪除檢驗單
export const deleteQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQo`, data);
};
// 更新檢驗單
export const updateQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQo`, data);
};
// 新增檢驗單使用的工單
export const getWo = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWo`, {
    params: _params,
  });
};
// 新增檢驗單取得的表頭
export const getSipHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSipHd`, {
    params: _params,
  });
};
// 新增檢驗單取得的表身
export const getSipTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSipTb`, {
    params: _params,
  });
};
// 檢驗索引
export const getRunTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunTb`, {
    params: _params,
  });
};
// 檢驗記錄
export const getRunRec = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunRec`, {
    params: _params,
  });
};

// 新增檢驗紀錄
export const addQoRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQoRec`, data);
};

// 修改檢驗紀錄的檢驗結果
export const qcresultQoRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qcresultQoRec`, data);
};
// 完成檢驗
export const closeQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/closeQo`, data);
};
// 最終判定
export const finalQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/finalQo`, data);
};
// 直控合格 or 特採 or 隔離 or 解除隔離
export const qoResultQo = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qoResultQo`, data);
};
