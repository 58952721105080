import { Button, Col, Divider, Form, Input, Row, Select, message } from "antd";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { getWlID } from "@/service/api/dispachManage";
import { getAbnList, report } from "@/service/api/wosAPI";

const { Option } = Select;
const { TextArea } = Input;

export default function Report({ thisWpc, setIsModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_getAbnList = useAPI(getAbnList);

  const call_report = useAPI(report);

  const call_getWlID = useAPI(getWlID);

  const submit = async () => {
    const formData = await form.validateFields();
    call_report.request({ ...thisWpc, ...formData });
  };

  useEffect(() => {
    call_getAbnList.request();
    call_getWlID.request();
  }, []);

  useEffect(() => {
    if (call_getAbnList.status === "err") {
      message.error(call_getAbnList.msg);
    }
  }, [call_getAbnList.status]);

  useEffect(() => {
    if (call_report.status === "suc") {
      message.success(call_report.msg);
      setIsModal(false);
    } else if (call_report.status === "err") {
      message.error(call_report.msg);
    }
  }, [call_report.status]);

  useEffect(() => {
    if (call_getWlID.status === "err") {
      message.error(call_getWlID.msg);
    }
  }, [call_getWlID.status]);

  return (
    <Row>
      <Col span={24}>
        <Form form={form} name="abnForm" layout="vertical" size="large">
          <Form.Item name="situF" hidden />
          <Row gutter={[24, 0]}>
            <Col span={6}>
              <Form.Item label={t("util.util.wlID")} name="wlID" rules={[{ required: true }]}>
                <Select
                  showSearch
                  options={
                    call_getWlID?.data?.map((x) => ({
                      label: x.wlID + "_" + x.wlNM,
                      value: x.wlID,
                    })) || []
                  }
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("WOS.util.rptID")}
                name="eventID"
                hasFeedback
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  options={
                    call_getAbnList?.data?.map((x) => ({
                      label: x.eventID + "_" + x.eventNM,
                      value: x.eventID,
                      data: x,
                    })) || []
                  }
                  onChange={(_, data) => form.setFieldsValue(data.data)}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={t("WOS.util.rptUser")}
                name="rptUser"
                initialValue={Cookies.get("userName")}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t("util.util.note")} name="note" initialValue={""}>
                <TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Button
          type="primary"
          size="large"
          loading={call_report.status === "load"}
          onClick={submit}
        >
          {t("util.util.ok")}
          {/* 確認 */}
        </Button>
      </Col>
    </Row>
  );
}
