import { instance } from "../handlerAPI";
import apiBasic from "../APIBasic";

export const getMoldID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMoldID`, {
    params: _params,
  });
};

export const getRun00wl = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRun00wl`, {
    params: _params,
  });
};

export const getWsID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWsID`, {
    params: _params,
  });
};

export const getWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpc`, {
    params: _params,
  });
};

export const wpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/wpc`, data);
};

export const getWlID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWlID`, {
    params: _params,
  });
};

export const getPwcID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPwcID`, {
    params: _params,
  });
};
export const wpcMoldID = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/wpcMoldID`, data);
};
