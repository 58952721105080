import { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Divider,
  Space,
  Button,
  message,
  Modal,
  Select,
  InputNumber,
  Alert,
  Spin,
} from "antd";
import styled from "styled-components";

import Cookies from "js-cookie";

import checkUndefined from "@/util/checkUndefined";
import Text from "@/components/Text";
import { CustomTable, CustomModal } from "@/components/Custom/AntdCustom";
import { getWpc, sfcWpc } from "@/service/api/wosAPI";
import { getWpcPwcID } from "@/service/api/publicAPI";
import useAPI from "@/hooks/useAPI";
import { getWlID } from "@/service/api/dispachManage";
import { wpc, restart } from "@/service/api/mcAPI/";
import { getAllStaffs } from "@/service/api/wosAPI";
import { getMoldID } from "@/service/api/dispachManage";
import { getAllWpc } from "@/service/api/publicAPI";
import { IconScanBarcode, IconArrow } from "@/components/Icon";
import { open } from "@/service/api/wosAPI";

import DetailsButton from "@/components/DetailsButton";
import WpcDetailModal from "@/components/WpcList/WpcDetailModal";
import { useTranslation } from "react-i18next";

const CustomSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 20px;
    /* padding-top: 12px !important; */
  }
  /* .ant-select-selector {
    height: 60px !important;
  } */
  .ant-select-selection-search-input {
    /* padding-top: 25px !important; */
    font-size: 20px;
  }
`;

const CustomSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-lg {
    border: none !important;
  }
  .ant-input .ant-input-lg {
  }
  .anticon.anticon-close-circle svg {
    width: 22px !important;

    height: 22px !important;
    color: #3d3d3d;
  }
  .anticon.anticon-close-circle {
    display: block;
    width: 20px !important;

    height: 20px !important;
  }
  .ant-input-suffix {
    position: absolute;
    right: 40px;
    top: 17%;
    color: red;
    /* width: 100px; */
    /* height: 100px; */
    font-size: 30px;
    z-index: 9999;
  }
  .ant-input-lg {
    font-size: 31px !important;
    border: none !important;
    padding-left: 4px !important;
    padding-right: 100px !important;
  }
  .ant-input {
    padding-left: 20px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
    display: none;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .ant-input-wrapper:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper::before {
    border: none !important;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-input {
    background-color: #eb8282 !important;
    border-radius: 9999px !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;
const initBarcodeObj = {
  wpcType: null,
  queryString: null,
  opcode: null,
};
export default function WpcLogin({ setIsModal, initSearch = {} }) {
  const { Search } = Input;
  const { t } = useTranslation();
  const searchRef = useRef(null);

  const [s_enterText, set_s_enterText] = useState("");

  const [s_alertBgc, set_s_alertBgc] = useState("bg-[#73cc7f]");

  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );
  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  // show
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState(initBarcodeObj);

  const [s_isCheckModal, set_s_isCheckModal] = useState(false); // 是否顯示檢查對話框的狀態

  // { moldID: "", moldcavity: null, stdmh: null }
  const [tableData, setTableData] = useState([]); // 表格資料
  const [s_ws, set_s_ws] = useState({ wsID: "", wsNM: "" });
  const [s_wlID, set_s_wlID] = useState(null);
  const [s_mold, set_s_mold] = useState(null);
  const [s_cardID, set_s_cardID] = useState([]);
  const [s_isMultiModal, set_s_isMultiModal] = useState(false);
  const [s_isWpcRestartModal, set_isWpcRestartModal] = useState(false);
  // 是否顯示WPC詳細資料對話框
  const [s_thisWpc, set_s_thisWpc] = useState({});

  const subTitleFontSize = "text-[28px]";
  const call_getWpc = useAPI(getWpc); // 呼叫getWpc API的自訂hook

  const call_getWlID = useAPI(getWlID);
  const call_getAllStaffs = useAPI(getAllStaffs);
  const call_getMoldID = useAPI(getMoldID); //取的模具下拉選單
  const call_getAllWpc = useAPI(getAllWpc);
  const call_wpc = useAPI(wpc);
  const call_restart = useAPI(restart);
  const call_open = useAPI(open);

  const wpcCheck = () => {
    const payloadCardID = s_cardID ? s_cardID.join("|") : s_cardID;

    if (payloadCardID === "") {
      message.error(t("util.util.placeholder_select") + t("WOS.wpc-printer.jobNumber"));
      return;
    }
    set_s_isCheckModal(true);
  }; // 設定顯示檢查對話框的狀態

  useEffect(() => {
    searchRef.current.focus();

    call_getWlID.request({});
    call_getAllStaffs.request({});

    const wsID = Cookies.get("wsID");
    const wsNM = Cookies.get("wsNM");
    set_s_ws({ wsID, wsNM });
  }, []);

  useEffect(() => {
    if (call_restart.status === "suc") {
      message.success(call_restart.msg);
      set_isWpcRestartModal(false);
      set_s_isCheckModal(false);
      set_s_thisWpc({});
      set_s_mold(null);
    } else if (call_restart.status === "err") {
      message.error(call_restart.msg);
      set_isWpcRestartModal(false);
      set_s_thisWpc({});
      set_s_wlID(null);
      set_s_mold(null);
      set_s_isCheckModal(false);
    }
  }, [call_restart.status]);

  useEffect(() => {
    if (call_wpc.status === "suc") {
      message.success(call_wpc.msg);
      set_s_thisWpc({});
      set_s_isCheckModal(false);

      set_s_wlID(null);
      set_s_mold(null);
      set_s_isMultiModal(false);
      set_isWpcRestartModal(false);
      searchRef.current.focus();
    } else if (call_wpc.status === "err") {
      message.error(call_wpc.msg);

      if (call_wpc.data === "multi") {
        set_s_isMultiModal(true);
        return;
      }

      if (call_wpc.data === "restart") {
        set_isWpcRestartModal(true);
        return;
      }
      set_s_thisWpc({});
      set_s_wlID(null);
      set_s_mold(null);
      set_s_isCheckModal(false);
      set_s_isMultiModal(false);
      searchRef.current.focus();
    }
  }, [call_wpc.status]);

  useEffect(() => {
    if (call_open.status === "suc") {
      message.success(call_open.msg);
      set_s_thisWpc({});
      set_s_isCheckModal(false);
      set_s_wlID(null);
      set_s_mold(null);
      searchRef.current.focus();
    } else if (call_open.status === "err") {
      message.error(call_open.msg);
      set_s_thisWpc({});
      set_s_wlID(null);
      set_s_isCheckModal(false);
    }
  }, [call_open.status]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      message.success(call_getAllWpc.msg);

      set_s_alertBgc("bg-[#73cc7f] ");

      set_s_alertMsg(call_getAllWpc.msg);
      // console.log(call_getAllWpc.data.tableData[0]);
      set_s_thisWpc(call_getAllWpc.data.tableData[0]);
      call_getMoldID.request(call_getAllWpc.data.tableData[0]);

      set_s_wlID(call_getAllWpc.data.tableData[0].wlID);
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表
      setTableData([]);
      message.error(call_getAllWpc.msg);

      set_s_alertBgc("bg-[#eb8282] ");
    }
  }, [call_getAllWpc.status]);
  useEffect(() => {
    if (call_getMoldID.status === "suc") {
      message.success(call_getMoldID.msg);
      const moldData = call_getMoldID.data?.find((item) => item.moldID === s_thisWpc.moldID);
      console.log(moldData);
      if (moldData) {
        set_s_mold(
          `${moldData.moldID}@${moldData.moldNM}@${moldData.moldcavity}@${moldData.stdmh}`
        );
      } else {
        set_s_mold(null);
      }
    } else if (call_getMoldID.status === "err") {
      message.error(call_getMoldID.msg);

      set_s_mold(null);
    }
  }, [call_getMoldID.status]);

  // 這邊的主要是對條碼進行解析和處理
  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_alertBgc("bg-[#eb8282] ");
        set_s_barcodeObj(initBarcodeObj);
        // set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode || Object.keys(parseBarcode).length === 0) {
        //非工單掃碼

        set_s_alertMsg(`
          ${t("WOS.wlwoc.noWoNScan")}
          ，${s_showBarcode}`);
        set_s_alertBgc("bg-[#eb8282] ");
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // call_barcodeOperationWpc.request({ ...s_searchData, ...parseBarcode });
      // 設定 s_alertMsg 為 s_showBarcode 的值
      // set_s_alertMsg(
      //   `${t("util.util.woN")}：${parseBarcode.woN ?? t("WOS.util.none")}
      //       ，${t("util.util.PN")}：${parseBarcode.PN ?? t("WOS.util.none")}，${t(
      //     "WOS.util.batchID"
      //   )}：${
      //     parseBarcode.batchID ?? t("WOS.util.none")
      //     //無
      //   }，
      //     ${t("WOS.util.batchbom")}
      //     ：${parseBarcode.batchbom ?? t("WOS.util.none")}`
      // );

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));

      set_s_alertBgc("bg-[#73cc7f] ");
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
        ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      set_s_alertBgc("bg-[#eb8282] ");
      // set_s_tableData([]);
    }
  }, [is_barcodeInput]);

  useEffect(() => {
    if (!s_barcodeObj.jobN) return;

    // console.log(s_barcodeObj);
    // console.log({
    //   wpcType: "",
    //   queryString: s_barcodeObj.jobN,
    //   opcode: s_barcodeObj.opcode,
    // });
    call_getAllWpc.request({
      wpcType: "woc",
      queryString: s_barcodeObj.jobN,
      opcode: s_barcodeObj.opcode,
    });
  }, [s_barcodeObj]);

  return (
    <Row gutter={[12, 6]}>
      <Col span={24}>
        <CustomSearch
          ref={searchRef}
          autoFocus
          size="large"
          allowClear
          placeholder={s_focusText}
          suffix={<IconScanBarcode className="text-[26px]" />}
          value={s_enterText}
          onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
          onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
          onChange={(e) => {
            set_s_enterText(e.target.value);
          }}
          onSearch={() => {
            set_s_enterText("");
            set_s_showBarcode(s_enterText);
            set_is_barcodeInput(true);
          }}
        />
      </Col>
      <Col span={24} className="mb-5">
        <Row className=" w-full  mb-5">
          <div className={` w-full  ${s_alertBgc}`}>
            <Text
              level="h3"
              type="error"
              style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
            >
              <span className="text-[#6C6C6C] font-bold">{t("util.util.systemReport")}:</span>
              {s_thisWpc.lifeF ? ` ${t(`WOS.util.lifeF_${s_thisWpc.lifeF}`)},` : ""}
              {s_alertMsg}
            </Text>
          </div>
        </Row>

        <Row className="bg-[#F0F9FF]">
          <Col span={12} className="flex  w-full ">
            <Row className="w-full flex ">
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "WOS.util.wo"
                    )}`}</div>
                    <div className="whitespace-normal   text-[30px]"> :</div>
                  </div>
                  <span className="text-black  self-center text-[25px] font-bold  ">
                    {s_thisWpc.woN ? `${s_thisWpc.woN} ${s_thisWpc.opcode}` : ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "WOS.util.PN"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black self-center text-[25px] font-bold  ">
                    {s_thisWpc.PN ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24} className=" ">
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "WOS.util.custPN"
                    )} `}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#39883d] self-center text-[25px] font-bold  ">
                    {s_thisWpc.dealPN ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24} className=" ">
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "WOS.util.pdtNM"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#3eb390]   w-[50%]  self-center text-[25px] font-bold  ">
                    {s_thisWpc.pdtNM ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "WOS.util.planqty"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#3e4ab3] self-center text-[25px] font-bold  ">
                    {s_thisWpc.planqty ? `${s_thisWpc.planqty}` : ""}
                  </span>
                </div>
              </Col>
            </Row>
            <Col>
              <Divider type="vertical" className="border-[#A9A9A9] h-[90%] mt-4 " />
            </Col>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Text level="h1" className="whitespace-normal text-[30px]">
                  {/* {`${t("util.util.PN")}  :`}&nbsp;&nbsp; */}
                  {``}&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-black ">{""}</span>
                </Text>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">
                      {`${t("WOS.util.ws")}`}
                    </div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black  self-center text-[25px] font-bold  ">
                    {Object.keys(s_thisWpc).length > 0
                      ? `${s_ws.wsID} 
                    ${s_ws.wsNM || ""} 
            
                    `
                      : ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">
                      {`${t("WOS.util.We")}`}
                    </div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black self-center text-[25px] font-bold  ">
                    {s_thisWpc.wlID ? `${s_thisWpc.wlID} ${s_thisWpc.wlNM}` : ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">
                      {`${t("WOS.util.mold")}`}
                    </div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black self-center text-[25px] font-bold  ">
                    {`${s_thisWpc.moldID || ""} ${s_thisWpc.moldNM || ""}`}
                  </span>
                </div>
              </Col>
              <Col span={24} className="">
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap    w-[80%] ml-2 font-bold  text-[30px]">
                      {`${t("WOS.util.pwc")}`}
                    </div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black self-center text-[25px] font-bold  ">
                    {s_thisWpc.pwcID ?? ""}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <div className="text-[24px] mb-3">{" SCAN Operator :"}</div>
            <div className="flex  ">
              <CustomSelect
                placeholder={t("util.util.placeholder_input") + t("WOS.wpc-printer.jobNumber")}
                optionFilterProp="label"
                size="large"
                showSearch
                allowClear
                mode="multiple"
                className="w-full"
                // value={s_cardID.cardID}
                onChange={(cardID, data) => {
                  set_s_cardID(cardID);
                }}
                options={
                  call_getAllStaffs.data?.map((x) => ({
                    label: x.cardID + "_" + x.peopleNM,
                    value: x.cardID,
                    data: x,
                  })) || []
                }
              />
              <div className="flex items-center ml-1">
                <IconScanBarcode className="text-[26px]" />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="text-[24px] mb-3">{" SCAN Machine :"}</div>
            <div className="flex">
              <CustomSelect
                placeholder={
                  t("util.util.placeholder_select") + t("ADM.production-line-manage.wlID")
                }
                showSearch
                allowClear
                optionFilterProp="label"
                size="large"
                className="w-full"
                value={s_wlID}
                onChange={(wlID, data) => {
                  set_s_wlID(wlID);
                }}
                options={
                  call_getWlID?.data?.map((x) => ({
                    label: x.wlID + "_" + x.wlNM,
                    value: x.wlID,
                    data: x,
                  })) || []
                }
              />
              <div className="flex items-center ml-1">
                <IconScanBarcode className="text-[26px]" />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="text-[24px] mb-3">{" SCAN Mold :"}</div>
            <div className="flex">
              <Select
                placeholder={t("util.util.placeholder_select") + t("util.util.mold")}
                showSearch
                allowClear
                filterOption={(inputValue, option) =>
                  (option.label + option.value).includes(inputValue)
                }
                value={s_mold}
                size="large"
                className="w-full"
                options={
                  call_getMoldID?.data?.map((x) => ({
                    label: `${x.moldID}-${x.moldNM}-CAV:${x.moldcavity}-CT:${x.stdmh}`,
                    value: `${x.moldID}@${x.moldNM}@${x.moldcavity}@${x.stdmh}`,
                    data: x,
                  })) || []
                }
                onChange={(value) => {
                  set_s_mold(value);
                  return;
                }}
              />
              <div className="flex items-center ml-1">
                <IconScanBarcode className="text-[26px]" />
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      <Divider />
      <Col span={24} className="text-right">
        <Space size={20}>
          <Button
            type="primary"
            size="large"
            className="h-[50px]"
            disabled={
              !(Object.keys(s_thisWpc).length !== 0 && ["1", "8"].includes(s_thisWpc.lifeF))
            }
            loading={call_getWpc.status === "load"}
            onClick={wpcCheck}
          >
            <Text level="h4">{"WO OPEN"}</Text>
            {/* 確認 */}
          </Button>
        </Space>
      </Col>
      {/* //共模彈窗 */}
      <CustomModal
        key="s_isMultiModal"
        title={"Warning"}
        // title="途單索引詳情"
        visible={s_isMultiModal}
        onCancel={() => set_s_isMultiModal(false)}
        footer={null}
        width="30%"
      >
        <div className="flex justify-center">
          <Spin spinning={call_wpc.status === "load"}>
            <Col span={24} className="text-right">
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="w-[150px] bg-red-500"
                  // disabled={s_chooseData.qty === 0}
                  loading={call_wpc.status === "load"}
                  onClick={() => {
                    set_s_isMultiModal(false);
                  }}
                >
                  {"CANCEL"}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="w-[160px] "
                  // disabled={s_chooseData.qty === 0}
                  loading={call_wpc.status === "load"}
                  onClick={() => {
                    if (!s_barcodeObj.opcode) return;

                    if (s_barcodeObj.opcode === "3310") {
                      let payload_mold = {};
                      if (s_mold) {
                        const [moldID, moldNM, moldcavity, stdmh] = s_mold.split("@");
                        payload_mold = {
                          moldID,
                          moldcavity: Number(moldcavity),
                          stdmh: Number(stdmh),
                        };
                      } else {
                        payload_mold = { moldID: "", moldcavity: null, stdmh: null };
                      }

                      call_wpc.request([
                        { ...s_thisWpc, wlID: s_wlID, multi: true, ...payload_mold },
                      ]);
                    }
                  }}
                >
                  {"OPEN CO-MOLD"}
                </Button>
              </Space>
            </Col>
            <Space size={0}></Space>
          </Spin>
        </div>
        {/* <WpcDetailModal setIsModal={setIsWpcDatailModal} thisWpc={loginWpc} /> */}
      </CustomModal>
      {/* //重啟彈窗 */}

      <CustomModal
        key="isWpcRestartModal"
        title={"Warning"}
        visible={s_isWpcRestartModal}
        onCancel={() => set_isWpcRestartModal(false)}
        footer={null}
        width="30%"
      >
        <div className="flex justify-center">
          <Spin spinning={call_wpc.status === "load"}>
            <Col span={24} className="text-right">
              <Space size={20}>
                <Button
                  type="primary"
                  size="large"
                  className="w-[150px] bg-red-500"
                  // disabled={s_chooseData.qty === 0}
                  loading={call_restart.status === "load"}
                  onClick={() => {
                    set_isWpcRestartModal(false);
                  }}
                >
                  {"CANCEL"}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="w-[160px] "
                  // disabled={s_chooseData.qty === 0}
                  loading={call_restart.status === "load"}
                  onClick={() => {
                    if (!s_barcodeObj.opcode) return;

                    if (s_barcodeObj.opcode === "3310") {
                      // call_restart.request()
                      let payload_mold = {};
                      if (s_mold) {
                        const [moldID, moldNM, moldcavity, stdmh] = s_mold.split("@");
                        payload_mold = {
                          moldID,
                          moldcavity: Number(moldcavity),
                          stdmh: Number(stdmh),
                        };
                      } else {
                        payload_mold = { moldID: "", moldcavity: null, stdmh: null };
                      }
                      call_restart.request({ ...s_thisWpc, wlID: s_wlID, ...payload_mold });
                    }
                  }}
                >
                  {"RESTART"}
                </Button>
              </Space>
            </Col>
            <Space size={0}></Space>
          </Spin>
        </div>
        {/* <WpcDetailModal setIsModal={setIsWpcDatailModal} thisWpc={loginWpc} /> */}
      </CustomModal>
      {/* //一般報工彈窗 */}
      <CustomModal
        key="prepare"
        onCancel={() => set_s_isCheckModal(false)}
        footer={null}
        title={
          <Text level="h3">
            {t("WOS.wlwoc.isRegisterTheWoN")}?{/* 是否對該筆工單進行登錄？ */}
          </Text>
        }
        visible={s_isCheckModal}
        width="600px"
        // onCancel={() => set_s_isCheckModal(false)}
      >
        <div>
          <Spin spinning={call_wpc.status === "load"}>
            <Space size={0}>
              <Text level="h2" className="inline">
                {t("WOS.wlwoc.openqty")}：
                <span className="text-red-500">
                  {checkUndefined(s_thisWpc.planqty + s_thisWpc.prefixplanqty - s_thisWpc.wpcC)}
                </span>
              </Text>
            </Space>
            <Divider />
            <Col span={24} className="text-right">
              <Space size={20}>
                {/* <Button size="large" onClick={() => setIsModal(false)}>
            {t("util.util.cancel")}
   
          </Button> */}
                <Button
                  type="primary"
                  size="large"
                  className="w-[150px] "
                  // disabled={s_chooseData.qty === 0}
                  loading={call_wpc.status === "load"}
                  onClick={() => {
                    console.log({ ...s_thisWpc, wlID: s_wlID, ...s_mold });
                    if (!s_barcodeObj.opcode) return;

                    if (s_barcodeObj.opcode === "3310") {
                      let payload_mold = {};
                      if (s_mold) {
                        const [moldID, moldNM, moldcavity, stdmh] = s_mold.split("@");
                        payload_mold = {
                          moldID,
                          moldcavity: Number(moldcavity),
                          stdmh: Number(stdmh),
                        };
                      } else {
                        payload_mold = { moldID: "", moldcavity: null, stdmh: null };
                      }

                      call_wpc.request([{ ...s_thisWpc, wlID: s_wlID, ...payload_mold }]);
                    } else {
                      call_open.request([{ ...s_thisWpc, wlID: s_wlID, SFC: true }]);
                    }
                  }}
                >
                  {/* {t("util.util.ok")} */}
                  {"WO OPEN"}
                  {/* 確認 */}
                </Button>
              </Space>
            </Col>
          </Spin>
        </div>
      </CustomModal>
    </Row>
  );
}
