import { Button, Col, Row, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MyButton from "@/components/Button";
import { CustomModal } from "@/components/Custom/AntdCustom";
import { IconArrow } from "@/components/Icon";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { close, open, prepare } from "@/service/api/wosAPI";
import { fontSize } from "@/styles/BasicSetting";

import { WlWocContext } from "../../index";
import Close from "../ButtonArea_BK/ModolComponent/Close/NewVersion";
import Down from "../ButtonArea_BK/ModolComponent/Down/";
import MC from "../ButtonArea_BK/ModolComponent/MC/index";
// import Prefix from "./ModolComponent/SplitOrder/Prefix";
import BatchPrepare from "./ModolComponent/BatchModule/Prepare";
// modol
import WorkOrderSend from "./ModolComponent/WorkOrderSend";
import WpcSned from "./ModolComponent/WpcSend";

const CustomIconArrow = styled(IconArrow)`
  font-size: ${fontSize.h1};
`;

function StartTab({ ASSY, INJE }) {
  const { t } = useTranslation();
  const { thisWpc, c_call_getAllWpc, processManagementAuth } = useContext(WlWocContext);
  // 生管派工
  const [isWorkOrderSend, setIsWorkOrderSend] = useState("def");
  //途單登錄
  const [isWpcSend, setIsWpcSend] = useState("def");

  const [is_mcModal, set_is_mcModal] = useState(false);
  const [is_closeModal, set_is_closeModal] = useState(false);

  const [s_isBatchPrepare, set_s_isBatchPrepare] = useState(false);
  const [s_isDownOpen, set_s_isDownOpen] = useState(false);

  const call_open = useAPI(open);
  const call_prepare = useAPI(prepare);

  const call_close = useAPI(close);

  //--------------------------------------------------------------------------------------
  useEffect(() => {
    // console.log("wosAuth", wosAuth);
  }, []);
  useEffect(() => {
    if (call_prepare.status === "suc") {
      message.success(call_prepare.msg);
      // 成功 call getAllWpc
      c_call_getAllWpc();
    } else if (call_prepare.status === "err") {
      message.error(call_prepare.msg);
    }
  }, [call_prepare.status]);

  useEffect(() => {
    if (call_open.status === "suc") {
      message.success(call_open.msg);
      // 成功 call getAllWpc
      c_call_getAllWpc();
    } else if (call_open.status === "err") {
      message.error(call_open.msg);
    }
  }, [call_open.status]);

  useEffect(() => {
    if (call_close.status === "suc") {
      message.success(call_close.msg);
      c_call_getAllWpc();
    } else if (call_close.status === "err") {
      message.error(call_close.msg);
    }
  }, [call_close.status]);
  console.log(ASSY, INJE);

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col span={6} className="flex justify-center items-center">
          <Space direction="vertical">
            {/* 生管派工 */}
            <MyButton
              height={120}
              width={200}
              onClick={() => setIsWpcSend(true)}
              disabled={processManagementAuth.wpcLogin}
            >
              <Text level="h2" className="tracking-widest">
                {"WO OPEN"}
                {/* 途單登錄 */}
              </Text>
            </MyButton>
          </Space>
        </Col>
        <Col span={3} className="flex justify-center items-center">
          <CustomIconArrow />
        </Col>
        <Col span={6} className="flex justify-center items-center">
          <Space direction="vertical">
            {/* 報開工 */}
            {/* <MyButton
              height={59}
              width={180}
              onClick={() => prepareCheck()}
              disabled={processManagementAuth.readyStart}
            >
              <Text level="h2" className="tracking-widest">
                {t("WOS.util.lifeF_B")}
           
              </Text>
            </MyButton>
             {/* 批量報開工 */}
            {/* <MyButton
              height={59}
              width={180}
              color="#70CEF6"
              onClick={() => set_s_isBatchPrepare(true)}
              disabled={Object.keys(thisWpc).length !== 0}
            >
              <Text level="h2">
                {t("WOS.util.batch") + t("WOS.util.lifeF_B")}
               
              </Text>
            </MyButton> */}
            <MyButton height={120} width={200} onClick={() => set_is_mcModal(true)}>
              <Text level="h2">
                {/* {t("WOS.wlwoc.ng_login")} */}
                {"MC"}
              </Text>
            </MyButton>
          </Space>
        </Col>
        <Col span={3} className="flex justify-center items-center">
          <CustomIconArrow />
        </Col>
        {INJE && (
          <Col span={6} className="flex justify-center items-center">
            <Space direction="vertical">
              {/* 開始生產 */}
              {/* <MyButton
              height={59}
              width={180}
              onClick={() => openCheck()}
              disabled={processManagementAuth.openWork}
            >
              <Text level="h2" className="tracking-widest">
                {t("WOS.util.lifeF_2")}
              
               
              </Text>
                {/* 批量生產 */}
              {/* </MyButton> */}

              <MyButton
                height={60}
                width={200}
                onClick={() => set_is_closeModal(true)}
                // disabled={processManagementAuth.offWork}
              >
                <Text level="h2">
                  {/* {t("WOS.util.lifeF_7")} */}
                  {/* 報完工 */}
                  {"WO CLOSE"}
                </Text>
              </MyButton>
              <MyButton
                // disabled
                className={`bg-blue-700`}
                height={60}
                width={200}
                onClick={() => set_s_isDownOpen(true)}
                // disabled={Object.keys(thisWpc).length !== 0}
              >
                <Text level="h2" className="tracking-widest">
                  {"WO DOWN"}
                </Text>
              </MyButton>
            </Space>
          </Col>
        )}
        {ASSY && (
          <Col span={6} className="flex justify-center items-center">
            <Space direction="vertical">
              {/* 開始生產 */}
              {/* <MyButton
              height={59}
              width={180}
              onClick={() => openCheck()}
              disabled={processManagementAuth.openWork}
            >
              <Text level="h2" className="tracking-widest">
                {t("WOS.util.lifeF_2")}
              
               
              </Text>
                {/* 批量生產 */}
              {/* </MyButton> */}

              <MyButton
                height={120}
                width={200}
                onClick={() => set_is_closeModal(true)}
                // disabled={processManagementAuth.offWork}
              >
                <Text level="h2" className="flex flex-col">
                  {/* {t("WOS.util.lifeF_7")} */}
                  <div> {"WO CLOSE"}</div>
                  <div> {"(GO END)"}</div>
                  {/* 報完工 */}
                  {/* {"WO CLOSE\n(GO END)"} */}
                </Text>
              </MyButton>
              {/* <MyButton
                // disabled
                className={`bg-blue-700`}
                height={60}
                width={200}
                onClick={() => set_s_isDownOpen(true)}
                // disabled={Object.keys(thisWpc).length !== 0}
              >
                <Text level="h2" className="tracking-widest">
                  {"WO DOWN"}
                </Text>
              </MyButton> */}
            </Space>
          </Col>
        )}
      </Row>

      <CustomModal
        // title="報完工"
        title={t("WOS.util.lifeF_7")}
        visible={is_closeModal}
        width="90%"
        onCancel={() => set_is_closeModal(false)}
        footer={null}
        afterClose={() => c_call_getAllWpc()}
      >
        <Close setIsModal={set_is_closeModal} thisWpc={thisWpc} />
      </CustomModal>

      <CustomModal
        key="isWorkOrderSend"
        title={t("WOS.util.wpcPrinter")}
        // "生管派工"
        visible={isWorkOrderSend === true}
        onCancel={() => setIsWorkOrderSend(false)}
        afterClose={() => c_call_getAllWpc()}
        footer={[
          <Button key="isWorkOrderSendCancel" onClick={() => setIsWorkOrderSend(false)}>
            {t("util.util.cancel")}
          </Button>,
        ]}
      >
        <WorkOrderSend setIsModal={setIsWorkOrderSend} />
      </CustomModal>

      <CustomModal
        key="isWpcSend"
        // title={t("WOS.wlwoc.WpcLogin")}
        title="WO OPEN"
        // "途單登錄"
        visible={isWpcSend === true}
        onCancel={() => setIsWpcSend(false)}
        footer={[
          <Button key="isWorkOrderSendCancel" onClick={() => setIsWpcSend(false)}>
            {t("util.util.cancel")}
          </Button>,
        ]}
        afterClose={() => c_call_getAllWpc()}
      >
        <WpcSned setIsModal={setIsWpcSend} />
      </CustomModal>

      {/* <CustomModal
        key="isPrefix"
        title="前拆單"
        visible={isPrefix === "true"}
        onCancel={() => setIsPrefix("false")}
        footer={null}
        width="80%"
        afterClose={() => c_call_getAllWpc()}
      >
        <Prefix setIsModal={setIsPrefix} thisWpc={thisWpc} />
      </CustomModal> */}

      <CustomModal
        title={t("WOS.util.batch") + t("WOS.util.lifeF_B")}
        // "批量報開工"
        visible={s_isBatchPrepare}
        onCancel={() => set_s_isBatchPrepare(false)}
        footer={null}
        width="100%"
        afterClose={() => c_call_getAllWpc()}
      >
        <BatchPrepare setIsModal={set_s_isBatchPrepare} />
      </CustomModal>

      <CustomModal
        title={"DOWN"}
        // "批量生產"
        visible={s_isDownOpen}
        onCancel={() => set_s_isDownOpen(false)}
        footer={null}
        width="100%"
        afterClose={() => c_call_getAllWpc()}
      >
        <Down setIsModal={set_s_isDownOpen} />
      </CustomModal>

      <CustomModal
        // title={t("WOS.util.lifeF_B")}
        // title={t("WOS.wlwoc.ng_login")}
        title="MC Panel"
        // {t("WOS.wlwoc.data_input")}//輸入資料
        visible={is_mcModal}
        width="90%"
        onCancel={() => set_is_mcModal(false)}
        footer={null}
        destroyOnClose
        afterClose={() => c_call_getAllWpc()}
      >
        <MC setIsModal={set_is_mcModal} />
      </CustomModal>
    </div>
  );
}

export default StartTab;
