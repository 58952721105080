const apiBasic = {
  // conn: "http",
  // url: "localhost:44444",

  // DEMO
  conn: "https", //"http"
  url: "demo.mes.satdg.com/wos",
};

export default apiBasic;
