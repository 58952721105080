import { useState, useEffect } from "react";
import { Form, Row, Col, Divider, Space, Button, message, Input, InputNumber } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import Text from "@/components/Text/index";
import useAPI from "@/hooks/useAPI";
import { createBatch } from "@/service/api/wosAPI";
import { useTranslation } from "react-i18next";
export default function Create({ set_s_showModal, thisWpc }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_createBatch = useAPI(createBatch);

  const onSubmit = async (values) => {
    const postData = {
      UUID: thisWpc.UUID,
      woN: thisWpc.woN,
      batches: values.batches,
    };
    call_createBatch.request(postData);
  };

  const formListAuth = {
    validator: async (_, formTbData) => {
      // 驗證規則
      const reC = form.getFieldValue("reC");
      const total = formTbData.reduce((data, item) => item.bulkqty && data + item.bulkqty, 0);
      if (!formTbData || formTbData.length === 0) {
        return Promise.reject(new Error(t("WOS.error.addAtLeastOne"))); //"請至少新增一筆資料"
      }
      if (total > reC) {
        return Promise.reject(new Error(t("WOS.error.batchQtyExceedRemainingQty"))); //"批號數量不得大於剩餘可用數量"
      }
      return Promise.resolve();
    },
  };

  useEffect(() => {
    //剩餘數量 remaining quantity
    const reC = thisWpc.inC - thisWpc.d_inC - thisWpc.wpcC - thisWpc.batchC;
    form.setFieldsValue({
      inC: thisWpc.inC,
      reC: reC,
      bdmbatchbom: thisWpc.bdmbatchbom,
      batches: [],
    });
  }, []);

  useEffect(() => {
    if (call_createBatch.status === "suc") {
      message.success(call_createBatch.msg);
      set_s_showModal(false);
    } else if (call_createBatch.status === "err") {
      message.error(call_createBatch.msg);
    }
  }, [call_createBatch.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Row gutter={[12, 0]}>
        <Col span={7}>
          <Form.Item label={t("WOS.util.inC")} name="inC">
            {/*投產數量*/}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={t("WOS.util.remaining") + t("WOS.util.available_qty")} name="reC">
            {/*剩餘可用數量*/}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label={t("WOS.wlwoc.bdmbatchbom")} name="bdmbatchbom">
            {/*"滿包數量" */}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider className="m-0 p-0" />
        </Col>
        <Col span={24}>
          <Form.List name="batches" rules={[formListAuth]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {!!fields.length && (
                  <Row>
                    <Col span={2}>
                      <Text level="h5">{t("util.util.SN")}</Text>
                      {/* 序 */}
                    </Col>
                    <Col span={10}>
                      <Text level="h5">{t("util.util.batchno")}</Text>
                      {/* 批號 */}
                    </Col>
                    <Col span={10}>
                      <Text level="h5">{t("util.util.batchno") + t("WOS.util.qty")}</Text>
                      {/* 批號數量 */}
                    </Col>
                  </Row>
                )}
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[12, 0]}>
                    <Form.Item {...field} name={[field.name, "bdmbatchbom"]} noStyle />
                    <Col span={2}>
                      <Text level="h5" style={{ marginLeft: 4 }}>
                        {index + 1}
                      </Text>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...field}
                        name={[field.name, "batchID"]}
                        // rules={[{ required: true, message: "請輸入批號" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...field}
                        name={[field.name, "bulkqty"]}
                        rules={[
                          {
                            required: true,
                            message:
                              t("util.util.placeholder_input") +
                              t("WOS.util.remaining") +
                              t("WOS.util.available_qty"),
                          }, //"請輸入剩餘可用數量"
                          {
                            validator: async (_, value) => {
                              // 驗證規則
                              const getbdmbatchbom = form.getFieldValue("bdmbatchbom");
                              // if (value > getbdmbatchbom) {
                              //   return Promise.reject(new Error("超過滿包數量"));
                              // }
                              if (value <= 0) {
                                return Promise.reject(new Error(t("WOS.error.countLessZero"))); //輸入數量不得為0
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber className="w-100" min={1} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        style={{ fontSize: "1.3rem", marginTop: 5 }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  </Row>
                ))}
                <div className="flex justify-center items-center">
                  <Form.ErrorList errors={[errors]} />
                </div>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => add({ bulkqty: 0, bdmbatchbom: thisWpc.bdmbatchbom })}
                  >
                    {t("util.util.add")}
                    {/* 新增 */}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24} className="ant-modal-footer">
          <Button size="large" onClick={() => set_s_showModal(false)}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button type="primary" size="large" htmlType="submit">
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
