import { Col, Row, message } from "antd";
import { format } from "highcharts";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MyButton from "@/components/Button";
import MyCard from "@/components/Card";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { WlWocContext } from "@/pages/NewQualityControl/index";
import { cancel, pass, qoResultQo, waive } from "@/service/api/qcAPI";
import checkUndefined from "@/util/checkUndefined";
import { formatAmount } from "@/util/format";

const PrepareAreaStyle = styled.div`
  /* height: 300px; */
`;

export default function PrepareArea({ thisWpc, call_getAllWpc, processManagementAuth }) {
  const call_pass = useAPI(pass);
  const { s_isInspection, set_s_isFianlInspection, s_isFianlInspection } = useContext(WlWocContext);
  const call_waive = useAPI(waive);
  const call_qoResultQo = useAPI(qoResultQo);

  const [s_qcButton, set_s_qcButton] = useState({
    qualified: false,
    waive: false,
    isolation: false,
  });

  const call_cancel = useAPI(cancel);
  const { t } = useTranslation();
  const handleButtonClick = (buttonName) => {
    set_s_qcButton((prev) => {
      // 使用 Object.keys 遍历对象，将所有值设置为 false
      Object.keys(prev).forEach((key) => {
        prev[key] = false;
      });
      // 将传入的按钮名对应的值设置为 true
      return { ...prev, [buttonName]: true };
    });
  };

  useEffect(() => {
    if (call_pass.status === "suc") {
      message.success(call_pass.msg);
      call_getAllWpc();
    } else if (call_pass.status === "err") {
      message.error(call_pass.msg);
    }
  }, [call_pass.status]);

  useEffect(() => {
    if (call_waive.status === "suc") {
      message.success(call_waive.msg);
      call_getAllWpc();
    } else if (call_waive.status === "err") {
      message.error(call_waive.msg);
    }
  }, [call_waive.status]);

  useEffect(() => {
    if (call_cancel.status === "suc") {
      message.success(call_cancel.msg);
      call_getAllWpc();
    } else if (call_cancel.status === "err") {
      message.error(call_cancel.msg);
    }
  }, [call_cancel.status]);
  useEffect(() => {
    if (call_qoResultQo.status === "suc") {
      message.success(call_qoResultQo.msg);
      call_getAllWpc();
    } else if (call_qoResultQo.status === "err") {
      message.error(call_qoResultQo.msg);
    }
  }, [call_qoResultQo.status]);

  return (
    <PrepareAreaStyle>
      <Row gutter={[12, 12]} className="py-4">
        <Col span={6}>
          <MyCard
            title={
              t("WOS.util.inC")
              /*投產數量*/
            }
            type="QualityControl"
            content={<div className="w-full text-center">{formatAmount(thisWpc.inC)}</div>}
            titleAlign="center"
            borderAlign="top"
            align="center"
            color="#4A83D1"
          />
        </Col>
        <Col span={6}>
          <MyCard
            title={
              t("WOS.util.okC")
              /*良品數量*/
            }
            type="QualityControl"
            content={<div className="w-full text-center">{formatAmount(thisWpc.okC)}</div>}
            titleAlign="center"
            borderAlign="top"
            align="center"
            color="#77D160"
          />
        </Col>
        <Col span={6}>
          <MyCard
            title={
              t("WOS.util.ngC")
              // {/*不良品數量*/}
            }
            type="QualityControl"
            content={
              <div className="w-full text-center">{formatAmount(thisWpc.ngC)}</div>

              // {/* {checkUndefined(thisWpc?.ngC)} */}
            }
            titleAlign="center"
            align="center"
            borderAlign="top"
            // align="right"
            color="#F37E7E"
          />
        </Col>

        <Col span={6}>
          <MyCard
            title={
              t("WOS.util.sampleSize")
              /*抽樣數量*/
            }
            type="QualityControl"
            content={<div className="w-full text-center">1</div>}
            titleAlign="center"
            borderAlign="top"
            align="center"
            color="#BEBEBE"
          />
        </Col>

        <Col span={6}>
          <MyButton
            height={120}
            className={`disabled:bg-[#DBE8D7]  bg-[#699F5B]  ${
              ((thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "P") &&
              "text-[#0022FF] bg-[#689E5A]"
            }`}
            radius="20"
            disabled={
              (thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "P"
                ? false
                : true
            }
            loading={call_pass.status === "load"}
            onClick={() => call_qoResultQo.request({ ...thisWpc, "qcjudge": "P" })}
          >
            <Text level="h2">{t("WOS.util.lifeF_P")}</Text>
          </MyButton>
        </Col>
        <Col span={6}>
          <MyButton
            height={120}
            color="#EBB549"
            className={`disabled:bg-[#FAEDD3]  ${
              ((thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "W") &&
              "text-[#0022FF] bg-[#EBB549]"
            }`}
            radius="20"
            disabled={
              (thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "W"
                ? false
                : true
            }
            loading={call_waive.status === "load"}
            onClick={() => call_qoResultQo.request({ ...thisWpc, "qcjudge": "W" })}
          >
            <Text level="h2">{t("WOS.util.qc_waiver")}</Text>
          </MyButton>
        </Col>
        <Col span={6}>
          <MyButton
            radius="20"
            height={120}
            color="#E54F4F"
            className={`disabled:bg-[#F9D5D5] ${
              ((thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "R") &&
              "text-[#0022FF] bg-[#E54F4F]"
            }`}
            disabled={
              (thisWpc.lifeF === "8" && thisWpc.qcjudge === "") || thisWpc.qcjudge === "R"
                ? false
                : true
            }
            onClick={() => call_qoResultQo.request({ ...thisWpc, "qcjudge": "R" })}
          >
            <Text level="h2">{t("WOS.util.qc_quarantine")}</Text>
          </MyButton>
        </Col>
        <Col span={6}>
          <MyButton
            height={120}
            radius="20"
            color="#70757c"
            className={"disabled:bg-[#aaabaa]"}
            disabled={thisWpc.lifeF === "8" && thisWpc.qcjudge !== "" ? false : true}
            onClick={() => call_qoResultQo.request({ ...thisWpc, "qcjudge": "" })}
            loading={call_cancel.status === "load"}
          >
            <Text level="h2">質控解除</Text>
          </MyButton>
        </Col>
      </Row>
    </PrepareAreaStyle>
  );
}
