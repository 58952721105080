import styled from "styled-components";
import {
 
    Input,
  
  } from "antd";

const CustomSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-lg {
    border: none !important;
  }
  .ant-input-suffix {
    position: absolute;
    right: 40px;
    top: 17%;
    color: red;
    /* width: 100px; */
    /* height: 100px; */
    font-size: 30px;
    z-index: 9999;
  }
  .ant-input-lg {
    font-size: 31px !important;
    border: none !important;
    padding-left: 4px !important;
  }
  .ant-input {
    padding-left: 20px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
    display: none;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .ant-input-wrapper:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper::before {
    border: none !important;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-input {
    background-color: #eb8282 !important;
    border-radius: 9999px !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;


export default CustomSearch;