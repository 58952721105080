import React, { forwardRef, useEffect, useState } from "react";

import HD from "./HD";
import TB from "./TB";

const Pfc = forwardRef((props, ref) => {
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  return (
    <div className="w-full flex flex-col gap-2 ">
      <HD
        ref={ref}
        flag={props.flag}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
        {...props}
      />
      {Object.keys(c_lightData).length > 0 && (
        <TB is_full={props.is_full} c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      )}
    </div>
  );
});

export default Pfc;
