import apiBasic from "../APIBasic";
import { instance } from "../handlerAPI";

export const addOk = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOk`, data);
};

export const getAllWsID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWsID`, {
    params: _params,
  });
};
export const getAllWlID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWlID`, {
    params: _params,
  });
};

export const wpcSearch = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/board/wpcSearch`, {
    params: _params,
  });
};

export const inpdt = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/board/inpdt`, {
    params: _params,
  });
};

export const getWsWl = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/board/getWsWl`, {
    params: _params,
  });
};
export const getWoNSearch = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/board/woNSearch`, {
    params: _params,
  });
};
export const getWoNDetail = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/board/woNSearchDetail`, {
    params: _params,
  });
};
