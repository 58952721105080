const defaultColor = {
  "@tabs-ink-bar-color": "#00FF00",
  "@primary-color": "#7ab1f4", // 主題色
};

export const light = {
  "@text-color": "#6C6C6C", // 文字顏色 fade(@black, 85%)
  "@heading-color": "rgba(0,0,0,0.85)",
  "@text-color-secondary": "rgba(0,0,0,0.85)",

  "@component-background": "#FFF", // ex: 表格內容背景色、navbar背景色
  "@background-color-light": "#FFF",
  "@table-header-sort-bg": "#d9e7e4",
  //"@disabled-color": 'red',
  "@select-item-selected-bg": "@primary-1",

  "@table-row-hover-bg": "rgba(30, 150, 183, 0.22)",
  "@table-header-color": "#6C6C6C", // @heading-color
  "@table-header-bg": "rgb(192, 220, 240)", // @primary-1
  "@table-bg": "#f5f5f5",
  "@table-body-sort-bg": "#fafafa",

  "@layout-body-background": "#FFF", //#F0F2F5
  "@layout-header-background": "#fff",

  "@layout-sider-background": "@layout-header-background", //
  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style
  "@collapse-header-bg": "#F0F2F5",
  "@collapse-header-color": "@primary-color",
  "@message-notice-content-bg": "#FFF2CC",
  "@select-item-selected-color": "#0000FF",

  ...defaultColor,
};
export const dark = {
  "@text-color": "#ffffff",
  "@heading-color": "rgba(255,255,255,0.85)",
  "@text-color-secondary": "rgba(255,255,255,0.85)",

  "@page-header-back-color": "#ffffff",
  "@component-background": "#424242",

  "@table-row-hover-bg": "#7d7d67",
  "@disabled-color": "#FFF",
  "@table-bg": "rgb(58, 71, 78)",
  "@disabled-color-dark": "red",
  "@background-color-light": "#2b587b",
  "@background-color-base": "rgba(20, 77, 107 , 0.5)",
  "@table-header-sort-bg": "@background-color-base",

  "@select-item-selected-bg": "rgba(0, 150, 136, 0.4)",
  "@item-hover-bg": "rgba(0, 150, 136, 0.1)",

  "@select-item-selected-color": "#FFFF00",

  "@table-body-sort-bg": "#526772",
  "@table-header-color": "#ffffff",
  "@table-header-bg": "@background-color-light",

  // "@menu-dark-bg": "#0f172a ",
  // "@menu-dark-submenu-bg": "#0f172a",

  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style

  "@message-notice-content-bg": "#FFF2CC",

  "@layout-body-background": "#000000",
  "@layout-header-background": "#424242",
  "@layout-sider-background-light": "#0f172a",

  "@border-color-split": "#6C6C6C",
  "@border-color-base": "#6C6C6C",
  "@divider-color": "#6C6C6C",
  ...defaultColor,
};
export const change_antd_theme = (color) => {
  window.less
    .modifyVars(color)
    .then(() => {
      console.log("更換主題成功");
    })
    .catch((error) => {
      console.log("更換主題失敗", error);
    });
};
