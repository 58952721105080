import { useState, useEffect } from "react";
import { Row, Col, Input, Divider, Space, Button, message, Modal } from "antd";

import Cookies from "js-cookie";

import checkUndefined from "@/util/checkUndefined";
import Text from "@/components/Text";
import { CustomTable, CustomModal } from "@/components/Custom/AntdCustom";
import { useTranslation } from "react-i18next";

import { getAllReturnWpc, returnWpc } from "@/service/api/wosAPI";
import useAPI from "@/hooks/useAPI";

import DetailsButton from "@/components/DetailsButton";

import WpcDetailModal from "@/components/WpcList/WpcDetailModal";

export default function WpcLogout({ setIsModal }) {
  const { t } = useTranslation();
  const { Search } = Input;

  const wpcLoginCol = [
    {
      title: t("util.util.wlID"), // "線別",      dataIndex: "wlID",
      key: "wlID",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.woN"), //"工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.lifeFT"), //"歷程",
      dataIndex: "lifeFstr",
      key: "lifeFstr",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.opcode"), //"單別",
      dataIndex: "wotypeStr",
      key: "wotypeStr",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.PN"), //"料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      key: "planqty",
      render: (str) => checkUndefined(str),
    },
    {
      //planQty - d_planQty - d_inC
      title: t("WOS.util.goal") + t("WOS.util.qty"), //"目標產量",
      dataIndex: "goalqty",
      key: "goalqty",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.okC"), //"生產數量",
      dataIndex: "okC",
      key: "okC",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.action"), //"動作",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (obj, recoded) => (
        <DetailsButton
          onClick={(event) => {
            logoutWpc?.UUID === recoded.UUID && event.stopPropagation();
            setIsWpcDatailModal(true);
          }}
        />
      ),
    },
  ];

  const [queryString, setQueryString] = useState("");

  const [logoutWpc, setLogoutWpc] = useState({});

  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 10, //回傳筆數
    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const [tableData, setTableData] = useState([]);

  const [isWpcDatailModal, setIsWpcDatailModal] = useState(false);

  const call_getAllReturnWpc = useAPI(getAllReturnWpc);

  const call_returnWpc = useAPI(returnWpc);

  useEffect(() => {
    call_getAllReturnWpc.request(pageInfo);
  }, [pageInfo.page]);

  useEffect(() => {
    if (call_getAllReturnWpc.status === "suc") {
      setPageInfo((prev) => ({
        ...prev,
        ...call_getAllReturnWpc.data.pageInfo,
      }));
      setTableData(
        call_getAllReturnWpc.data.tableData?.map((x) => {
          return {
            ...x,
            key: x.UUID,
            wlID: Cookies.get("wlID"),
            wsID: Cookies.get("wsID"),
            linemixStr:
              x.linemix === 1
                ? t("WOS.util.linemix_1")
                : x.linemix === 2
                ? t("WOS.util.linemix_2")
                : t("WOS.util.linemix_0"),
            wotype:
              x.wotype === 0
                ? t("WOS.util.wotype_0")
                : x.wotype === 1
                ? t("WOS.util.wotype_1")
                : t("WOS.wlwoc.nodata"),
          };
        })
      );
      message.success(call_getAllReturnWpc.msg);
    } else if (call_getAllReturnWpc.status === "err") {
      // 只要失敗就清空途單列表
      setTableData([]);
      message.error(call_getAllReturnWpc.msg);
    }
  }, [call_getAllReturnWpc.status]);

  const returnWpcCheck = () => {
    Modal.confirm({
      title: (
        <Text level="h3">
          {t("WOS.wlwoc.waybillWithdraw")}?{/* 是否對該筆途單進行撤登 */}
        </Text>
      ),
      content: (
        <Text level="h3">
          {t("WOS.wlwoc.PleaseMakeSureTheProductionProcedureIsCorrectFirst")}，
          {/* 請先確認生產作業程序無誤 */}
          {t("WOS.wlwoc.retake")}
          {/* 再進行 */}
          撤登
        </Text>
      ),
      okText: t("util.util.ok"), //"確認",
      okType: "primary",
      cancelText: t("util.util.cancel"), //"取消",
      centered: true,
      width: "600px",
      onOk() {
        call_returnWpc.request(logoutWpc);
      },
    });
  };

  useEffect(() => {
    if (call_returnWpc.status === "suc") {
      message.success(call_returnWpc.msg);
      setIsModal("false");
    } else if (call_returnWpc.status === "err") {
      message.error(call_returnWpc.msg);
    }
  }, [call_returnWpc.status]);

  return (
    <Row gutter={[0, 12]}>
      <Col span={19}></Col>
      <Col span={5} className="flex justify-center items-center">
        <Search
          // placeholder="請輸入完整的工單/料號"
          placeholder={
            t("util.util.placeholder_input") + t("util.util.woN") + "/" + t("util.util.PN")
          }
          allowClear
          enterButton
          size="large"
          onChange={(e) => setQueryString(e.target.value)}
          onSearch={() =>
            call_getAllReturnWpc.request({
              ...pageInfo,
              queryString: queryString,
            })
          }
        />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={wpcLoginCol}
          loading={call_getAllReturnWpc.status === "load"}
          dataSource={tableData}
          rowClassName={(record, index) =>
            record.UUID === logoutWpc?.UUID
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.UUID === logoutWpc?.UUID) {
                  setLogoutWpc({});
                } else {
                  setLogoutWpc(record);
                }
              },
            };
          }}
          pagination={{
            total: pageInfo.totalItems,
            current: pageInfo.page,
            pageSize: pageInfo.pageSize,
            onChange: (current) => setPageInfo({ ...pageInfo, page: current }),
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
              </Text>
            ),
          }}
        />
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Space size={20}>
          <Button size="large" onClick={() => setIsModal("false")}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button
            type="primary"
            size="large"
            disabled={Object.keys(logoutWpc).length === 0}
            loading={call_getAllReturnWpc.status === "load"}
            onClick={returnWpcCheck}
          >
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Space>
      </Col>

      <CustomModal
        key="isWpcDatailModal"
        title={t("WOS.util.wpcList") + t("util.util.detail")}
        // title="途單索引詳情"
        visible={isWpcDatailModal}
        onCancel={() => setIsWpcDatailModal(false)}
        footer={null}
        width="80%"
      >
        <WpcDetailModal setIsModal={setIsWpcDatailModal} thisWpc={logoutWpc} />
      </CustomModal>
    </Row>
  );
}
