import { Tooltip } from "antd";
import styled from "styled-components";

import { IconStation } from "@/components/Icon/index";
import Text from "@/components/Text";

const CardStyle = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: inset 0px 10px 10px -15px #000, inset -10px 0px 10px -15px #000,
    rgba(0, 0, 0, 0.5) inset -2px -2px 10px;
  background-color: ${({ color }) => {
    return `${color ? color : "#EE8158"}`;
  }};
  height: ${({ height }) => height ?? 100};

  .text-area {
    width: 100%;
  }

  .wscard-context {
    width: calc(100% - 30px);
    padding: 0px 28px;
    /* background-color: red; */
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    white-space: nowrap; /*//强制文本在一行内输出 */
    overflow: hidden; /*隐藏溢出部分*/
    text-overflow: ellipsis; /*//對溢出部分加上...*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .extra {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-10px, -50%);
  }
`;

const CardIcon = styled.div`
  height: 45px;
  width: 45px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 38%;
  left: 5%;
  transform: translate(10px, -50%);
`;
// Ariean: 因為要趕展覽使用，所以這邊先復課 wsCard 的樣式，不共用 wsCard，如有需要可自行修改
const SipCard = (props) => {
  return (
    <CardStyle {...props}>
      <div className="flex">
        <CardIcon>
          <IconStation style={{ fontSize: "45px", color: "#EE8158" }} />
        </CardIcon>
        {props.siptype && (
          <Text level="h1" style={{ color: "white", lineHeight: 1 }}>
            <Tooltip title={props.siptype}>{props.siptype}</Tooltip>
          </Text>
        )}
      </div>
      <span className="flex  flex-col justify-around items-center text-area">
        {props.siptype_NM && (
          <div className="wscard-context" style={{ color: "white" }}>
            <Tooltip title={props.siptype_NM}>{props.siptype_NM}</Tooltip>
          </div>
        )}
      </span>
      <span className="extra">{props.extra}</span>
    </CardStyle>
  );
};

export default SipCard;
