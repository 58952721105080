import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CustomTable } from "@/components/Custom/AntdCustom";
import { IconScanBarcode } from "@/components/Icon";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getAllWpc, getModel } from "@/service/api/publicAPI";
import { createRun39, getRun39, updateRun39, updateRun39PrintC } from "@/service/api/wosAPI";
import combineBase64Images from "@/util/Printer/combineBase64Images";
import labelBuild from "@/util/Printer/labelBuild";
import numberWithCommas from "@/util/numberWithCommas";

const Box = styled.div`
  background-color: ${({ theme }) => theme.componentBg};
  height: 208px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
  z-index: 10;
`;

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    margin: auto;
    height: 120px;
    width: 2px;
    background-color: #d9d9d9;
  }
`;

const CustomSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-lg {
    border: none !important;
  }
  .ant-input-suffix {
    position: absolute;
    right: 40px;
    top: 17%;
    color: red;
    /* width: 100px; */
    /* height: 100px; */
    font-size: 30px;
    z-index: 9999;
  }
  .ant-input-lg {
    font-size: 31px !important;
    border: none !important;
    padding-left: 4px !important;
  }
  .ant-input {
    padding-left: 20px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
    display: none;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .ant-input-wrapper:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper::before {
    border: none !important;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-input {
    background-color: #eb8282 !important;
    border-radius: 9999px !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;

const initBarcodeObj = {
  woN: null,
  PN: null,
  wsID: null,
};

const { labelPrinter, unitToPx } = labelBuild;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "note" ? (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
          />
        ) : (
          <InputNumber
            ref={inputRef}
            className="bg-green-500"
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={1}
            style={{ width: "100%" }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap "
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};
// chooseThisWpc 是外面List已經選擇的thisWpc
export default function PrintBarcode({ set_s_showModal, chooseThisWpc = {} }) {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_enterText, set_s_enterText] = useState("");
  const [s_thisWpc, set_s_thisWpc] = useState({});
  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );

  const [s_batchCount, set_s_batchCount] = useState(0);
  const [s_batchbom, set_s_batchbom] = useState(0);

  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  // show
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState({});

  const [s_selectedRows, set_s_selectedRows] = useState([]);
  const call_getModel = useAPI(getModel);
  const call_getAllWpc = useAPI(getAllWpc);

  const call_getRun39 = useAPI(getRun39);
  const call_createRun39 = useAPI(createRun39);
  const call_updateRun39 = useAPI(updateRun39);
  const call_updateRun39PrintC = useAPI(updateRun39PrintC);

  const handlePrint = async (values) => {
    if (call_getModel.status === "err") {
      message.error("請檢查模板設定");
      return;
    }
    const modalsData =
      call_getModel.data?.variable?.map((x) => ({
        ...JSON.parse(x.variable),
      })) || [];

    const labelParams = values.map((x) => ({
      ...call_getModel.data,
      ...x,
      width: unitToPx(call_getModel.data.width, call_getModel.data.unit), //要轉為px 進行運算
      height: unitToPx(call_getModel.data.height, call_getModel.data.unit), //要轉為px 進行運算
    }));

    console.log(labelParams);

    // 撈不到資料
    if (Object.keys(labelParams).length === 0 || modalsData.length === 0) {
      message.error("請檢查模板或關鍵文字");
      return;
    }

    let rowNormalImg = await labelPrinter(labelParams[0], modalsData, "normal"); // 生成一般條碼的圖像

    // 生成關鍵條碼的圖像
    const rowImg = [];
    for (let i = 0; i < labelParams.length; i++) {
      const rowKeyImg1 = await labelPrinter(labelParams[i], modalsData, "key");
      rowImg.push([rowKeyImg1]);
    }

    const postImgs = combineBase64Images({
      col: 1,
      imgWidth: labelParams[0].width,
      imgHeight: labelParams[0].height,
      imgGap: 0,
      normalImgGroup: rowNormalImg,
      keyImgGroup: rowImg,
      type: " ",
    }); // 將圖像進行合併處理
    try {
      // let api = "http://192.168.0.139:59487/Printer/store";
      let api = "http://localhost:59487/Printer/store";
      axios
        .post(api, {
          type: "zpl",
          SAT: postImgs,
          width: unitToPx(labelParams[0].width, labelParams[0].unit),
          height: unitToPx(labelParams[0].height, labelParams[0].unit),
          left_gap: 0,
          top_gap: 0,
          dpi: 300,
          MD: 0,
          total: rowImg.length,
          startSN: 1,
          endSN: rowImg.length,
          // angle: 0,
          MD: 15,
          top_gap: 0,
          left_gap: 0,
        })
        .then((response) => call_updateRun39PrintC.request(values))
        .catch((error) => {
          message.error("請條碼伺服器連線狀態");
          console.log(error);
        });
    } catch (error) {
      message.error("產生條碼失敗，請聯繫開發人員");
    }
  };

  // 表格內編輯儲存到後端
  const handleSave = (data, dataIndex, value) => {
    const { batchbom } = data;
    //輸入一樣的數字就直接返回
    if (batchbom === value) return;

    //防止輸入非數字
    if (isNaN(value)) return;
    console.log("要傳給後端的資料 = ", {
      ...data,
      [dataIndex]: value,
    });
    call_updateRun39.request({
      ...s_thisWpc,
      ...data,
      [dataIndex]: value,
    });
  };

  const rowSelection = {
    selectedRowKeys: s_selectedRows.map((x) => x.key),
    onChange: (_, newSelectedRows) => {
      set_s_selectedRows(newSelectedRows);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const tableColumns = [
    {
      title: t("util.util.SN"), //"",
      dataIndex: "SN",
      align: "center",
    },
    {
      title: t("util.util.batchno"), //"批號",
      dataIndex: "batchID",
      align: "center",
    },
    {
      title: t("WOS.util.qty"), //"數量",
      dataIndex: "batchbom",
      align: "right",
      width: "30%",
      editable: true,
      render: (data, rowData, index) => {
        return <InputNumber className="w-full" value={data} readOnly={true} />;
      },
    },
    {
      title: t("util.util.printC"), //"列印次數",
      dataIndex: "printC",
      align: "right",
    },
  ];

  const columns = tableColumns
    .filter((col) => !col.hidden)
    .map((col) => {
      if (!col.editable) return col;
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
          // style: {
          //   backgroundColor: record?.lifeF === 'T' ? '#e1dbdb' : '',
          //   pointerEvents: record?.lifeF === 'T' ? 'none' : '',
          //   opacity: record?.lifeF === 'T' ? '.5' : '1',
          //   color: record?.lifeF === 'T' ? '#b6b1b1' : record?.confirmqty === 0 ? '#b6b1b1' : '#FF850A'
          // }
        }),
      };
    });

  function addBatch(s_batchbom, s_batchCount) {
    if (!s_batchbom || !s_batchCount) {
      //數量不能為0
      message.error("Quantity cannot be 0");
      return;
    }
    if (Object.keys(s_thisWpc).length === 0 || s_thisWpc.opcode === "") {
      //工單不能為空
      message.error("wo cannot be empty");
      return;
    }

    call_createRun39.request({
      ...s_thisWpc,
      batchCount: s_batchCount,
      batchbom: s_batchbom,
    });
  }

  function updatePrint(printData = []) {
    if (printData.length === 0) {
      // 選中的項目不能為空
      message.error("Selected cannot be empty");
      return;
    }

    handlePrint(printData);
  }

  useEffect(() => {
    call_getModel.request({ modelID: "LBL_2B_PF_003", ver: "3" });

    //chooseThisWpc 是外面List已經選擇的thisWpc
    //如果有資料可以預先撈出來
    // if (Object.keys(chooseThisWpc).length > 0) {
    //   call_getAllWpc.request({
    //     wpcType: "woc",
    //     queryString: chooseThisWpc.jobN,
    //     opcode: chooseThisWpc.opcode,
    //   });
    // }
  }, []);

  useEffect(() => {
    if (Object.keys(s_barcodeObj).length > 0) {
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    }
  }, [s_barcodeObj]);

  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        // set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
          ${t("WOS.wlwoc.noWoNScan")}
          ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
        ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      // set_s_tableData([]);
    }
  }, [is_barcodeInput]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      message.success(call_getAllWpc.msg);
      set_s_alertMsg(call_getAllWpc.msg);
      set_s_selectedRows([]);
      set_s_thisWpc(call_getAllWpc.data.tableData[0]);
      set_s_batchbom(call_getAllWpc.data.tableData[0]?.bdmbatchbom);
      call_getRun39.request(call_getAllWpc.data.tableData[0]);
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表
      // setTableData([]);
      set_s_selectedRows([]);
      message.error(call_getAllWpc.msg);
    }
  }, [call_getAllWpc.status]);

  useEffect(() => {
    if (call_getRun39.status === "suc") {
      message.success(call_getRun39.msg);
      set_s_tableData(
        call_getRun39.data.map((item, index) => ({
          ...item,
          key: index,
          SN: call_getRun39.data.length - index,
          issueday: moment(item.issueday).format("YYYY-MM-DD"),
        }))
      );
    } else if (call_getRun39.status === "err") {
      // 只要失敗就清空途單列表
      set_s_tableData([]);
      message.error(call_getRun39.msg);
    }
  }, [call_getRun39.status]);

  useEffect(() => {
    if (call_createRun39.status === "suc") {
      message.success(call_createRun39.msg);
      // set_s_tableData()
      updatePrint(
        call_createRun39.data.map((x) => ({
          ...x,
          issueday: moment(x.issueday).format("YYYY-MM-DD"),
        }))
      );
    } else if (call_createRun39.status === "err") {
      // 只要失敗就清空途單列表
      // setTableData([]);
      message.error(call_createRun39.msg);
    }
  }, [call_createRun39.status]);

  useEffect(() => {
    if (call_updateRun39.status === "suc") {
      message.success(call_updateRun39.msg);
      call_getRun39.request(s_thisWpc);
      set_s_selectedRows([]);
      // set_s_tableData()
    } else if (call_updateRun39.status === "err") {
      // 只要失敗就清空途單列表
      // setTableData([]);
      message.error(call_updateRun39.msg);
    }
  }, [call_updateRun39.status]);

  useEffect(() => {
    if (call_getModel.status === "err") {
      message.error(call_getModel.msg);
    }
  }, [call_getModel.status]);

  useEffect(() => {
    if (call_updateRun39PrintC.status === "suc") {
      message.success(call_updateRun39PrintC.msg);
      call_getRun39.request(call_getAllWpc.data.tableData[0]);
    } else if (call_updateRun39PrintC.status === "err") {
      message.error(call_updateRun39PrintC.msg);
    }
  }, [call_updateRun39PrintC.status]);

  return (
    <Row gutter={[12, 6]}>
      <Spin spinning={call_getAllWpc.status === "load"}>
        <Row gutter={[12, 6]}>
          <Col span={24}>
            <CustomSearch
              ref={searchRef}
              autoFocus
              size="large"
              allowClear
              placeholder={s_focusText}
              suffix={<IconScanBarcode className="text-[26px]" />}
              value={s_enterText}
              onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
              onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
              onChange={(e) => {
                set_s_enterText(e.target.value);
              }}
              onSearch={() => {
                set_s_enterText("");
                set_s_showBarcode(s_enterText);
                set_is_barcodeInput(true);
              }}
            />
          </Col>
          <Col span={24} className="mb-5">
            <Row className=" w-full  mb-5">
              <div className="bg-[#cedee9] w-full ">
                <Text
                  level="h3"
                  type="error"
                  style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
                >
                  <span className="text-[#6C6C6C] font-bold">{t("util.util.systemReport")}:</span>
                  {s_thisWpc.lifeF ? ` ${t(`WOS.util.lifeF_${s_thisWpc.lifeF}`)},` : ""}
                  {s_alertMsg}
                </Text>
              </div>
            </Row>

            <Row className="bg-[#F0F9FF]">
              <Col span={12}>
                <Row className="w-full flex ">
                  <Col span={24}>
                    <div className="flex justify-start  w-full  ">
                      <div className="flex      w-[35%]">
                        <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                          "util.util.wo"
                        )}`}</div>
                        <div className="whitespace-normal   text-[30px]"> :</div>
                      </div>
                      <span className="text-black  self-center text-[25px] font-bold  ">
                        {s_thisWpc.woN ? `${s_thisWpc.woN} ${s_thisWpc.opcode}` : ""}
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="flex justify-start  w-full  ">
                      <div className="flex      w-[35%]">
                        <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                          "util.util.PN"
                        )}`}</div>
                        <div className="whitespace-normal  text-[30px]"> :</div>
                      </div>
                      <span className="text-black self-center text-[25px] font-bold  ">
                        {s_thisWpc.PN ?? ""}
                      </span>
                    </div>
                  </Col>
                  <Col span={24} className=" ">
                    <div className="flex justify-start  w-full  ">
                      <div className="flex      w-[35%]">
                        <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                          "util.util.custPN"
                        )} `}</div>
                        <div className="whitespace-normal  text-[30px]"> :</div>
                      </div>
                      <span className="text-[#39883d] self-center text-[25px] font-bold  ">
                        {s_thisWpc.dealPN ?? ""}
                      </span>
                    </div>
                  </Col>
                  <Col span={24} className=" ">
                    <div className="flex justify-start  w-full  ">
                      <div className="flex      w-[35%]">
                        <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                          "util.util.pdtNM"
                        )}`}</div>
                        <div className="whitespace-normal  text-[30px]"> :</div>
                      </div>
                      <span className="text-[#3eb390]   w-[50%]  self-center text-[25px] font-bold  ">
                        {s_thisWpc.pdtNM ?? ""}
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="flex justify-start  w-full  ">
                      <div className="flex      w-[35%]">
                        <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                          "util.util.planqty"
                        )}`}</div>
                        <div className="whitespace-normal  text-[30px]"> :</div>
                      </div>
                      <span className="text-[#3e4ab3] self-center text-[25px] font-bold  ">
                        {s_thisWpc.planqty ? `${s_thisWpc.planqty}` : ""}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Box className="bg-[#FFF8F2]">
                  <Row justify="center" align="middle" gutter={[128, 0]} className="h-full">
                    <Col span={5} className="flex  flex-col justify-center items-center">
                      <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                        {t("WOS.wlwoc.inC")}
                      </Text>
                      <span className="text-[32px] text-[#4A83D1]">
                        {numberWithCommas(s_thisWpc?.inC) ?? 0}
                      </span>
                    </Col>
                    <Line />
                    <Col span={5} className="flex flex-col justify-center items-center ">
                      <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                        {t("WOS.wlwoc.ok_count")}
                      </Text>
                      <span className="text-[32px] font-bold text-[#77D160]">
                        {numberWithCommas(s_thisWpc?.okC ?? 0)}
                      </span>
                    </Col>
                    <Line />
                    <Col span={5} className="flex flex-col justify-center items-center ">
                      <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                        {t("WOS.wlwoc.ng_count")}
                      </Text>
                      <span className="text-[32px] text-[#F37E7E]">
                        {numberWithCommas(s_thisWpc?.ngC ?? 0)}
                      </span>
                    </Col>
                    <Line />
                    <Col span={5} className="flex flex-col justify-center items-center ">
                      <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                        {t("WOS.util.bal")}
                      </Text>
                      <span className="text-[32px] text-gray-300">
                        {numberWithCommas(s_thisWpc?.bal ?? 0)}
                      </span>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Col>

          <Divider />
          <Col span={24} className="text-right">
            <Space size={20}></Space>
          </Col>
        </Row>
      </Spin>

      <Row gutter={[12, 6]} className="w-full ">
        <Col span={24} className="flex items-center  ">
          <div className="flex   justify-center items-center ">
            <Text className="text-[28px]"> {t("ADM.pn-manage.batchbom")}</Text>
            <InputNumber
              min={1}
              className="w-[200px] text-[28px]"
              value={s_batchbom}
              onChange={(value) => set_s_batchbom(value)}
            />
          </div>
          <div className="flex justify-center items-center ml-2">
            <Text className="text-[28px]">{t("util.util.labelC")}</Text>
            <InputNumber
              min={1}
              className="w-[200px] text-[28px]"
              value={s_batchCount}
              onChange={(value) => set_s_batchCount(value)}
            />

            <Button
              block
              type="primary"
              loading={call_createRun39.status === "load"}
              className="text-[20px] ml-2"
              size="large"
              onClick={() => {
                addBatch(s_batchbom, s_batchCount);
              }}
            >
              {t("util.util.new") + "&" + t("util.util.header_print")}
            </Button>
          </div>
        </Col>
      </Row>

      <Divider />
      <Spin
        spinning={
          call_getRun39.status === "load" ||
          call_createRun39.status === "load" ||
          call_updateRun39.status === "load" ||
          call_updateRun39PrintC.status === "load"
        }
      >
        <CustomTable
          columns={columns}
          components={components}
          dataSource={s_tableData}
          scroll={{ y: 200 }}
          pagination={false}
          rowSelection={rowSelection}
        />
      </Spin>

      <Col span={24} className="text-right">
        <Space size={20}>
          <Button type="" size="large" onClick={() => set_s_showModal(false)}>
            {/* 取消 */}
            {t("util.util.close")}
          </Button>

          <Button type="primary" size="large" onClick={() => updatePrint(s_selectedRows)}>
            {/* 列印 */}
            {t("util.util.header_print")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
