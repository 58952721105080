import React from "react";
import styled from "styled-components";
import { IconTripDetails } from "../Icon/index";

const DetailsButton = styled(IconTripDetails)`
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  &:hover {
    font-size: 1.5rem;
  }
`;

export default DetailsButton