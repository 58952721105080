import { Col, Modal, Row, message } from "antd";
import { useContext, useEffect } from "react";
// import ControlTab from "./ControlTab";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import MyButton from "@/components/Button";
import { CustomModal } from "@/components/Custom/AntdCustom";
import { IconArrow } from "@/components/Icon";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { closeQo, finalQo, openQo } from "@/service/api/qcAPI";
import { fontSize } from "@/styles/BasicSetting";

import { WlWocContext } from "../index";
import AddQoModal from "./ModolComponent/AddQoModal";

const CustomIconArrow = styled(IconArrow)`
  font-size: ${fontSize.h1};
`;

export default function ButtonArea({ thisWpc }) {
  const { t } = useTranslation();
  const { s_showAddQo, set_s_showAddQo, c_call_getAllWpc } = useContext(WlWocContext);
  const call_openQo = useAPI(openQo);
  const call_closeQo = useAPI(closeQo);
  const call_finalQo = useAPI(finalQo);

  const openQoHandler = () => {
    Modal.confirm({
      title: <Text level="h3">系統提示</Text>,
      content: <Text level="h3">是否開始檢驗？</Text>,
      okText: t("util.util.ok"), //"確認",
      okType: "primary",
      cancelText: t("util.util.cancel"), //"取消",
      centered: true,
      width: "600px",
      onOk() {
        call_openQo.request(thisWpc);
      },
    });
  };

  useEffect(() => {
    const { status, msg } = call_openQo;
    if (status === "suc") {
      message.success(msg);
      c_call_getAllWpc();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_openQo.status]);

  useEffect(() => {
    const { status, msg } = call_closeQo;
    if (status === "suc") {
      message.success(msg);
      c_call_getAllWpc();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_closeQo.status]);

  useEffect(() => {
    const { status, msg } = call_finalQo;
    if (status === "suc") {
      message.success(msg);
      c_call_getAllWpc();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_finalQo.status]);

  return (
    <div className="p-[20px]">
      {/* <StartTab /> */}
      <Row gutter={[]} className=" w-full" justify="space-between">
        <Col span={4}>
          <MyButton
            height={90}
            width={150}
            color={"#7C79D2"}
            onClick={() => set_s_showAddQo(true)}
            disabled={Object.keys(thisWpc)?.length === 0 ? false : true}
          >
            <Text level="h2" className="tracking-widest">
              {/* {t("WOS.wlwoc.WpcLogin")} */}
              檢驗開單
            </Text>
          </MyButton>
        </Col>
        <Col span={1} className=" flex justify-center items-center">
          <CustomIconArrow />
        </Col>
        <Col span={4}>
          <MyButton
            height={90}
            width={150}
            onClick={openQoHandler}
            color={"#7C79D2"}
            disabled={thisWpc.lifeF === "0" ? false : true}
          >
            <Text level="h2" className="tracking-widest">
              開始檢驗
              {/* {s_isInspection ? "檢驗中" : "開始檢驗"} */}
            </Text>
          </MyButton>
        </Col>
        <Col span={1} className=" flex justify-center items-center">
          <CustomIconArrow />
        </Col>
        <Col span={4}>
          <MyButton
            height={90}
            width={150}
            onClick={() => call_closeQo.request(thisWpc)}
            color={"#7C79D2"}
            disabled={thisWpc.lifeF === "2" ? false : true}
          >
            <Text level="h2" className="tracking-widest">
              完成檢驗
            </Text>
          </MyButton>
        </Col>
        <Col span={1} className=" flex justify-center items-center">
          <CustomIconArrow />
        </Col>
        <Col span={4}>
          <MyButton
            height={90}
            width={150}
            onClick={() => call_finalQo.request(thisWpc)}
            color={"#7C79D2"}
            disabled={thisWpc.lifeF === "7" ? false : true}
          >
            <Text level="h2" className="tracking-widest">
              {/* {t("WOS.util.start")+t("WOS.util.production")} */}
              最終判定
            </Text>
          </MyButton>
        </Col>
      </Row>
      <CustomModal
        key="addQo"
        title="檢驗開單"
        width="60%"
        visible={s_showAddQo}
        centered={false}
        footer={null}
        onCancel={() => set_s_showAddQo(false)}
        // afterClose={() => alert("檢驗開單-afterClose")}
      >
        {s_showAddQo && <AddQoModal setIsModal={set_s_showAddQo} />}
      </CustomModal>
    </div>
  );
}
