import { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import StoreContext from "@/components/Context/StoreContext";
import MyLayout from "@/components/Layout";
import Login from "@/pages/Login";
import NewQualityControl from "@/pages/NewQualityControl";
// 原本桌面應用
// import About from '@/pages/About'
import WlWoc from "@/pages/WlWoc";

const MyRoutes = () => {
  const { storeData } = useContext(StoreContext);
  return (
    <BrowserRouter basename="wos">
      <Routes>
        {/* 斜線導入 */}
        <Route path="/" element={<Navigate to="/WlWoc" />} />

        <Route path="/" element={<MyLayout />}>
          <Route path="WlWoc" element={<WlWoc />} />
          {/* <Route path="MC" element={<MC />} /> */}
          {/* <Route path="QC" element={<QC />} /> */}
          {/* <Route path="TK" element={<TableKanban />} /> */}
          {/* <Route path="TK" element={<TableKanban />} /> */}
          {/* <Route path="ticker-dashboard" element={<TickerDashboard />} /> */}
          {/* <Route path="pis" element={<PIS />} /> */}
          {/* <Route path="QualityControl" element={<QualityControl />} /> */}
          <Route path="new-qualityControl" element={<NewQualityControl />} />
          {/* <Route path="wpc-printer" element={<WpcPrinter />} /> */}
          {/* <Route path="wpc-printer/wpc-card" element={<WpcCard />} /> 列印切版測試用 */}
          {/*航太廠A4 1張工單 6製程*/}
          {/* <Route path="wpc-printer/wpc-card_tech" element={<WpcCard_Tech />} /> 列印切版測試用 */}
          {/*科技廠A4 1張工單 6製程*/}
          {/* <Route path="wpc-printer/wpc-card-a4n2p3won" element={<WpcCardA4N2P3Won />} />{" "} */}
          {/*列印切版測試用*/}
          {/*航太A4 2張工單 3製程*/}
          {/* <Route path="wpc-printer/wpc-card-a5n6-process" element={<WpcCardA5N6Process />} />{" "} */}
          {/*列印切版測試用*/}
          {/*A5 一張工單 6製程*/}
          {/* <Route path="wpc-printer/wpc-card-a5n2p2won" element={<WpcCardA5N2P2Won />} />{" "} */}
          {/*列印切版測試用*/}
          {/*A5 2張工單 2製程*/}
          {/* <Route path="dispach-manage" element={<DispachManage />} /> */}
          {/* <Route path="line-dispach-manage" element={<LineDispachManage />} /> */}
          {/* 原本桌面應用 */}
          {/* <Route path="parts-binding" element={<PartsBinding />} /> */}
          {/* <Route path="ppidp" element={<PPIDP />} /> */}
          {/* 單機SPC */}
          {/* <Route path="spc" element={<SPC />} /> */}
          {/* 單機SPC */}
          {/* <Route path="spcII" element={<SPCII />} /> */}
        </Route>
        <Route path="login" element={<Login />} />

        {/* 未匹配 */}
        <Route path="*" element={<Navigate to="/WlWoc" />} />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRoutes;
