import { Col, Row } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import MyButton from "@/components/Button";
import CustomModal from "@/components/Custom/CustomModal";
import Text from "@/components/Text";

import { WlWocContext } from "../../index";
import Report from "./ModolComponent/Abnormal/Report";
import Resolve from "./ModolComponent/Abnormal/Resolve";

function AbnormalTab() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { thisWpc, c_call_getAllWpc, processManagementAuth } = useContext(WlWocContext);

  const [isResolve, setIsResolve] = useState("false");

  const [isReport, setIsReport] = useState("false");

  return (
    <Row gutter={[12, 0]}>
      <Col span={12} className="flex justify-center items-center">
        <MyButton
          height={127}
          width={280}
          color="#D86363"
          // disabled={processManagementAuth.abcReport}
          onClick={() => setIsReport("true")}
        >
          <Text level="h2" className="tracking-widest">
            {t("WOS.wlwoc.abcReport")}
          </Text>
        </MyButton>
      </Col>
      <Col span={12} className="flex justify-center items-center">
        <MyButton
          height={127}
          width={280}
          color={theme.reError}
          // disabled={processManagementAuth.abcDismiss}
          onClick={() => setIsResolve("true")}
        >
          <Text level="h2">{t("WOS.wlwoc.abcDismiss")} </Text>
        </MyButton>
      </Col>

      <CustomModal
        key="Resolve"
        title={t("WOS.wlwoc.abcDismiss")}
        visible={isResolve === "true"}
        onCancel={() => setIsResolve("false")}
        footer={null}
        width="80%"
        afterClose={() => c_call_getAllWpc()}
      >
        <Resolve setIsModal={setIsResolve} thisWpc={thisWpc} />
      </CustomModal>

      <CustomModal
        key="report"
        title={t("WOS.wlwoc.abcReport")}
        visible={isReport === "true"}
        onCancel={() => setIsReport("false")}
        footer={null}
        width="70%"
        afterClose={() => c_call_getAllWpc()}
      >
        <Report setIsModal={setIsReport} thisWpc={thisWpc} />
      </CustomModal>
    </Row>
  );
}

export default AbnormalTab;
