import { Button, Col, DatePicker, Divider, Row, Select, Space, Spin, message } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CustomSearch, CustomTable } from "@/components/Custom/AntdCustom";
import { IconArrow, IconScanBarcode } from "@/components/Icon";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getAllOkItem } from "@/service/api/mcAPI.js";
import { addBatchOk, getAllWpc, getBatchOk, getMcPDA } from "@/service/api/publicAPI";
import { getAllStaffs } from "@/service/api/wosAPI";
import numberWithCommas from "@/util/numberWithCommas";

const BMSCustomTable = styled(CustomTable)`
  .ant-table-thead .ant-table-cell {
    font-size: 1.6 !important;
    border-top: 1px #dcdcdc solid !important;
    border-bottom: 1px #dcdcdc solid !important;
    border-left: 0px #dcdcdc solid !important;
    border-right: 0px #dcdcdc solid !important;
    background-color: #bdddf2 !important ;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 2px;
  }
  .ant-table-thead > tr > th {
    background-color: #ececec;
    color: #6c6c6c;
  }
  /* 恢復線條 */

  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    /* border-width: 0 1px 1px 1px !important; */
    /* border-style: none solid solid solid !important; */
    /* border-color: transparent #dcdcdc #dcdcdc #dcdcdc !important; */
  }

  .ant-table-thead th:last-child.ant-table-cell.ant-table-cell-scrollbar {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }
  .ant-table-content {
    border: 2px solid #dcdcdc;
  }
`;
const CustomSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 20px;
    /* padding-top: 12px !important; */
  }
  /* .ant-select-selector {
    height: 60px !important;
  } */
  .ant-select-selection-search-input {
    /* padding-top: 25px !important; */
    font-size: 20px;
  }
`;
const Box = styled.div`
  background-color: ${({ theme }) => theme.componentBg};
  height: 208px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
  z-index: 10;
`;

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    margin: auto;
    height: 120px;
    width: 2px;
    background-color: #d9d9d9;
  }
`;

export default function View({ thisWpc = {}, set_s_showModal }) {
  const { t } = useTranslation();
  const ref_customSearch = useRef(null);
  const { Option } = Select;
  const currentDate = moment();
  const [s_enterLongText, set_s_enterLongText] = useState("");
  const [s_batchEnterText, set_s_batchEnterText] = useState("");

  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );
  const [s_batchFocusText, set_s_batchFocusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  const [s_showBatchBarcode, set_s_showBatchBarcode] = useState("");
  // 控制effect用
  const [is_longbarcodeInput, set_is_longbarcodeInput] = useState(false);
  const [is_batchBarcodeInput, set_is_batchBarcodeInput] = useState(false);
  const [s_thisWpc, set_s_thisWpc] = useState({});

  // show
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState("");
  const [s_batchBarcodeObj, set_s_batchBarcodeObj] = useState("");

  const initBarcodeObj = {
    dealPN: null,
    opcode: null,
    jobN: null,
  };

  const [s_batchTableData, set_s_batchTableData] = useState([]);

  const batchData = s_batchTableData.filter((batch) => {
    return batch.lifeF !== "0";
  });

  const lifeF0BatchData = s_batchTableData.filter((batch) => {
    return batch.lifeF === "0";
  });

  const [s_date, set_s_date] = useState(currentDate);
  const [s_shift, set_s_shift] = useState(undefined);
  const [s_cardID, set_s_cardID] = useState([]);

  const [s_okItemOptions, set_s_okItemOptions] = useState([]);
  const firstOkItem = s_okItemOptions[0]?.value === undefined ? "" : s_okItemOptions[0]?.value;
  const [s_okCheckProducts, set_s_okCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });
  const call_getAllStaffs = useAPI(getAllStaffs);
  const call_getAllWpc = useAPI(getAllWpc);
  const call_getBatchOk = useAPI(getBatchOk);
  const call_getMcPDA = useAPI(getMcPDA);

  const call_getAllOkItem = useAPI(getAllOkItem);
  const call_addBatchOk = useAPI(addBatchOk);

  const scanningTableColumns = [
    {
      title: t("WOS.util.batchID"), //"數量",
      dataIndex: "batchID",
      align: "center",
      // width: "70px",
    },
    {
      title: t("WOS.util.batchbom"), //"數量",
      dataIndex: "batchbom",
      align: "right",
      render: (num) => numberWithCommas(num),
      // width: "70px",
    },
    {
      title: t("util.util.lifeF"), //"批號",
      dataIndex: "lifeF",
      align: "center",

      render: (value) => t(`WOS.util.mclifeF_${value}`),
    },
    {
      title: t("util.util.lifeFT"), //"批號",
      dataIndex: "lifeFT",
      align: "center",

      render: (text) => moment(text).format("MM-DD HH:mm"),
    },
  ];

  // 定義隔行變色函式
  const getRowClassName = (_, index) => {
    return index % 2 === 1 ? "once" : "twoe";
  };
  // 定義固定的滾動配置
  const scrollConfig = { y: 200 };

  useEffect(() => {
    ref_customSearch.current.focus();
    call_getAllStaffs.request();
    call_getAllOkItem.request();
  }, []);

  // 這邊的主要是對條碼進行解析和處理
  useEffect(() => {
    try {
      if (!is_longbarcodeInput) return;
      set_is_longbarcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        // set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);
      // const parseBarcode = parseString(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
            ${t("WOS.wlwoc.noWoNScan")}
            ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
          ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      // set_s_tableData([]);
    }
  }, [is_longbarcodeInput]);

  useEffect(() => {
    if (!s_barcodeObj.jobN) return;
    if (Object.keys(s_barcodeObj).length > 0) {
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    }
  }, [s_barcodeObj]);

  useEffect(() => {
    try {
      if (!is_batchBarcodeInput) return;
      set_is_batchBarcodeInput(false);

      if (!s_showBatchBarcode || s_showBatchBarcode === "") {
        message.error(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_batchBarcodeObj("");
        return;
      }
      // -------------old----------------
      // const batchID = s_showBatchBarcode.split(",")[4];
      // console.log(batchID);
      // if (!batchID) {
      //   message.error("no batchID");
      //   set_s_batchBarcodeObj("");
      //   return;
      // }
      // if (s_showBatchBarcode === s_batchBarcodeObj) {
      //   message.error("BatchBarcode duplicate scanning");
      //   return;
      // }
      // set_s_batchBarcodeObj(s_showBatchBarcode);
      // ---------------new------------------
      const parseBarcode = JSON.parse(s_showBatchBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
            ${t("WOS.wlwoc.noWoNScan")}
            ，${s_showBatchBarcode}`);
        set_s_batchBarcodeObj(initBarcodeObj);
        return;
      }
      console.log(parseBarcode);
      set_s_batchBarcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      message.error(`${t("WOS.wlwoc.barcodeParsingFailure")}
      ，${s_showBatchBarcode}`);
      set_s_batchBarcodeObj("");
    }
  }, [is_batchBarcodeInput]);

  useEffect(() => {
    // console.log(s_batchBarcodeObj);
    // if (!s_batchBarcodeObj) return;
    // const batchID = s_batchBarcodeObj.split(",")[4];
    const itemID = s_okCheckProducts.product.split("_")[0];
    const { batchID, dealPN } = s_batchBarcodeObj;
    if (Object.keys(s_thisWpc).length === 0) return;
    if (!batchID) return;
    if (!dealPN) return;
    if (!s_shift) return message.error("no shift");
    if (!itemID) return message.error("no itemID");
    const payload = { ...s_thisWpc, batchID, dealPN, itemID, shift: s_shift };
    console.log(payload);
    call_addBatchOk.request(payload);
    // console.log("call call_addBatchOk ");
  }, [s_batchBarcodeObj]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      message.success(call_getAllWpc.msg);
      set_s_alertMsg(call_getAllWpc.msg);
      set_s_thisWpc(call_getAllWpc.data.tableData[0]);
      call_getBatchOk.request(call_getAllWpc.data.tableData[0]);
      call_getMcPDA.request({ UUID: call_getAllWpc.data.tableData[0].UUID });
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表
      message.error(call_getAllWpc.msg);
    }
  }, [call_getAllWpc.status]);

  useEffect(() => {
    if (call_getBatchOk.status === "suc") {
      set_s_batchTableData([...call_getBatchOk.data]);
      call_getMcPDA.request({ UUID: s_thisWpc.UUID });
    }
  }, [call_getBatchOk.status]);

  useEffect(() => {
    if (call_getAllOkItem.status === "suc") {
      set_s_okItemOptions(
        call_getAllOkItem.data.map((x) => ({
          label: `${x.itemID}_${x.itemNM}`,
          value: `${x.itemID}_${x.itemNM}`,
        }))
      );

      set_s_okCheckProducts((prev) => ({ ...prev, product: "00_ALL(others)" }));
    }
  }, [call_getAllOkItem.status]);

  useEffect(() => {
    if (call_addBatchOk.status === "suc") {
      message.success(call_addBatchOk.msg);
      call_getBatchOk.request(s_thisWpc);
    } else if (call_addBatchOk.status === "err") {
      // 只要失敗就清空途單列表

      message.error(call_addBatchOk.msg);
    }
    // set_s_batchBarcodeObj("");
  }, [call_addBatchOk.status]);

  return (
    <Row gutter={[0, 6]}>
      <Col span={24}>
        <Row gutter={[0, 25]}>
          <Col span={24} className="w-full ">
            <Row gutter={[0, 0]}>
              <Col span={6} className="">
                <div className="w-full flex flex-col ">
                  <Text level="h3">
                    {t("ADM.util.time")}
                    {":"}
                  </Text>
                  <DatePicker
                    size="large"
                    className="w-4/5"
                    value={s_date}
                    onChange={(date, dateString) => {
                      set_s_date(date);
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="w-full flex flex-col">
                  <Text level="h3">
                    {/* 班別 */}
                    {t("WOS.util.shift")}
                    {":"}
                  </Text>
                  <CustomSelect
                    size="large"
                    className="w-4/5"
                    value={s_shift}
                    onChange={(e) => {
                      set_s_shift(e);
                    }}
                  >
                    <Option value="SHIFTI">{"SHIFT I"}</Option>
                    <Option value="SHIFTII">{"SHIFT II"}</Option>
                    <Option value="SHIFTIII"></Option>
                  </CustomSelect>
                </div>
              </Col>
              <Col span={6}>
                <div className="w-full flex flex-col">
                  <Text level="h3">
                    {t("WOS.wpc-printer.jobNumber")}
                    {":"}
                  </Text>

                  <CustomSelect
                    placeholder={t("WOS.util.scanJobNumber")}
                    size="large"
                    showSearch
                    className="w-4/5"
                    optionFilterProp="label"
                    value={s_cardID}
                    mode="multiple"
                    onChange={(cardID, data) => {
                      set_s_cardID(cardID);
                    }}
                    options={
                      call_getAllStaffs.data?.map((x) => ({
                        label: x.cardID + "_" + x.peopleNM,
                        value: x.cardID,
                        data: x,
                      })) || []
                    }
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className="w-full flex flex-col ">
                  {/* 良品品項 */}
                  <Text level="h3">
                    {t("WOS.util.okItem")}
                    {":"}
                  </Text>

                  <Select
                    size="large"
                    showSearch
                    style={{ width: "80%" }}
                    defaultValue={firstOkItem}
                    value={s_okCheckProducts.product}
                    options={s_okItemOptions}
                    onChange={(e) => {
                      console.log(e);
                      set_s_okCheckProducts({
                        ...s_okCheckProducts,
                        product: e,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24} className="flex flex-col gap-3 justify-center items-center">
        <div className="flex  w-full">
          {/* <Text level="h2" className="mr-2">Long </Text> */}
          <CustomSearch
            ref={ref_customSearch}
            autoFocus
            size="large"
            allowClear
            placeholder={s_focusText}
            suffix={<IconScanBarcode className="text-[26px]" />}
            value={s_enterLongText}
            onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
            onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
            onChange={(e) => {
              set_s_enterLongText(e.target.value);
            }}
            onSearch={() => {
              set_s_enterLongText("");
              set_s_showBarcode(s_enterLongText);
              set_is_longbarcodeInput(true);
            }}
          />
        </div>
      </Col>
      <Col span={24} className="mb-5">
        <Row className=" w-full  mb-5">
          <div className="bg-[#cedee9] w-full ">
            <Text
              level="h3"
              type="error"
              style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
            >
              <span className="text-[#6C6C6C] font-bold">{t("util.util.systemReport")}:</span>
              {s_thisWpc.lifeF ? ` ${t(`WOS.util.lifeF_${s_thisWpc.lifeF}`)},` : ""}
              {s_alertMsg}
            </Text>
          </div>
        </Row>

        <Row className="bg-[#F0F9FF]">
          <Col span={12}>
            <Row className="w-full flex ">
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "util.util.wo"
                    )}`}</div>
                    <div className="whitespace-normal   text-[30px]"> :</div>
                  </div>
                  <span className="text-black  self-center text-[25px] font-bold  ">
                    {s_thisWpc.woN ? `${s_thisWpc.woN} ${s_thisWpc.opcode}` : ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "util.util.PN"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-black self-center text-[25px] font-bold  ">
                    {s_thisWpc.PN ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24} className=" ">
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "util.util.custPN"
                    )} `}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#39883d] self-center text-[25px] font-bold  ">
                    {s_thisWpc.dealPN ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24} className=" ">
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "util.util.pdtNM"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#3eb390]   w-[50%]  self-center text-[25px] font-bold  ">
                    {s_thisWpc.pdtNM ?? ""}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex justify-start  w-full  ">
                  <div className="flex      w-[35%]">
                    <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                      "util.util.planqty"
                    )}`}</div>
                    <div className="whitespace-normal  text-[30px]"> :</div>
                  </div>
                  <span className="text-[#3e4ab3] self-center text-[25px] font-bold  ">
                    {s_thisWpc.planqty ? `${s_thisWpc.planqty}` : ""}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Box className="bg-[#FFF8F2]">
              <Row justify="center" align="middle" gutter={[128, 0]} className="h-full">
                <Col span={5} className="flex  flex-col justify-center items-center">
                  <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                    {t("WOS.util.inC")}
                  </Text>
                  <span className="text-[32px] text-[#4A83D1]">
                    {numberWithCommas(call_getMcPDA?.data?.inC) ?? 0}
                  </span>
                </Col>
                <Line />
                <Col span={5} className="flex flex-col justify-center items-center ">
                  <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                    {t("WOS.util.okC")}
                  </Text>
                  <span className="text-[32px] font-bold text-[#77D160]">
                    {numberWithCommas(call_getMcPDA?.data?.okC ?? 0)}
                  </span>
                </Col>
                <Line />
                <Col span={5} className="flex flex-col justify-center items-center ">
                  <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                    {t("WOS.util.ngC")}
                  </Text>
                  <span className="text-[32px] text-[#F37E7E]">
                    {numberWithCommas(call_getMcPDA?.data?.ngC ?? 0)}
                  </span>
                </Col>
                <Line />
                <Col span={5} className="flex flex-col justify-center items-center ">
                  <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                    {t("WOS.util.balshrot")}
                  </Text>
                  <span className="text-[32px] text-gray-300">
                    {numberWithCommas(call_getMcPDA?.data?.bal ?? 0)}
                  </span>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Col>
      <Spin spinning={is_batchBarcodeInput} className="w-full">
        <Col span={24} className="flex justify-center items-center">
          <CustomSearch
            size="large"
            allowClear
            loading={is_batchBarcodeInput}
            placeholder={s_batchFocusText}
            suffix={<IconScanBarcode className="text-[26px]" />}
            value={s_batchEnterText}
            onFocus={() => set_s_batchFocusText(t("WOS.util.focusText"))}
            onBlur={() => set_s_batchFocusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
            onChange={(e) => {
              if (Object.keys(s_thisWpc).length === 0) return;
              set_s_batchEnterText(e.target.value);
            }}
            onSearch={() => {
              if (Object.keys(s_thisWpc).length === 0) return;
              set_s_batchEnterText("");
              set_s_showBatchBarcode(s_batchEnterText);
              set_is_batchBarcodeInput(true);
            }}
          />
        </Col>

        <Col span={24}>
          <Row>
            <Col span={11}>
              <Text level="h2" className="px-0">
                指定批號
              </Text>
              <BMSCustomTable
                columns={scanningTableColumns}
                dataSource={lifeF0BatchData}
                scroll={scrollConfig}
                size="small"
                rowClassName={getRowClassName}
                pagination={false}
              />
            </Col>
            <Col span={2} className="flex justify-center items-center">
              <IconArrow className="text-[45px]" />
            </Col>
            <Col span={11}>
              <Text level="h2" className="">
                掃入批號
              </Text>
              <BMSCustomTable
                columns={scanningTableColumns}
                dataSource={batchData}
                scroll={scrollConfig}
                size="small"
                rowClassName={getRowClassName}
                pagination={false}
              />
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={24} className="text-right">
          <Space size={20}>
            <Button type="" size="large" onClick={() => set_s_showModal(false)}>
              {/* 取消 */}
              {t("util.util.close")}
            </Button>
          </Space>
        </Col>
      </Spin>
    </Row>
  );
}
