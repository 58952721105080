import { Button, Col, PageHeader, Row } from "antd";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CustomModal from "@/components/Custom/CustomModal";
import Text from "@/components/Text";
// import DetailedArea from "./CtrlArea/DeTailedArea"; //途單詳情
import WpcList from "@/components/WpcList/index";
import useWebSocket from "@/hooks/useWebSocket";
import { openSocket } from "@/service/ws/ws";

import AlexModuleArea from "./CtrlArea/AlexModuleArea"; //模組管理
import BarcodeInstruction from "./CtrlArea/BarcodeInstruction/VerAlex";
import ButtonArea from "./CtrlArea/ButtonArea"; //主操作區
// import StatusLight from "@/components/StatusLight";
import PDFview from "./CtrlArea/PDF/PDFview";
import WoInfoArea from "./CtrlArea/WoInfoArea"; //途單資訊

const WlWocStyle = styled.div`
  //每個區塊外面的邊框
  .normal-component {
    background-color: ${({ theme }) => theme.componentBg};
    margin: 8px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
    z-index: 10;
  }
  .special-component {
    background-color: ${({ theme }) => theme.specialComponentBg};
  }
`;

export const WlWocContext = createContext({});

export default function WlWoc() {
  const { t } = useTranslation();
  //當前被選中的wpc
  const [thisWpc, setThisWpc] = useState({});
  // 接受 rtn 上來的 call_getAllWpc.req
  const [call_getAllWpc, setCall_getAllWpc] = useState([]);

  // 透過 UUID 有無值 判斷是否開啟 socket 連線
  const [thisUUID, setThisUUID] = useState("");

  const [s_barcodeModal, set_s_barcodeModal] = useState(false);

  const [s_pdfModal, set_s_pdfModal] = useState(false);

  const [s_viewPDF, set_s_viewPDF] = useState("SOP");

  const ws_openSocket = useWebSocket(openSocket);

  const [processManagementAuth, setProcessManagementAuth] = useState({
    wpcTable: false,
    productionManagement: false, //生管派工
    wpcLogin: false,
    readyStart: false, //事前準備
    prefixWork: false,
    openWork: false,
    moldManagement: false,
    timeManagement: false,
    offWork: false, //報完工
    suffixWork: false, //後拆單
    offLine: false, //報下線
    pause: false, //報停工
    resumeWork: false, //報復工
    abcReport: false, //異常提報
    abcDismiss: false, //異常解除
    controlTab: false, //拆單
  });
  console.log(thisWpc);

  useEffect(() => {
    if (Object.keys(thisWpc).length > 1 && (thisWpc.lifeF === "2" || thisWpc.lifeF === "4")) {
      // 觸發 UUID 的 useEffect
      setThisUUID(thisWpc.UUID);
    } else {
      setThisUUID("");
    }
  }, [thisWpc]);

  useEffect(() => {
    // 在這邊判斷是否開啟 webSocket (觸發的effect name : ws_openSocket.socketData)
    if (thisWpc?.UUID === thisUUID && thisUUID !== "") {
      ws_openSocket.changeParams({ UUID: thisUUID });
      ws_openSocket.isOpenSwitch(true);
    } else {
      ws_openSocket.isOpenSwitch(false);
    }
  }, [thisUUID]);

  // 在這邊判斷按鈕權限
  useEffect(() => {
    // 如果thisWpc 有這個權限 就用它的
    const mold = thisWpc.setmold ? false : true;
    const time = thisWpc.setwhm ? false : true;
    // 有線別的話  報開工 開始生產 停工 復工不能按
    const wlauth = thisWpc.wlID ? true : false;
    // true 代表 disabled , false 代表不 disabled
    switch (thisWpc.lifeF) {
      case "1": // 登錄
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: false, //途單索引
          productionManagement: false, //生管派工
          wpcLogin: false, //途單登錄
          readyStart: wlauth ? wlauth : false, //事前準備
          prefixWork: false, //前拆單
          openWork: wlauth ? wlauth : true, //開始生產
          moldManagement: true, //模具管理
          timeManagement: true, //工時管理
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });
        break;
      case "B": //報開工
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: true, //途單索引
          productionManagement: false, //生管派工
          wpcLogin: false, //途單登錄
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : false,
          //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: mold,
          timeManagement: false,
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: false, //OEM權限
          chooseWe: true, //機碼選擇
        });
        break;
      case "R": //
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: true,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : false, //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: mold,
          timeManagement: time,
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });
        break;
      case "2": //開始生產
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: true,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true, //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: true,
          timeManagement: false,
          offWork: false, //報完工
          suffixWork: false, //後拆單
          offLine: false, //報下線
          pause: wlauth ? wlauth : false, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: thisWpc.situF === "R", //異常提報
          abcDismiss: false, //異常解除
          OEM: false, //OEM權限
          chooseWe: false, //機碼選擇
        });
        break;
      case "4": //報復工
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: true,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true, //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: true,
          timeManagement: false,
          offWork: false, //報完工
          suffixWork: false, //後拆單
          offLine: false, //報下線
          pause: wlauth ? wlauth : false, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: thisWpc.situF === "R", //異常提報
          abcDismiss: false, //異常解除
          OEM: false, //OEM權限
          chooseWe: false, //機碼選擇
        });
        break;
      case "3": //報停工
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: true,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true, //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: true,
          timeManagement: true, //lifeF = br246
          offWork: false, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : false, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });

        break;
      case "5":
        setProcessManagementAuth({
          controlTab: false, //拆單
          wpcTable: false,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true, //條件式 需要判斷有無購買模具管理 工時一定要有
          moldManagement: true,
          timeManagement: true, //lifeF = br246
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });

        break;
      case "7":
      case "C": //報完工
        setProcessManagementAuth({
          controlTab: true, //拆單
          wpcTable: false,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true,
          moldManagement: true,
          timeManagement: true,
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });

        break;
      default:
        //預設報完工狀態
        setProcessManagementAuth({
          wpcTable: false,
          productionManagement: false, //生管派工
          wpcLogin: false,
          readyStart: wlauth ? wlauth : true, //事前準備
          prefixWork: true,
          openWork: wlauth ? wlauth : true,
          moldManagement: true,
          timeManagement: true,
          offWork: true, //報完工
          suffixWork: true, //後拆單
          offLine: true, //報下線
          pause: wlauth ? wlauth : true, //報停工
          resumeWork: wlauth ? wlauth : true, //報復工
          abcReport: true, //異常提報
          abcDismiss: true, //異常解除,
          OEM: true, //OEM權限
          chooseWe: true, //機碼選擇
        });
    }
  }, [thisWpc]);

  useEffect(() => {
    if (ws_openSocket.socketData.status) {
      setThisWpc({
        ...ws_openSocket.socketData.data,
        key: ws_openSocket.socketData.data.UUID,
        // wlID: Cookies.get("wlID"),
        // wsID: Cookies.get("wsID"),
        linemixStr:
          ws_openSocket.socketData.data.linemix === 1
            ? t("WOS.util.linemix_1") //"左線"
            : ws_openSocket.socketData.data.linemix === 2
            ? t("WOS.util.linemix_2")
            : t("WOS.util.linemix_0"),
        wotype:
          ws_openSocket.socketData.data.wotype === 0
            ? t("WOS.util.wotype_0")
            : // "正常工單"
            ws_openSocket.socketData.data.wotype === 1
            ? t("WOS.util.wotype_1")
            : // "重工工單"
              "",
      });
    }
  }, [ws_openSocket.socketData]);

  return (
    <WlWocStyle>
      {/* 最重要的兩個 當前途單 跟 call_getAllWpc */}
      <WlWocContext.Provider
        value={{
          thisWpc: thisWpc,
          c_call_getAllWpc: call_getAllWpc,
          processManagementAuth: processManagementAuth,
        }}
      >
        <PageHeader
          className="p-0"
          backIcon={false}
          title={
            <Text level="h2" mark>
              {t("WOS.util.wlwoc")}
              {/* 生產控制 */}
            </Text>
          }
          extra={[
            // <Button
            //   type="primary"
            //   key="barcode"
            //   size="large"
            //   onClick={() => set_s_barcodeModal(true)}
            // >
            //   {t("WOS.util.barcodeCommands")}
            //   {/* 條碼指令 */}
            // </Button>,
            // <StatusLight thisWpc={thisWpc} key="StatusLight" />,
            <Button
              key="SOP"
              size="large"
              className="enabled:text-[#C85B5F] enabled:border-[#C85B5F] text-[20px]"
              disabled={Object.keys(thisWpc).length === 0}
              onClick={() => {
                set_s_pdfModal(true);
                set_s_viewPDF("SOP");
              }}
            >
              {t("WOS.util.SOPBrowsing")}
            </Button>,
            // <Button
            //   key="SIP"
            //   size="large"
            //   className="enabled:text-[#71AFF8] enabled:border-[#71AFF8] text-[20px] "
            //   disabled={Object.keys(thisWpc).length === 0}
            //   onClick={() => {
            //     set_s_pdfModal(true);
            //     set_s_viewPDF("SIP");
            //   }}
            // >
            //   {t("WOS.util.SIPPreview")}
            // </Button>,
          ]}
        />
        <Row justify="center" align="middle">
          <Col span="12">
            <div className="normal-component h-[210px]">
              <ButtonArea thisWpc={thisWpc} />
            </div>
          </Col>
          <Col span="12">
            <WoInfoArea thisWpc={thisWpc} />
          </Col>
          <Col span="24">
            {/* <div className="normal-component"> */}
            <AlexModuleArea
              thisWpc={thisWpc}
              processManagementAuth={processManagementAuth}
              call_getAllWpc={call_getAllWpc}
            />
            {/* </div> */}
          </Col>
          {/* <Col span="12">
            <div className="normal-component">
              <DetailedArea thisWpc={thisWpc} />
            </div>
          </Col> */}
          <Col span="24">
            <WpcList
              thisWpc={thisWpc}
              setThisWpc={setThisWpc}
              setCall_getAllWpc={setCall_getAllWpc}
              wpcType="woc"
            />
          </Col>
        </Row>
        <CustomModal
          key="BarcodeInstruction"
          title={t("WOS.util.barcodeCommands")}
          // "條碼指令"
          visible={s_barcodeModal}
          onCancel={() => set_s_barcodeModal(false)}
          footer={null}
          width="90%"
          afterClose={() => call_getAllWpc()}
        >
          <BarcodeInstruction setIsModal={set_s_barcodeModal} />
        </CustomModal>

        <CustomModal
          key="PDF"
          title={`${s_viewPDF}
          ${t("WOS.util.preview")}`}
          visible={s_pdfModal}
          onCancel={() => set_s_pdfModal(false)}
          footer={null}
          width="70%"
          afterClose={() => call_getAllWpc()}
        >
          <PDFview setIsModal={set_s_pdfModal} thisWpc={thisWpc} doctype={s_viewPDF} />
        </CustomModal>
      </WlWocContext.Provider>
    </WlWocStyle>
  );
}
