import styled from "styled-components";

import { fontSize } from "@/styles/BasicSetting";

const handleFontSize = (size) => {
  switch (size) {
    case "h1":
      return fontSize.h1;
    case "h2":
      return fontSize.h2;
    case "h3":
      return fontSize.h3;
    case "h4":
      return fontSize.h4;
    case "h5":
      return fontSize.h5;
    case "p":
      return fontSize.p;
    default:
      return fontSize.p;
  }
};

const handleType = (type) => {
  switch (type) {
    case "focus":
      return "focus";
    case "primary":
      return "primary";
    case "error":
      return "error";
    case "warning":
      return "warning";
    case "success":
      return "success";
    case "disabled":
      return "disabledTextColor";
    case undefined:
      return "text";
    default:
      return "text";
  }
};

// interface titleType {
//   className?: string,
//   children?: string,
//   level?:string,
//   mark?:boolean,
//   type?:string,
//   vice?:boolean,
//   style?:object,
// }

const TitleElement = ({
  className,
  children,
  level = "h1",
  mark = false,
  type = "text",
  vice = false,
  style,
}) => {
  return (
    <div className={className} style={style}>
      <span className={`text ${mark ? "mark" : ""} ${vice ? "vice" : ""}`}>{children}</span>
    </div>
  );
};

/**
 *
 * @param {string} level"h1~p"
 * @param {bool} mark "遮罩"
 * @param {string} type     
 *   "primary"
     "error"
     "warning"
     "success"
     "disabled"
     "focus"
  @param {bool} vice "opacity 0.6"
 */

const Text = styled(TitleElement)`
  font-weight: 600;
  font-size: ${({ level }) => handleFontSize(level)};
  color: ${({ theme, type }) => theme[handleType(type)]};
  line-height: 1.5;
  font-weight: 600;
  /* white-space: nowrap; */
  .vice {
    opacity: 0.6;
  }
  .text {
    margin: 0px 5px;
    padding: 0px 5px;
    vertical-align: middle;
  }
  /* 有背景時字皆為白色 */
  .mark {
    color: white;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 5px;
    padding: 10px 10px;
  }
`;

export default Text;
