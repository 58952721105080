import React from "react";

//創建一個即時更新的useState
//第一個返回為default
//dispatch 為setState({})
//透過useRef取得最新狀態
// ref.current 為最新狀態
const useStateRef = (defaultValue) => {
  let [state, setState] = React.useState(defaultValue);
  let ref = React.useRef(state);

  let dispatch = React.useCallback(function (val) {
    ref.current = typeof val === "function" ? val(ref.current) : val;

    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export default useStateRef;
