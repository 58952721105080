import { Button, Col, Divider, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomTable } from "@/components/Custom/AntdCustom";
import Light from "@/components/Light";
import SquareLight from "@/components/Light/SquareLight";
import Text from "@/components/Text";
import checkUndefined from "@/util/checkUndefined";
import { FullscreenOutlined, SearchOutlined } from "@ant-design/icons";

import { QCListCompoment } from "./index";

export default function QCDetailModal(props) {
  const { setIsModal } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <QCListCompoment isModal={true} {...props} />
      </Col>

      <Divider />
      <Col span={24} className="text-right">
        <Button size="large" onClick={() => setIsModal(false)}>
          {t("util.util.close")}
          {/* 關閉 */}
        </Button>
      </Col>
    </Row>
  );
}

export function QCDetailModal1({ setIsModal }) {
  const { t } = useTranslation();
  const [thisWpc, setThisWpc] = useState();
  const wpcDetailsTableCol = [
    {
      title: t("util.util.woN"), //"工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.wpcset"), // "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.wpcSN"), //"途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.prefix"), // "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.suffix"), //"後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      key: "planqty",
      render: (str) => checkUndefined(str),
    },
    {
      //planQty - d_planQty - d_inC
      title: t("WOS.util.goal") + t("WOS.util.qty"), //"目標產量",
      dataIndex: "goalqty",
      key: "goalqty",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.d_planqty"), // "前拆數量",
      dataIndex: "d_planqty",
      key: "d_planqty",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.d_inC"), //"後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.offfix"), // "下線碼",
      dataIndex: "offfix",
      key: "offfix",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.woMix"), // "多單碼",
      dataIndex: "woMix",
      key: "woMix",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.lineMix"), //"混線碼",
      dataIndex: "lineMix",
      key: "lineMix",
      render: (num) => checkUndefined(num),
    },
  ];
  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 10, //回傳筆數

    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const tableColumns = [
    {
      // title: t("WOS.wlwoc.inspN"), // "檢驗單號",
      title: "狀態燈號", // "檢驗單號",
      dataIndex: "situF",
      width: "90px",
      align: "center",
      render: (str) => {
        switch (str) {
          case "B":
            return <Light size="20px" type="main" />;
          case "G":
            return <Light size="20px" type="success" />;
          case "Y":
            return <Light size="20px" type="warning" />;
          case "R":
            return <Light size="20px" type="error" />;
          default:
            return <Light size="20px" disabled />;
        }
      },
    },
    {
      // title: t("WOS.wlwoc.inspN"), // "檢驗單號",
      title: "合格燈", // "檢驗單號",
      dataIndex: "situF",
      width: "90px",
      align: "center",
      render: (str) => {
        switch (str) {
          case "B":
            return <SquareLight size="20px" type="main" />;
          case "G":
            return <SquareLight size="20px" type="success" />;
          case "Y":
            return <SquareLight size="20px" type="warning" />;
          case "R":
            return <SquareLight size="20px" type="error" />;
          default:
            return <SquareLight size="20px" disabled />;
        }
      },
    },
    {
      // title: t("WOS.util.typeInspection"), // "線別",
      title: "檢驗單", // "線別",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      // title: t("WOS.util.typeInspection"), // "線別",
      title: "檢驗型態", // "線別",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      // title: t("WOS.util.typeInspection"), // "線別",
      title: "工(源)單", // "線別",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("util.util.PN"),
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("WOS.util.pdtNM"),
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("WOS.util.pf"), // "製程",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("WOS.wlwoc.We"), // "機碼",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("util.util.ws"), // "工站",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("util.util.pwc"), // "工作中心",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      // title: t("util.util.ws"), // "工作中心",
      title: "創建時間", // "工作中心",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("WOS.util.createID"), // "工作中心",
      // title: "創建時間", // "工作中心",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
  ];

  const fakeTableData = [
    {
      situF: "B",
      wlID: "WLID001",
    },
    {
      situF: "G",
      wlID: "WLID002",
    },
    {
      situF: "Y",
      wlID: "WLID003",
    },
    {
      situF: "R",
      wlID: "WLID004",
    },
    {
      situF: "B",
      wlID: "WLID005",
    },
    // ... (如果需要更多資料，可依此格式增加)
  ];

  return (
    <Row>
      <Col span={3}>
        <Text level="h2" type="primary" className=" text-[#7c79d2]">
          {t("WOS.util.checklist") + t("WOS.util.index")}
          {/* 檢單索引 */}
        </Text>
      </Col>

      <Col span={21} className=" ">
        <div className="flex w-full gap-4  justify-between">
          <Select
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            placeholder={t("util.util.placeholder_input") + t("util.util.typeInspection")}
          />
          <Input
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            className="w-[50%]"
            placeholder={t("util.util.placeholder_select") + t("util.util.PN")}
          />
          <Input
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            className="w-[50%]"
            placeholder={t("util.util.placeholder_select") + t("WOS.wlwoc.INSP")}
          />
          <Input
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            className="w-[50%]"
            placeholder={t("util.util.placeholder_select") + "工(源)單"}
          />
          <Select
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            placeholder={t("util.util.placeholder_input") + t("WOS.util.pf")}
          />

          <Select
            placeholder={
              t("util.util.placeholder_input") + t("util.util.ws")

              //請輸入完整個工單/批號
            }
            allowClear
            enterButton
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            // onSearch={() =>
            //   call_getAllWpc.request({ ...pageInfo, wpcType: wpcType, queryString: queryString })
            // }
          />
          <Select
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            placeholder={t("util.util.placeholder_input") + t("WOS.wlwoc.We")}
          />
          <Select
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            allowClear
            placeholder={t("util.util.placeholder_select") + t("util.util.pwc")}
          />
          <Button
            className="bg-[#7c79d2] border-[#7c79d2] w-[100px] "
            size="large"
            type="primary"
            icon={<SearchOutlined />}
            // onClick={() => setIsWpcDatailModal(true)}
          />

          <Button
            className="bg-[#7c79d2] border-[#7c79d2] w-[100px] "
            size="large"
            type="primary"
            icon={<FullscreenOutlined />}
            // onClick={() => setIsWpcDatailModal(true)}
          />
        </div>
      </Col>

      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          // dataSource={tableData}
          dataSource={fakeTableData}
          size="small"
          // loading={call_getAllWpc.status === "load"}
          scroll={{ y: 185 }}
          rowClassName={(record, index) =>
            record.UUID === thisWpc?.UUID
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          onRow={(record) => {
            return {
              onClick: () => (record.UUID === thisWpc?.UUID ? setThisWpc({}) : setThisWpc(record)),
            };
          }}
          pagination={{
            total: pageInfo.totalItems,
            current: pageInfo.page,
            pageSize: pageInfo.pageSize,
            onChange: (current, size) =>
              setPageInfo((prev) => {
                // 如果有更動pageSize 回第一頁
                let checkPage = prev.pageSize !== size ? 1 : current;
                return { ...prev, page: checkPage, pageSize: size };
              }),
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
              </Text>
            ),
          }}
        />
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Button size="large" onClick={() => setIsModal(false)}>
          {t("util.util.close")}
          {/* 關閉 */}
        </Button>
      </Col>
    </Row>
  );
}
