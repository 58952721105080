import styled from "styled-components";
import { Modal } from "antd";
import { fontSize } from "@/styles/BasicSetting";

const CustomModalStyle = styled(Modal)`
  .ant-modal-title {
    font-size: ${fontSize.h3};
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
  }
`;

const CustomModal = (props) => {
  return (
    <CustomModalStyle
      centered
      // maskClosable={false} //点击蒙层是否允许关闭
      destroyOnClose //关闭时销毁 Modal 里的子元素
      {...props}
    />
  );
};

export default CustomModal;
