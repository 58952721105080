import { Row, Col, Dropdown } from "antd";
import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { IconTriangle, IconStation } from "@/components/Icon/index";
import MyCard from "@/components/Card";
import checkUndefined from "@/util/checkUndefined";
import Cookies from "js-cookie";
import WsCard from "@/components/WsCard/index";
import { useTranslation } from "react-i18next";

export default function WoInfoArea({ thisWpc }) {
  const theme = useTheme();
  const { t } = useTranslation();
  // 控制閃爍開關
  const [isCtrlColor, setIsCtrlColor] = useState(false);
  const [s_wsAndwlOptions, set_s_wsAndwlOptions] = useState({
    ws: [],
    wl: [],
  });

  const changeWs = (data) => {
    const { key } = data;
    const getWsIDdata = s_wsAndwlOptions.ws.find((x) => x.key === key);
    Cookies.set("wsID", key, { expires: 365 * 99 });
    Cookies.set("wsNM", getWsIDdata?.data.wsID.wsNM, { expires: 365 * 99 });
    Cookies.set("wlID", getWsIDdata?.data?.wlIDs ? getWsIDdata.data.wlIDs[0].wlID : "", {
      expires: 365 * 99,
    });
    Cookies.set("wlNM", getWsIDdata?.data?.wlIDs ? getWsIDdata.data.wlIDs[0].wlNM : "", {
      expires: 365 * 99,
    });
    Cookies.set("wosAuth", getWsIDdata.data.wsID.auth, { expires: 365 * 99 });
    window.location.reload();
  };

  const changeWl = (data) => {
    const { key } = data;
    const getWlIDdata = s_wsAndwlOptions.wl.find((x) => x.key === key);
    Cookies.set("wsNM", getWlIDdata.data.wsNM, { expires: 365 * 99 });
    Cookies.set("wsID", getWlIDdata.data.wsID, { expires: 365 * 99 });
    Cookies.set("wlID", getWlIDdata.data.wlID, { expires: 365 * 99 });
    Cookies.set("wlNM", getWlIDdata.data.wlNM, { expires: 365 * 99 });
    window.location.reload();
  };

  useEffect(() => {
    const allWsWl = JSON.parse(localStorage.getItem("allWsWl")) || [];
    const thisWsObj = allWsWl?.find((item) => item.wsID.wsID === Cookies.get("wsID"));
    let ws = allWsWl.map((x) => ({
      label: x.wsID.wsID + "_" + x.wsID.wsNM,
      key: x.wsID.wsID,
      data: x,
    }));

    let wl = thisWsObj?.wlIDs
      ? thisWsObj.wlIDs.map((x) => ({ label: x.wlID + "_" + x.wlNM, key: x.wlID, data: x }))
      : [];

    set_s_wsAndwlOptions({
      ws: ws,
      wl:
        wl.length === 0
          ? [
              {
                label: t("WOS.util.notSwitchable") + t("util.util.wl"),
                key: "noWl",
                disabled: true,
              },
            ]
          : wl,
    });
  }, []);

  useEffect(() => {
    // lifeF =2 or 4 啟動閃爍開關
    let timer;
    if (thisWpc.lifeF === "2" || thisWpc.lifeF === "4") {
      timer = setInterval(() => {
        setIsCtrlColor((prev) => !prev);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [thisWpc]);

  return (
    <Row gutter={[12, 12]} className="m-2">
      <Col span={8}>
        <MyCard
          color={isCtrlColor ? theme.primary : theme.success}
          title={t("util.util.N") + "/" + t("util.util.opcode")}
          // "單號/單別"
          content={checkUndefined(thisWpc?.woN)}
          subContent={checkUndefined(thisWpc?.wotypeStr)}
          borderAlign="top"
          align="left"
        />
      </Col>
      <Col span={8}>
        {/* 此為工單的工線 並非當前工線 */}
        <MyCard
          title={`${t("WOS.wlwoc.We")} / ${t("WOS.util.pwcID")}`}
          // "工線/工作中心"
          content={`${checkUndefined(thisWpc?.wsID)}_${checkUndefined(thisWpc?.wsNM)}`}
          subContent={checkUndefined(thisWpc?.pwcID)}
          borderAlign="top"
          align="left"
          extra={
            <Dropdown menu={{ items: s_wsAndwlOptions.wl, onClick: changeWl }} trigger={["click"]}>
              <IconTriangle style={{ cursor: "pointer" }} />
            </Dropdown>
          }
        />
      </Col>
      <Col span={8}>
        <WsCard
          Icon={<IconStation className="text-[#bd2eff] text-[30px]" />}
          wsID={checkUndefined(Cookies.get("wsID"))?.slice(0, 6) ?? ""}
          wsNM={checkUndefined(Cookies.get("wsNM")) ?? ""}
          extra={
            <Dropdown menu={{ items: s_wsAndwlOptions.ws, onClick: changeWs }} trigger={["click"]}>
              <IconTriangle className="text-white cursor-pointer text-[25px]" />
            </Dropdown>
          }
        />
        {/* <MyCard
            title="站別/站名"
            content={checkUndefined(Cookies.get("wsID"))}
            subContent={checkUndefined(Cookies.get("wsNM"))}
            borderAlign="top"
            align="left"
            extra={
              <Dropdown
                menu={{ items: s_wsAndwlOptions.ws, onClick: changeWs }}
                trigger={["click"]}
              >
                <IconTriangle style={{ cursor: "pointer" }} />
              </Dropdown>
            }
          /> */}
      </Col>
      <Col span={12}>
        <MyCard
          title={t("util.util.PN") + "/" + t("util.util.pdtNM")}
          // "料號/品名"
          content={checkUndefined(thisWpc?.PN)}
          subContent={checkUndefined(thisWpc?.pdtNM)}
          borderAlign="top"
          align="left"
        />
      </Col>
      <Col span={12}>
        <MyCard
          color={isCtrlColor ? theme.success : theme.primary}
          title={t("WOS.util.production") + "/" + t("WOS.util.goal") + " " + t("WOS.util.qty")}
          // "生產/目標 數量"
          content={
            <span>
              <span style={{ color: "blue", fontSize: "50px" }}>
                {checkUndefined(thisWpc?.inC)}&nbsp;
              </span>
              <span style={{ fontSize: "30px" }}>
                / {checkUndefined(thisWpc?.goalqty)}&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          }
          borderAlign="top"
          align="right"
        />
      </Col>
    </Row>
  );
}
