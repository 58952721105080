import { useState, useEffect } from "react";
import { Form, Row, Col, Divider, Space, Button, message, Input, InputNumber, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import Text from "@/components/Text/index";
import useAPI from "@/hooks/useAPI";
import { mergeBatch, getSplitBatches } from "@/service/api/wosAPI";
import { useTranslation } from "react-i18next";
export default function Merge({ set_s_showModal, thisWpc }) {
  const [form] = Form.useForm();
  const f_batches = Form.useWatch("batches", form);
  const { t } = useTranslation();
  const call_mergeBatch = useAPI(mergeBatch);
  const call_getSplitBatches = useAPI(getSplitBatches);

  const onSubmit = async (values) => {
    console.log(values);
    call_mergeBatch.request(values);
  };

  const formListAuth = {
    validator: async (_, formTbData) => {
      // 驗證規則
      if (!formTbData || formTbData.length === 0) {
        return Promise.reject(new Error(t("WOS.error.addAtLeastOne"))); //"請至少新增一筆資料"
      }
      return Promise.resolve();
    },
  };

  useEffect(() => {
    call_getSplitBatches.request();
  }, []);

  useEffect(() => {
    if (call_mergeBatch.status === "suc") {
      message.success(call_mergeBatch.msg);
      set_s_showModal(false);
    } else if (call_mergeBatch.status === "err") {
      message.error(call_mergeBatch.msg);
    }
  }, [call_mergeBatch.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ bulkqty: 0, batches: [] }}
      onValuesChange={(_, formData) => {
        const bulkqty =
          formData.batches?.reduce((data, curr) => {
            curr.bulkqty && (data = curr.bulkqty + data);
            return data;
          }, 0) || 0;
        const bdmbatchbom = formData?.batches[0]?.bdmbatchbom || 0;
        form.setFields([
          {
            name: "bulkqty",
            value: bulkqty,
          },
          {
            name: "bdmbatchbom",
            value: bdmbatchbom,
          },
        ]);
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item label={t("WOS.wlwoc.mergeBatch")} name="batchID">
            {/*"合併批號"*/}
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("WOS.wlwoc.bdmbatchbom")} name="bdmbatchbom">
            {/*"滿包數量" */}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t("util.util.batchno") + t("WOS.util.qty")} // 批號數量
            name="bulkqty"
            rules={[
              {
                validator: async (_, value) => {
                  const formData = form.getFieldsValue();
                  if (value === 0) {
                    return Promise.reject(new Error(t("WOS.error.batchnoQtyLessZero"))); //批號數量不得為0
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <InputNumber disabled className="w-100" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider className="m-0 p-0" />
        </Col>
        <Col span={24}>
          <Form.List name="batches" rules={[formListAuth]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {!!fields.length && (
                  <Row align="middle">
                    <Col span={1}>
                      <Text level="h5">
                        {t("util.util.SN")}
                        {/* 序 */}
                      </Text>
                    </Col>
                    <Col span={11}>
                      <Text level="h5">
                        {/* 批號 */}
                        {t("util.util.batchno")}
                      </Text>
                    </Col>
                    <Col span={3}>
                      <Text level="h5">
                        {t("WOS.util.inC")}
                        {/* 投產數量 */}
                      </Text>
                    </Col>
                    <Col span={4}>
                      <Text level="h5">
                        {/* 剩餘可用數量 */}
                        {t("WOS.util.remaining") + t("WOS.util.available_qty")}
                      </Text>
                    </Col>
                    <Col span={4}>
                      <Text level="h5">
                        {t("util.util.batchno") + t("WOS.util.qty")}
                        {/* 批號數量 */}
                      </Text>
                    </Col>
                  </Row>
                )}
                {fields.map((field, index) => {
                  return (
                    <Row key={field.name} gutter={[12, 0]}>
                      <Form.Item noStyle name={[field.name, "woN"]} />
                      <Form.Item noStyle name={[field.name, "UUID"]} />
                      <Form.Item noStyle name={[field.name, "bdmbatchbom"]} />
                      <Form.Item noStyle name={[field.name, "PN"]} />
                      <Col span={1}>
                        <Text level="h5" style={{ marginLeft: 4 }}>
                          {index + 1}
                        </Text>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          name={[field.name, "batchID"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                const filterRebatchID = f_batches.filter(
                                  (x) => x.batchID === value
                                );
                                if (!value || value === "") {
                                  return Promise.reject(new Error(t("WOS.error.chooseBatchno"))); //請選擇批號
                                } else if (filterRebatchID.length > 1) {
                                  return Promise.reject(new Error(t("WOS.error.duplicateBatchno"))); //批號重複
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <Select
                            className="w-100"
                            showSearch
                            options={call_getSplitBatches.data?.map((x) => ({
                              // label:
                              //   "【批號】" +
                              //   x.batchID +
                              //   "，【工單號】" +
                              //   x.woN +
                              //   "，【可用數量】" +
                              //   x.qty,
                              label:
                                `【${t("util.util.batchno")}】` +
                                x.batchID +
                                `，【${t("util.util.woN")}】` +
                                x.woN +
                                `，【${t("WOS.util.available_qty")}】` +
                                x.qty, // qty 可用數量
                              value: x.batchID,
                              data: x,
                            }))}
                            onChange={(_, data) => {
                              let getFormData = JSON.parse(JSON.stringify(form.getFieldsValue()));
                              getFormData.batches[field.name] = {
                                ...data.data,
                                bulkqty: 0,
                              };
                              console.log(getFormData);
                              form.setFieldsValue(getFormData);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item name={[field.name, "inC"]}>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item name={[field.name, "qty"]}>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          name={[field.name, "bulkqty"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                const rowData = f_batches[field.name];
                                if (!value || value === 0) {
                                  return Promise.reject(new Error(t("WOS.error.countLessZero"))); //數量不得為0
                                } else if (value > rowData.qty) {
                                  return Promise.reject(
                                    new Error(t("WOS.error.exceedAvailable_qty"))
                                  ); //超出剩餘可用數量
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                        >
                          <InputNumber min={1} className="w-100" />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined
                          style={{ fontSize: "1.3rem", marginTop: 5 }}
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                  );
                })}
                <div className="flex justify-center items-center">
                  <Form.ErrorList errors={[errors]} />
                </div>
                <Form.Item>
                  <Button size="large" type="primary" onClick={() => add({})}>
                    {t("util.util.add")}
                    {/* 新增 */}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24} className="ant-modal-footer">
          <Button size="large" onClick={() => set_s_showModal(false)}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button type="primary" size="large" htmlType="submit">
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
