import { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  message,
  Button,
  Input,
  Space,
  Select,
  Modal,
  InputNumber,
  Divider,
  Form,
  Spin,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CustomTable from "@/components/Custom/CustomTable.jsx";
import MyButton from "@/components/Button";
import {
  PlusOutlined,
  MinusOutlined,
  CheckSquareOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { CustomModal } from "@/components/Custom/AntdCustom";
import NgModal from "@/pages/WlWoc/CtrlArea/ButtonArea_BK/ModolComponent/MC/NgModal";
import GoStockModal from "@/pages/WlWoc/CtrlArea/ButtonArea_BK/ModolComponent/MC/GoStockModal";
import useAPI from "@/hooks/useAPI";
import { getAllWpc } from "@/service/api/publicAPI";
import { close } from "@/service/api/wosAPI";
import { confirm } from "@/service/api/qcAPI";
// import { IconExpand } from "@/components/Icon/Action";
import {
  getAllOkItem,
  addOk,
  getAllOk,
  getAllNgItem,
  addNg,
  getAllNg,
} from "@/service/api/mcAPI.js";
import Text from "@/components/Text";
import { IconScanBarcode } from "@/components/Icon";

import { getAllStaffs } from "@/service/api/wosAPI";

import moment from "moment";

import numberWithCommas from "@/util/numberWithCommas";

const CustomSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-lg {
    border: none !important;
  }
  .ant-input-suffix {
    position: absolute;
    right: 40px;
    top: 17%;
    color: red;
    /* width: 100px; */
    /* height: 100px; */
    font-size: 30px;
    z-index: 9999;
  }
  .ant-input-lg {
    font-size: 31px !important;
    border: none !important;
    padding-left: 4px !important;
  }
  .ant-input {
    padding-left: 20px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
    display: none;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .ant-input-wrapper:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper::before {
    border: none !important;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-input {
    background-color: #eb8282 !important;
    border-radius: 9999px !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;
const CustomSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 20px;
    /* padding-top: 12px !important; */
  }
  /* .ant-select-selector {
    height: 60px !important;
  } */
  .ant-select-selection-search-input {
    /* padding-top: 25px !important; */
    font-size: 20px;
  }
`;
const initBarcodeObj = {};

const Box = styled.div`
  background-color: ${({ theme }) => theme.componentBg};
  height: 208px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
  z-index: 10;
`;

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    margin: auto;
    height: 120px;
    width: 2px;
    background-color: #d9d9d9;
  }
`;
const MC = ({ setIsModal }) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const ref_customSearch = useRef(null);
  const [form] = Form.useForm();
  const currentDate = moment();

  const call_getAllOkItem = useAPI(getAllOkItem);
  const call_addOk = useAPI(addOk);
  const call_getAllOk = useAPI(getAllOk);

  const call_getAllNgItem = useAPI(getAllNgItem);
  const call_addNg = useAPI(addNg);
  const call_getAllNg = useAPI(getAllNg);

  const call_getAllWpc = useAPI(getAllWpc);
  const call_getAllStaffs = useAPI(getAllStaffs);

  const [s_okItemOptions, set_s_okItemOptions] = useState([]);
  // 已登錄良品
  const [s_okRecordList, set_s_okRecordList] = useState([]);
  // 要送出的資料
  const [s_okCheckProducts, set_s_okCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });
  const [s_date, set_s_date] = useState(currentDate);
  const [s_ngItemOptions, set_s_ngItemOptions] = useState([]);
  // 已登錄良品
  const [s_ngRecordList, set_s_ngRecordList] = useState([]);
  // 要送出的資料
  const [s_ngCheckProducts, set_s_ngCheckProducts] = useState({
    count: 0, //
    product: "",
    shift: "",
  });

  const [s_enterText, set_s_enterText] = useState("");

  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );
  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  // show
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState(initBarcodeObj);

  const [s_thisWpc, set_s_thisWpc] = useState({});
  const [s_spin, set_s_spin] = useState(false);
  const [s_cardID, set_s_cardID] = useState([]);
  const [s_shift, set_s_shift] = useState(undefined);
  const [s_isNgModal, set_s_isNgModal] = useState(false);
  const [s_isGOSTOCKModal, set_s_isGoSTOCKModal] = useState(false);
  useEffect(() => {
    ref_customSearch.current.focus();
    call_getAllStaffs.request();
  }, []);

  const okProductsConfirm = (e) => {
    if (s_okCheckProducts.count <= 0) {
      // message.error("數量不得小於0");
      message.error(t("WOS.error.countLessZero"));
      return;
    } else if (!s_shift) {
      message.error(t("WOS.error.shiftEmpty"));
      return;
    } else {
      Modal.confirm({
        title: <Text level="h4">{t("WOS.mc.okitemNM") + t("util.util.register")}？</Text>,
        content: <Text level="h4">{t("WOS.util.checkrRgister")}</Text>,
        okText: t("util.util.ok"), // 確認
        okType: "primary",
        cancelText: t("util.util.cancel"), // 取消
        centered: true,
        width: 600,
        onOk() {
          const count = e === "add" ? s_okCheckProducts.count : s_okCheckProducts.count * -1;
          call_addOk.request({
            UUID: s_thisWpc.UUID,
            woN: s_thisWpc.woN,
            lifeF: s_thisWpc.lifeF,
            itemID: s_okCheckProducts.product.split("_")[0],
            okC: count,
            shift: s_shift,
            pwcID: s_thisWpc.pwcID,
          });
        },
      });
    }
  };

  const ngProductsConfirm = (e) => {
    if (s_ngCheckProducts.count <= 0) {
      message.error(t("WOS.error.countLessZero"));
      return;
    } else if (!s_shift) {
      message.error(t("WOS.error.shiftEmpty"));
      return;
    } else {
      Modal.confirm({
        title: <Text level="h4">{t("WOS.mc.ngitemNM") + t("util.util.register")}？</Text>,
        content: <Text level="h4">{t("WOS.util.checkrRgister")}</Text>,
        okText: t("util.util.ok"), // 確認
        okType: "primary",
        cancelText: t("util.util.cancel"), // 取消
        centered: true,
        width: 600,
        onOk() {
          const count = e === "add" ? s_ngCheckProducts.count : s_ngCheckProducts.count * -1;
          call_addNg.request([{
            UUID: s_thisWpc.UUID,
            woN: s_thisWpc.woN,
            lifeF: s_thisWpc.lifeF,
            itemID: s_ngCheckProducts.product.split("_")[0],
            ngC: count,
            shift: s_shift,
            pwcID: s_thisWpc.pwcID,
          }]);
        },
      });
    }
  };

  const callCloseConfirm = async () => {
    set_s_spin(true);
    const postData = call_getAllWpc.data.tableData[0];

    if (postData.bal !== 0) {
      // 防帳差
      message.error(t("WOS.wlwoc.discrepancyaccounts")); //"不可有帳差"
      set_s_alertMsg(t("WOS.wlwoc.discrepancyaccounts")); //"不可有帳差"
      set_s_spin(false);
      return;
    }

    const call_close = await close(postData);
    if (!call_close.status) {
      message.error(call_close.msg);
      set_s_spin(false);
      return;
    }

    const call_confirm = await confirm(postData);
    if (!call_confirm.status) {
      message.error(call_confirm.msg);
      set_s_spin(false);
      return;
    }
    message.success(call_confirm.msg);
    form.resetFields();
    set_s_alertMsg(call_confirm.msg);
    set_s_spin(false);
  };

  useEffect(() => {
    call_getAllOkItem.request();
    call_getAllOk.request(s_thisWpc);
    call_getAllNg.request(s_thisWpc);
  }, []);

  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
        ${t("WOS.wlwoc.noWoNScan")}
        ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => {
        return {
          ...prev,
          ...parseBarcode,
        };
      });
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
      ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
    }
  }, [is_barcodeInput]);

  useEffect(() => {
    if (Object.keys(s_barcodeObj).length > 0) {
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
      // call_getAllWpc.request({
      //   wpcType: "car",
      //   queryString: s_barcodeObj.batchID,
      //   pwcID: s_barcodeObj.pwcID,
      // });
    }
  }, [s_barcodeObj]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      set_s_thisWpc({
        ...call_getAllWpc?.data?.tableData?.[0],
        rcvqty: call_getAllWpc?.data?.tableData?.[0].inC,
      });
      form.setFieldsValue(
        {
          ...call_getAllWpc?.data?.tableData?.[0],
          lifeFstr: t(`WOS.util.lifeF_${call_getAllWpc?.data?.tableData?.[0].lifeF}`),
          pwcIDstr:
            call_getAllWpc?.data?.tableData?.[0].pwcID +
            "_" +
            call_getAllWpc?.data?.tableData?.[0].pwcNM,
          rcvqty: call_getAllWpc?.data?.tableData?.[0].inC,
        } || {}
      );
      set_s_alertMsg(call_getAllWpc.msg);

      call_getAllNgItem.request({ opcode: call_getAllWpc?.data?.tableData?.[0].opcode });
    } else if (call_getAllWpc.status === "err") {
      set_s_alertMsg(call_getAllWpc.msg); // 條碼解析失敗
      form.resetFields();
      set_s_thisWpc({});
    }
  }, [call_getAllWpc.status]);

  useEffect(() => {
    if (Object.keys(s_thisWpc).length > 0) {
      console.log("in");
      call_getAllOk.request(s_thisWpc);
      call_getAllNg.request(s_thisWpc);
    }
  }, [s_thisWpc]);

  useEffect(() => {
    if (call_getAllOkItem.status === "suc") {
      set_s_okItemOptions(
        call_getAllOkItem.data.map((x) => ({
          label: `${x.itemID}_${x.itemNM}`,
          value: `${x.itemID}_${x.itemNM}`,
        }))
      );

      set_s_okCheckProducts((prev) => ({ ...prev, product: "00_ALL(others)" }));
    }
  }, [call_getAllOkItem.status]);

  useEffect(() => {
    if (call_addOk.status === "suc") {
      message.success(call_addOk.msg);
      call_getAllOk.request(s_thisWpc);
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    } else if (call_addOk.status === "err") {
      message.error(call_addOk.msg);
    }
  }, [call_addOk.status]);

  useEffect(() => {
    if (call_getAllOk.status === "suc") {
      set_s_okRecordList(call_getAllOk.data?.map((x) => ({ ...x, key: x.SN })));
    }
  }, [call_getAllOk.status]);

  useEffect(() => {
    if (call_getAllNgItem.status === "suc") {
      set_s_ngItemOptions(
        call_getAllNgItem.data.map((x) => ({
          label: `${x.itemID}_${x.itemNM}`,
          value: `${x.itemID}_${x.itemNM}`,
        }))
      );
      set_s_ngCheckProducts((prev) => ({ ...prev, product: "00_ALL(others)" }));
    }
  }, [call_getAllNgItem.status]);

  useEffect(() => {
    if (call_addNg.status === "suc") {
      message.success(call_addNg.msg);
      call_getAllNg.request(s_thisWpc);
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    } else if (call_addNg.status === "err") {
      message.error(call_addNg.msg);
    }
  }, [call_addNg.status]);

  useEffect(() => {
    if (call_getAllNg.status === "suc") {
      set_s_ngRecordList(call_getAllNg.data?.map((x) => ({ ...x, key: x.cumT })));
    }
  }, [call_getAllNg.status]);

  const okProductCol = [
    {
      title: t("WOS.util.shift"), // 班別
      dataIndex: "shift",
      align: "center",
    },
    {
      title: t("WOS.util.okItem"), // 良品項
      dataIndex: "itemNM",
      width: "150px",
      align: "center",
    },
    {
      title: t("OMS.out-stock.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      render: (num) => numberWithCommas(num),
    },
    {
      title: t("util.util.lifeF"), // 登錄時間
      dataIndex: "lifeF",
      align: "center",
      width: "80px",
      // onCell: (value) => {
      //   if (value.lifeF === "1") {
      //     return { className: `bg-[#F59139] ` };
      //   }
      // },
      render: (value) => t(`WOS.util.mclifeF_${value}`),
    },
    {
      title: t("util.util.lifeFT"), // 登錄時間
      dataIndex: "lifeFT",
      align: "center",
      width: "150px",
      render: (item) => (
        <div className="whitespace-pre-wrap">{moment(item).format("MM-DD HH:mm")}</div>
      ),
    },
  ];

  const ngProductCol = [
    {
      title: t("WOS.util.shift"), // 班別
      dataIndex: "shift",
      align: "center",
      width: "100px",
    },
    {
      title: t("ADM.string-manage.tabNgItem"), // 不良品項
      dataIndex: "itemNM",
      align: "center",
      width: "150px",
    },
    {
      title: t("OMS.out-stock.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      render: (num) => numberWithCommas(num),
    },
    {
      title: t("util.util.lifeF"), // 登錄時間
      dataIndex: "lifeF",
      align: "center",
      width: "80px",
      // onCell: (value) => {
      //   if (value.lifeF === "1") {
      //     return { className: `bg-[#F59139] ` };
      //   }
      // },
      render: (value) => t(`WOS.util.mclifeF_${value}`),
    },
    {
      title: t("util.util.lifeFT"), // 登錄時間
      dataIndex: "lifeFT",
      align: "center",
      width: "150px",
      render: (item) => (
        <div className="whitespace-pre-wrap">{moment(item).format("MM-DD HH:mm")}</div>
      ),
    },
  ];
  const firstOkItem = s_okItemOptions[0]?.value === undefined ? "" : s_okItemOptions[0]?.value;

  return (
    <Spin
      spinning={
        call_getAllWpc.status === "load" ||
        call_addOk.status === "load" ||
        call_addNg.status === "load" ||
        s_spin === true
      }
    >
      <Row gutter={[20, 10]}>
        <Col span={11} className="">
          <Row gutter={[16, 15]}>
            <Col span={24}>
              <CustomSearch
                ref={ref_customSearch}
                autoFocus
                suffix={<IconScanBarcode className="text-[36px] text-[#B22222] " />}
                enterButton={t("WOS.util.focusText")} //請掃入條碼
                size="large"
                placeholder={s_focusText}
                value={s_enterText}
                onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
                onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
                onChange={(e) => {
                  set_s_enterText(e.target.value);
                }}
                onSearch={() => {
                  set_s_enterText("");
                  set_s_showBarcode(s_enterText);
                  set_is_barcodeInput(true);
                }}
              />
            </Col>
            <Col span={24} className="">
              <Row className=" w-full  mb-5">
                <div className="bg-[#cedee9] w-full ">
                  <Text
                    level="h3"
                    type="error"
                    style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
                  >
                    <span className="text-[#6C6C6C] font-bold">{t("util.util.systemReport")}:</span>
                    {s_thisWpc.lifeF ? `${t(`WOS.util.lifeF_${s_thisWpc.lifeF}`)},` : ""}
                    {s_alertMsg}
                  </Text>
                </div>
              </Row>
              <Row className="bg-[#F0F9FF] h-[400px]">
                <Col span={24} className="flex  w-full ">
                  <Row className="w-full flex ">
                    <Col span={24}>
                      <div className="flex justify-start  w-full  ">
                        <div className="flex      w-[35%]">
                          <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                            "util.util.wo"
                          )}`}</div>
                          <div className="whitespace-normal   text-[30px]"> :</div>
                        </div>
                        <span className="text-black  self-center text-[25px] font-bold  ">
                          {s_thisWpc.woN ? `${s_thisWpc.woN} ${s_thisWpc.opcode}` : ""}
                        </span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="flex justify-start  w-full  ">
                        <div className="flex      w-[35%]">
                          <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                            "util.util.PN"
                          )}`}</div>
                          <div className="whitespace-normal  text-[30px]"> :</div>
                        </div>
                        <span className="text-black self-center text-[25px] font-bold  ">
                          {s_thisWpc.PN ?? ""}
                        </span>
                      </div>
                    </Col>
                    <Col span={24} className=" ">
                      <div className="flex justify-start  w-full  ">
                        <div className="flex      w-[35%]">
                          <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                            "util.util.custPN"
                          )} `}</div>
                          <div className="whitespace-normal  text-[30px]"> :</div>
                        </div>
                        <span className="text-[#39883d] self-center text-[25px] font-bold  ">
                          {s_thisWpc.dealPN ?? ""}
                        </span>
                      </div>
                    </Col>
                    <Col span={24} className=" ">
                      <div className="flex justify-start  w-full  ">
                        <div className="flex      w-[35%]">
                          <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                            "util.util.pdtNM"
                          )}`}</div>
                          <div className="whitespace-normal  text-[30px]"> :</div>
                        </div>
                        <span className="text-[#3eb390]   w-[50%]  self-center text-[25px] font-bold  ">
                          {s_thisWpc.pdtNM ?? ""}
                        </span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="flex justify-start  w-full  ">
                        <div className="flex      w-[35%]">
                          <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                            "util.util.planqty"
                          )}`}</div>
                          <div className="whitespace-normal  text-[30px]"> :</div>
                        </div>
                        <span className="text-[#3e4ab3] self-center text-[25px] font-bold  ">
                          {s_thisWpc.planqty ? `${s_thisWpc.planqty}` : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {/* <Col span={24}>
                      <Text level="h1" className="whitespace-normal text-[40px]">
                        {`${t("WOS.wlwoc.inC")}  :`}&nbsp;&nbsp;
                        <span className="text-green-700">{s_thisWpc.inC ?? ""}</span>
                 
                      </Text>
                    </Col> */}
              </Row>
            </Col>

            <Col span={24}>
              <Box className="bg-[#FFF8F2]">
                <Row justify="center" align="middle" gutter={[128, 0]} className="h-full">
                  <Col span={5} className="flex flex-col justify-center items-center">
                    <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                      {t("WOS.wlwoc.inC")}
                    </Text>
                    <span className="text-[32px] text-[#4A83D1]">
                      {numberWithCommas(s_thisWpc?.inC) ?? 0}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col  text-center justify-center items-center ">
                    <Text level="h2" className="w-[150px] whitespace-pre-line ">
                      {t("WOS.wlwoc.ok_count")}
                    </Text>
                    <span className="text-[32px] font-bold text-[#77D160]">
                      {numberWithCommas(s_thisWpc?.okC ?? 0)}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col text-center justify-center items-center ">
                    <Text level="h2" className="w-[150px] whitespace-pre-line ">
                      {t("WOS.wlwoc.ng_count")}
                    </Text>
                    <span className="text-[32px] text-[#F37E7E]">
                      {numberWithCommas(s_thisWpc?.ngC ?? 0)}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col text-center justify-center items-center ">
                    <Text level="h2" className="w-[170px] whitespace-pre-line ">
                      {t("WOS.util.bal")}
                    </Text>
                    <span className="text-[32px] text-gray-300">
                      {numberWithCommas(s_thisWpc?.bal ?? 0)}
                    </span>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Col>

        <Col span={13} className="">
          <Row gutter={[12, 25]}>
            <Col span={24} className=" ">
              <Row gutter={[12, 0]}>
                <Col span={6} className="">
                  <div className="w-full flex">
                    <Text level="h3">
                      {t("ADM.util.time")}
                      {":"}
                    </Text>
                    <DatePicker
                      size="small"
                      className="w-full"
                      value={s_date}
                      onChange={(date, dateString) => {
                        set_s_date(date);
                      }}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  <div className="w-full flex">
                    <Text level="h3">
                      {/* 班別 */}
                      {t("WOS.util.shift")}
                      {":"}
                    </Text>
                    <CustomSelect
                      size="middle"
                      className="w-full"
                      value={s_shift}
                      onChange={(e) => {
                        set_s_shift(e);
                      }}
                    >
                      <Option value="SHIFTI">{"SHIFT I"}</Option>
                      <Option value="SHIFTII">{"SHIFT II"}</Option>
                      <Option value="SHIFTIII"></Option>
                    </CustomSelect>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="w-full flex">
                    <Text level="h3">
                      {t("WOS.wpc-printer.jobNumber")}
                      {":"}
                    </Text>

                    <CustomSelect
                      placeholder={t("WOS.util.scanJobNumber")}
                      size="middle"
                      showSearch
                      className="w-full"
                      optionFilterProp="label"
                      value={s_cardID}
                      mode="multiple"
                      onChange={(cardID, data) => {
                        set_s_cardID(cardID);
                      }}
                      options={
                        call_getAllStaffs.data?.map((x) => ({
                          label: x.cardID + "_" + x.peopleNM,
                          value: x.cardID,
                          data: x,
                        })) || []
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className="w-full">
              <Row gutter={[12, 45]} className=" ">
                <Col span={24}>
                  <Row className="bg-[#eeffef] px-4 pb-4  ">
                    <Col span={24}>
                      <Row gutter={[12, 0]} className=" ">
                        <Col span={24} className="flex w-full justify-between">
                          {/* <Text level={"h2"}>良品登錄</Text> */}
                          <div className="font-bold   text-[28px] text-[#4e89c9]">
                            {t("WOS.util.AddOk")}
                          </div>

                          <MyButton
                            onClick={() => {
                              set_s_isGoSTOCKModal(true);
                            }}
                            height={50}
                            width={200}
                            // loading={call_gostk.status === "load"}
                            disabled={!["7", "2"].includes(s_thisWpc?.lifeF)}
                            className="ml-3  text-white "
                          >
                            <Text level="h2" className="tracking-widest">
                              {t("APS.aps-manage.GOSTK")}
                            </Text>
                          </MyButton>
                        </Col>
                        <Col span={8}>
                          <Space direction="vertical" className="w-full ">
                            {/* 良品品項 */}
                            <div level="h4" className="text-[24px] " style={{ display: "block" }}>
                              {/* 良品項 */}
                              {t("WOS.util.okItem")}
                            </div>

                            <Select
                              size="large"
                              showSearch
                              style={{ width: "100%" }}
                              defaultValue={firstOkItem}
                              value={s_okCheckProducts.product}
                              options={s_okItemOptions}
                              onChange={(e) => {
                                set_s_okCheckProducts({
                                  ...s_okCheckProducts,
                                  product: e,
                                });
                              }}
                            />
                            <div
                              level="h4"
                              className="text-[24px] my-3 "
                              style={{ display: "block" }}
                            >
                              {/* 良品數量 */}

                              {t("OMS.out-stock.qty")}
                            </div>

                            <InputNumber
                              className="text-[20px]"
                              Number
                              size="large"
                              value={s_okCheckProducts.count}
                              min={1}
                              onChange={(e) => {
                                set_s_okCheckProducts({ ...s_okCheckProducts, count: e });
                              }}
                              style={{ width: "100%" }}
                            />
                            <div className=" flex justify-between">
                              <MyButton
                                height={60}
                                width={125}
                                onClick={() => okProductsConfirm("add")}
                                loading={call_addOk.status === "load"}
                                disabled={!["B", "2", "4", "3", "7", "G"].includes(s_thisWpc.lifeF)}
                              >
                                <Text level="h3">
                                  <PlusOutlined />
                                </Text>
                              </MyButton>
                              <MyButton
                                height={60}
                                width={125}
                                color="#ff4d4f"
                                onClick={() => okProductsConfirm("reduce")}
                                loading={call_addOk.status === "load"}
                                disabled={!["B", "2", "4", "3", "7", "G"].includes(s_thisWpc.lifeF)}
                              >
                                <Text level="h3">
                                  <MinusOutlined />
                                </Text>
                              </MyButton>
                            </div>
                          </Space>
                        </Col>
                        <Col span={16}>
                          <div
                            className="text-[24px] mb-2 "
                            level="h4"
                            style={{ display: "block" }}
                          >
                            {t("WOS.util.regeistOkItem")}

                            {/* 已錄入之良品品項 */}
                          </div>

                          <CustomTable
                            columns={okProductCol}
                            dataSource={s_okRecordList}
                            scroll={{ y: 175 }}
                            size="small"
                            rowClassName={(record, index) =>
                              index % 2 === 1 //隔行變色
                                ? "once"
                                : "twoe"
                            }
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className=" ">
                  <Row gutter={[12, 0]} className="bg-red-50 h-[100%] px-4 pb-4">
                    <Col span={24} className="flex justify-between">
                      <div level="h2" className=" font-bold   text-[28px] text-[#4e89c9]">
                        {/* 不良品登錄 */}

                        {t("util.util.addNg")}
                      </div>
                      <div className="mt-3">
                        <Button
                          type="default"
                          className="h-full px-2 py-0 bg-[#EE5A5A]"
                          onClick={() => {
                            set_s_isNgModal(true);
                          }}
                        >
                          <FullscreenOutlined className="text-[24px] text-white" />
                        </Button>
                      </div>
                    </Col>

                    <Col span={8}>
                      <Space direction="vertical" className="w-full">
                        <div level="h4" className="text-[24px]  " style={{ display: "block" }}>
                          {t("ADM.string-manage.tabNgItem")}

                          {/* 不良項目 */}
                        </div>
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          value={s_ngCheckProducts.product}
                          options={s_ngItemOptions}
                          showSearch
                          onChange={(e) => {
                            set_s_ngCheckProducts({
                              ...s_ngCheckProducts,
                              product: e,
                            });
                          }}
                        />
                      </Space>
                      <div level="h4" className="text-[24px] my-3 " style={{ display: "block" }}>
                        {/* 不良品數量 */}

                        {t("OMS.out-stock.qty")}
                      </div>
                      <Space direction="vertical" className="w-full" size={50}>
                        <InputNumber
                          className="text-[20px]"
                          Number
                          size="large"
                          value={s_ngCheckProducts.count}
                          min={1}
                          onChange={(e) => {
                            set_s_ngCheckProducts({ ...s_ngCheckProducts, count: e });
                          }}
                          style={{ width: "100%" }}
                        />
                      </Space>
                      <div className="mt-3 flex justify-between">
                        <MyButton
                          height={60}
                          width={125}
                          onClick={() => ngProductsConfirm("add")}
                          loading={call_addNg.status === "load"}
                          disabled={!["B", "2", "4", "3", "7", "G"].includes(s_thisWpc.lifeF)}
                        >
                          <Text level="h3">
                            <PlusOutlined />
                          </Text>
                        </MyButton>
                        <MyButton
                          height={60}
                          width={125}
                          color="#ff4d4f"
                          onClick={() => ngProductsConfirm("reduce")}
                          loading={call_addNg.status === "load"}
                          disabled={!["B", "2", "4", "3", "7", "G"].includes(s_thisWpc.lifeF)}
                        >
                          <Text level="h3">
                            <MinusOutlined />
                          </Text>
                        </MyButton>
                      </div>
                    </Col>
                    <Col span={16} className=" pl-2">
                      <div className="text-[24px] mb-2" level="h4" style={{ display: "block" }}>
                        {/* 不量良品項 */}
                        {t("WOS.util.regeistNgItem")}
                        {/* 已錄入之不良品品項 */}
                      </div>
                      <CustomTable
                        columns={ngProductCol}
                        dataSource={s_ngRecordList}
                        scroll={{ y: 160 }}
                        size="small"
                        rowClassName={(record, index) =>
                          index % 2 === 1 //隔行變色
                            ? "once"
                            : "twoe"
                        }
                        pagination={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Divider />
        <Col span={24} className="text-right">
          <Space size={20}>
            <Button type="primary" onClick={() => setIsModal(false)}>
              <CheckSquareOutlined className="text-[20px]" />
            </Button>
          </Space>
        </Col>
      </Row>
      <CustomModal
        key="isNgModal"
        title={
          <div className="w-[95%] flex justify-between">
            <div>{t("WOS.util.AddNg")}</div>

            <div className="bg-[#E3E3E3] text-[#343434] rounded-[8px]  p-[10px] ">
              {`${t("APS.aps-manage.pfIDNM")} : `}
            </div>
          </div>
        }
        // title="途單索引詳情"
        visible={s_isNgModal}
        onCancel={() => set_s_isNgModal(false)}
        footer={null}
        width="95%"
        afterClose={() => {
          call_getAllNg.request(s_thisWpc);
        }}
      >
         <NgModal
          set_s_isNgModal={set_s_isNgModal}
          s_editData={s_thisWpc}
          s_ngItemOptions={s_ngItemOptions}
          shift={s_shift}
        />
      </CustomModal>
      <CustomModal
        key="s_isGOSTOCKModal"
        title={
          <div className="w-[95%] flex text-[28px] justify-between">
            <div>{t("APS.aps-manage.GOSTK")}</div>
          </div>
        }
        // title="途單索引詳情"
        visible={s_isGOSTOCKModal}
        onCancel={() => set_s_isGoSTOCKModal(false)}
        footer={null}
        width="95%"
        destroyOnClose
        afterClose={() => {
          // set_c_lightData({});
          // call_getAllOk.request({ UUID: s_editData.UUID });
          // call_getMesWo.request({ ...c_searchData, ...s_editData });
        }}
      >
        <GoStockModal
          s_thisWpc={s_thisWpc}
          set_s_isGoSTOCKModal={set_s_isGoSTOCKModal}
          s_okRecordList={s_okRecordList}
          set_s_okRecordList={set_s_okRecordList}
          call_getAllOk={call_getAllOk}
          // s_editData={s_editData}
          // s_ngItemOptions={s_ngItemOptions}
          // shift={s_shift}
        />
      </CustomModal>
    </Spin>
  );
};

export default MC;
