import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import numberWithCommas from "@/util/numberWithCommas";
import CustomTable from "@/components/Custom/CustomTable.jsx";
import moment from "moment";
import { Button, Space, message } from "antd";
import { CheckSquareOutlined, CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
// import { IconNo } from "@/components/Icon/Action";
import { gostk } from "@/service/api/mcAPI/";

import useAPI from "@/hooks/useAPI";
import Text from "@/components/Text";
import MyButton from "@/components/Button";

const GoStockModal = ({
  s_thisWpc,
  set_s_isGoSTOCKModal,
  s_okRecordList,
  set_s_okRecordList,
  call_getAllOk,
}) => {
  const { t } = useTranslation();
  console.log(s_okRecordList);
  const call_gostk = useAPI(gostk);

  const [s_selectedOKQty, set_s_selectedOKQty] = useState([]);
  const [s_selectedGOSTOCKQty, set_s_selectedGOSTOCKQty] = useState([]);

  const onSelectOKQtyChange = (_, newSelectedRows) => {
    set_s_selectedOKQty(newSelectedRows);
  };
  const onSelectGOSTOCKQtyChange = (_, newSelectedRows) => {
    set_s_selectedGOSTOCKQty(newSelectedRows);
  };

  const OKQty_Selection = {
    selectedRowKeys: s_selectedOKQty.map((x) => x.key),
    onChange: onSelectOKQtyChange,
  };

  const GOSTOCKQty_Selection = {
    selectedRowKeys: s_selectedGOSTOCKQty.map((x) => x.key),
    onChange: onSelectGOSTOCKQtyChange,
    getCheckboxProps: (record) => ({
      disabled: record.isgostk, // 如果 record.isgostk 为 true，则禁用勾选框
    }),
  };

  const gostockData = s_okRecordList.filter((record) => {
    return record.isgostk === true || record.lifeF === "1";
  });
  const okQtyData = s_okRecordList.filter((record) => {
    return record.isgostk === false && record.lifeF !== "1";
  });

  const okQtyDataAmount = s_okRecordList.reduce((accumulator, currentValue) => {
    if (currentValue.isgostk === false && currentValue.lifeF !== "1") {
      accumulator += currentValue.sqty;
    }
    return accumulator;
  }, 0);

  const GOSTOCK_Amount =
    s_okRecordList.length === 0
      ? 0
      : s_okRecordList.reduce((accumulator, currentValue) => {
          return currentValue.lifeF === "1"
            ? (accumulator += currentValue.sqty)
            : (accumulator += 0);
        }, 0);

  const okProductCol = [
    {
      title: t("util.util.batchno"), //"批號",
      dataIndex: "batchID",
      width: "150px",
      align: "center",
    },
    // {
    //   title: t("WOS.util.shift"), // 班別
    //   dataIndex: "shift",
    //   align: "center",
    // },
    // {
    //   title: t("WOS.util.okItem"), // 良品項
    //   dataIndex: "itemNM",
    //   width: "150px",
    //   align: "center",
    // },
    {
      title: t("OMS.out-stock.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      render: (num) => numberWithCommas(num),
    },
    {
      title: t("util.util.lifeF"), // 登錄時間
      dataIndex: "lifeF",
      align: "center",
      width: "150px",
      onCell: (value) => {
        if (value.lifeF === "1") {
          return { className: `bg-[#F59139] ` };
        }
      },
      render: (value) => t(`WOS.util.mclifeF_${value}`),
    },
    {
      title: t("util.util.lifeFT"), // 登錄時間
      dataIndex: "lifeFT",
      align: "center",
      width: "150px",
      render: (item) => (
        <div className="whitespace-pre-wrap">{moment(item).format("MM-DD HH:mm")}</div>
      ),
    },
  ];

  const onSubmit = () => {
    const payload = s_okRecordList.filter((record) => {
      return record.lifeF === "1";
    });
    console.log(payload);
    call_gostk.request(payload);
  };

  const changeDataToLife1 = () => {
    if (OKQty_Selection.selectedRowKeys.length === 0) {
      return message.error("no Select OK Qty");
    }

    const selectedRow_SN = [...OKQty_Selection.selectedRowKeys];

    const new_s_okRecordList = s_okRecordList.map((element) => {
      return selectedRow_SN.includes(element.SN) ? { ...element, lifeF: "1" } : { ...element };
    });

    set_s_okRecordList([...new_s_okRecordList]);
    set_s_selectedOKQty([]);
  };
  const resetDataTOLife0 = () => {
    if (GOSTOCKQty_Selection.selectedRowKeys.length === 0) {
      return message.error("no Select GOSTOCK Qty");
    }

    const selectedRow_SN = [...GOSTOCKQty_Selection.selectedRowKeys];

    const new_s_okRecordList = s_okRecordList.map((element) => {
      return selectedRow_SN.includes(element.SN) ? { ...element, lifeF: "0" } : { ...element };
    });
    set_s_okRecordList([...new_s_okRecordList]);
    set_s_selectedGOSTOCKQty([]);
  };

  useEffect(() => {
    if (call_gostk.status === "suc") {
      message.success(call_gostk.msg);
      call_getAllOk.request({ UUID: s_thisWpc.UUID });
      set_s_selectedOKQty([]);
    } else if (call_gostk.status === "err") {
      message.error(call_gostk.msg);
    }
  }, [call_gostk.status]);

  return (
    <div className="flex flex-col w-full  ">
      <div className="flex gap-3">
        <div className="flex px-5 py-5 w-[45%] bg-[#E1F3FF]">
          <div className="flex flex-col w-full  ">
            <div className="flex w-full justify-between">
              <Text level="h2" className={"font-bold self-start   text-[28px] text-[#66B4FE]"}>
                {t("WOS.util.okC")}
              </Text>
              <Text level="h2" className={"font-bold self-start   text-[28px] text-[#66B4FE]"}>
                {`${t("util.util.planIn")} : ${okQtyDataAmount}`}
              </Text>
            </div>
            <CustomTable
              columns={okProductCol}
              dataSource={okQtyData}
              rowSelection={OKQty_Selection}
              scroll={{ y: 200 }}
              size="small"
              className="w-full"
              pagination={false}
              rowClassName={(record, index) =>
                record.light
                  ? "clickRowStyle"
                  : index % 2 === 1 //隔行變色
                  ? "once"
                  : "twoe"
              }
            />
          </div>
        </div>

        <div className="flex flex-col  w-[10%] justify-center items-center gap-3">
          <div className="flex  items-center justify-center w-[10%]">
            <MyButton
              className={"bg-[#F59139]"}
              width={100}
              height={80}
              onClick={() => {
                changeDataToLife1();
              }}
              loading={call_gostk.status === "load"}
            >
              <Text level="h1">
                <CaretRightOutlined />
              </Text>
            </MyButton>
          </div>

          <div className="flex  items-center justify-center w-[10%]">
            <MyButton
              className={"bg-[#66B4FE]"}
              width={100}
              height={80}
              onClick={() => {
                resetDataTOLife0();
              }}
              loading={call_gostk.status === "load"}
            >
              <Text level="h1">
                <CaretLeftOutlined />
              </Text>
            </MyButton>
          </div>
        </div>

        <div className="flex px-5 py-5 flex-col w-[45%]  bg-[#FFF1E8] items-stretch ">
          <div className="flex w-full justify-between">
            <Text level="h2" className={"font-bold   text-[28px] text-[#F59139]"}>
              {t("WOS.util.gostockC")}
            </Text>
            <Text level="h2" className={"font-bold self-start   text-[28px] text-[#F59139]"}>
              {`${t("util.util.pickIn")} : ${GOSTOCK_Amount}`}
            </Text>
          </div>

          <CustomTable
            pagination={false}
            columns={okProductCol}
            dataSource={gostockData}
            rowSelection={GOSTOCKQty_Selection}
            scroll={{ y: 200 }}
            className="w-full"
            size="small"
            rowClassName={(record, index) =>
              record.light
                ? "clickRowStyle"
                : index % 2 === 1 //隔行變色
                ? "once"
                : "twoe"
            }
          />
        </div>
      </div>

      <div className="flex w-full justify-end mt-4  ">
        <Space size={10}>
          <Button
            type="default"
            className="h-full w-[75px] pt-2"
            onClick={() => set_s_isGoSTOCKModal(false)}
            loading={call_gostk.status === "load"}
          >
            {/* <IconNo className="text-[26px] " /> */}
          </Button>
          <Button
            type="primary"
            className="h-full w-[75px] pt-2"
            // disabled={true}
            loading={call_gostk.status === "load"}
            onClick={() => {
              onSubmit();
              // set_s_isGoSTOCKModal(false);
            }}
          >
            <CheckSquareOutlined className="text-[26px]" />
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default GoStockModal;
