import { useContext, useEffect } from 'react'

import styled from 'styled-components'

import { IconSun, IconMoon } from '../Icon'

import StoreContext from '../Context/StoreContext'

const ThemeButtonEle = styled.span`
  cursor: pointer;
`

const ThemeButton = () => {
  const configContext = useContext(StoreContext)

  const { storeData, setStoreData } = configContext

  const changeTheme = () => {
    storeData.theme === 'light' ? setStoreData({ ...storeData, theme: 'dark' }) : setStoreData({ ...storeData, theme: 'light' })
  }

  return <ThemeButtonEle onClick={changeTheme} style={{fontSize:"23px"}}>{storeData.theme === 'light' ? <IconMoon /> : <IconSun />}</ThemeButtonEle>
}
export default ThemeButton
