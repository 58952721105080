import { Button, Input, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CustomModal, CustomTable } from "@/components/Custom/AntdCustom";
import DisabledBox from "@/components/DisabledBox";
import { IconAddQrcode } from "@/components/Icon/index";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { chooseWpcCurrentStaff, getInOutBatches } from "@/service/api/wosAPI";
import { FullscreenOutlined, UndoOutlined } from "@ant-design/icons";

import Create from "./ActionModal/Create";
import Merge from "./ActionModal/Merge";
import Split from "./ActionModal/Split";
import View from "./ViewModal";
import PrintBarcode from "./ViewModal/PrintBarcode";

const BMSCustomTable = styled(CustomTable)`
  .ant-table-thead .ant-table-cell {
    font-size: 1rem !important;
    border-top: 1px #dcdcdc solid !important;
    border-bottom: 1px #dcdcdc solid !important;
    border-left: 1px #dcdcdc solid !important;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 2px;
  }
  .ant-table-thead > tr > th {
    background-color: #ececec;
    color: #6c6c6c;
  }
  /* 恢復線條 */

  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-width: 0 1px 1px 1px !important;
    border-style: none solid solid solid !important;
    border-color: transparent #dcdcdc #dcdcdc #dcdcdc !important;
  }

  .ant-table-thead th:last-child.ant-table-cell.ant-table-cell-scrollbar {
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }
`;

export default function BMS({ thisWpc, processManagementAuth, call_getAllWpc }) {
  const { t } = useTranslation();

  const [s_modalKey, set_s_modalKey] = useState("");

  const [s_showModal, set_s_showModal] = useState(false);
  const [s_viewShowModal, set_s_viewShowModal] = useState(false);
  const [s_isBarcodeShowModal, set_s_isBarcodeShowModal] = useState(false);
  const [s_inTableData, set_s_inTableData] = useState([]);
  const [s_outTableData, set_s_outTableData] = useState([]);

  const call_getInOutBatches = useAPI(getInOutBatches);
  const call_chooseWpcCurrentStaff = useAPI(chooseWpcCurrentStaff);

  const chooseTableColumns = [
    {
      title: t("util.util.SN"), //"批號",
      dataIndex: "SN",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.batchno"), //"批號",
      dataIndex: "cbatchID",
    },
    {
      title: t("WOS.util.qty"), //"數量",
      dataIndex: "bulkqty",
      align: "right",
      width: "70px",
    },
  ];

  useEffect(() => {
    call_getInOutBatches.request(thisWpc);
  }, []);

  useEffect(() => {
    if (Object.keys(thisWpc).length > 0) {
      call_chooseWpcCurrentStaff.request(thisWpc);
    }
  }, [thisWpc]);

  useEffect(() => {
    if (call_getInOutBatches.status === "suc") {
      message.success(call_getInOutBatches.msg);
      set_s_inTableData(
        (call_getInOutBatches.data?.bom || []).map((x, i) => ({ key: i, SN: i + 1, ...x }))
      );
      set_s_outTableData(
        (call_getInOutBatches.data?.batches || []).map((x, i) => ({ key: i, SN: i + 1, ...x }))
      );
    } else if (call_getInOutBatches.status === "err") {
      message.error(call_getInOutBatches.msg);
      set_s_inTableData([]);
      set_s_outTableData([]);
    }
  }, [call_getInOutBatches.status]);

  return (
    <div>
      <DisabledBox disabled={false}>
        <div className="flex w-full  items-center justify-between">
          <div className="bg-[#FFFFFF] w-[33%] px-4  rounded-lg shadow-lg z-10 h-[300px]">
            <Text level="h3" className="text-black">
              {/* 投產掃碼 */}
              {t("WOS.pis.in") + t("util.util.scan_barcode")}
            </Text>
            <div className=" h-[75%] flex flex-col justify-around ">
              <Space className="w-full" direction="vertical ">
                <Text level="h4">{t("WOS.wlwoc.operator")}：</Text>
                <div className="flex overflow-y-scroll  flex-col h-[100px] ">
                  {call_chooseWpcCurrentStaff?.data?.map((name, index) => {
                    console.log(name);
                    return <span key={index}>{name}</span>;
                  })}
                  {/* {names?.map((name,index)=>{
                  console.log(name)
                  return <span key={index}>{name}</span>
                })} */}
                </div>
              </Space>
              {/* <Space className="w-full" direction="vertical ">
                <Text level="h4">{t("WOS.wlwoc.We")}：</Text>
                <Input value={thisWpc.wlID} className="" disabled />
              </Space> */}
              <Space className="w-full" direction="vertical ">
                <Text level="h4">{t("util.util.mold")}：</Text>
                <Input className="w-full" disabled />
              </Space>
            </div>
          </div>
          <div className="bg-[#FFFFFF] w-[33%] px-4 rounded-lg shadow-lg z-10 h-[300px]">
            <div className="flex justify-between items-center mb-2 ">
              <Text level="h2" className="text-black">
                {t("WOS.wlwoc.feeding") + t("util.util.scan_barcode")}
              </Text>
              {/*產出批號*/}
              <Space size={20}>
                <Button
                  type="primary"
                  icon={<UndoOutlined className="text-[40px]" />}
                  className="enabled:bg-yellow-500 enabled:border-yellow-600 w-[50px] h-[50px]"
                  onClick={() => call_getInOutBatches.request(thisWpc)}
                />
                <Button
                  type="primary"
                  icon={<FullscreenOutlined className="text-[40px]" />}
                  className="enabled:bg-green-500 enabled:border-green-600 w-[50px] h-[50px]"
                  disabled={Object.keys(thisWpc).length === 0}
                  onClick={() => {
                    set_s_viewShowModal(true);
                  }}
                />
              </Space>
            </div>
            <BMSCustomTable
              bordered={true}
              className="ml-2  h-[25vh] "
              columns={chooseTableColumns}
              dataSource={s_outTableData}
              scroll={{ y: 200 }}
              pagination={false}
            />
          </div>
          <div className="bg-[#FFFFFF] w-[33%] px-4 rounded-lg shadow-lg z-10 h-[300px]">
            <div className="flex justify-between items-center mb-2 ">
              <Text level="h2" className="text-black">
                {t("WOS.wlwoc.output") + t("util.util.batchno")}
              </Text>
              {/*產出批號*/}
              <Space size={20}>
                <Button
                  type="primary"
                  icon={<UndoOutlined className="text-[40px]" />}
                  className="enabled:bg-yellow-500 enabled:border-yellow-600 w-[50px] h-[50px]"
                  onClick={() => call_getInOutBatches.request(thisWpc)}
                />
                <Button
                  type="primary"
                  icon={<FullscreenOutlined className="text-[40px]" />}
                  className="enabled:bg-green-500 enabled:border-green-600 w-[50px] h-[50px]"
                  disabled={Object.keys(thisWpc).length === 0}
                  onClick={() => {
                    set_s_viewShowModal(true);
                  }}
                />
                <Button
                  type="primary"
                  icon={<IconAddQrcode className="text-[40px]" />}
                  className=" w-[50px] h-[50px]"
                  onClick={() => {
                    set_s_isBarcodeShowModal(true);
                  }}
                />
              </Space>
            </div>
            <BMSCustomTable
              bordered
              className="ml-2"
              columns={chooseTableColumns}
              dataSource={[]}
              scroll={{ y: 200 }}
              pagination={false}
            />
          </div>
        </div>

        <CustomModal
          key="modal"
          title={
            (s_modalKey === "create"
              ? t("util.util.create")
              : s_modalKey === "split"
              ? t("WOS.wlwoc.split")
              : s_modalKey === "merge" && t("WOS.wlwoc.merge")) + t("util.util.batchno")
          }
          visible={s_showModal}
          onCancel={() => set_s_showModal(false)}
          footer={null}
          width="80%"
          afterClose={() => {
            call_getAllWpc();
          }}
        >
          {s_modalKey === "create" ? (
            <Create thisWpc={thisWpc} set_s_showModal={set_s_showModal} />
          ) : s_modalKey === "split" ? (
            <Split thisWpc={thisWpc} set_s_showModal={set_s_showModal} />
          ) : (
            s_modalKey === "merge" && <Merge thisWpc={thisWpc} set_s_showModal={set_s_showModal} />
          )}
        </CustomModal>

        <CustomModal
          // title={(s_modalKey === "in" ? "投料" : "產出") + "批號"}
          title={
            (s_modalKey === "in" ? t("WOS.wlwoc.feeding") : t("WOS.wlwoc.output")) +
            t("util.util.batchno")
          }
          visible={s_viewShowModal}
          onCancel={() => set_s_viewShowModal(false)}
          footer={null}
          width="95%"
          afterClose={() => {
            call_getAllWpc();
          }}
        >
          {s_viewShowModal && (
            <View
              s_tableData={s_inTableData}
              set_s_showModal={set_s_viewShowModal}
              thisWpc={thisWpc}
            />
          )}
        </CustomModal>

        <CustomModal
          key="s_isBarcodeShowModal"
          // title="條碼批號"
          title={t("WOS.util.barCode") + t("util.util.batchno")}
          visible={s_isBarcodeShowModal}
          onCancel={() => set_s_isBarcodeShowModal(false)}
          footer={null}
          width="95%"
          afterClose={() => call_getAllWpc()}
        >
          <PrintBarcode chooseThisWpc={thisWpc} set_s_showModal={set_s_isBarcodeShowModal} />
        </CustomModal>
      </DisabledBox>
    </div>
  );
}
