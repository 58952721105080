import zhTW from "antd/es/locale/zh_TW";
import viVN from "antd/es/locale/vi_VN";
import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import idID from "antd/es/locale/id_ID";
import thTH from "antd/es/locale/th_TH";
// import thTH from "antd/locale/id_ID";


// import zhTW from "antd/es/lib/locale/zh_TW";

import "moment/locale/es-us";
import "moment/locale/vi";
import "moment/locale/zh-tw";
import "moment/locale/zh-cn";
import "moment/locale/id";
import "moment/locale/th";

const packageLan = {
  "en-US": enUS,
  "zh-TW": zhTW,
  "zh-CN": zhCN,
  "vi-VN": viVN,
  "id-ID": idID,
  "th-TH": thTH,
};

export default packageLan;
