/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { render } from "bwip-js";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomTable } from "@/components/Custom/AntdCustom";
import useAPI from "@/hooks/useAPI";
import { addQo, getSipHd, getSipTb, getWo } from "@/service/api/qcAPI";
import { getAllStaffs } from "@/service/api/wosAPI";
import { formatAmount } from "@/util/format";

const AddQoModal = ({ setIsModal }) => {
  const [form] = Form.useForm();
  const f_PN = Form.useWatch("PN", form);
  // 檢驗標準
  const f_dcUUID = Form.useWatch("dcUUID", form);
  const { t } = useTranslation();
  const call_getAllStaffs = useAPI(getAllStaffs);
  const call_getWo = useAPI(getWo);
  // 取得檢驗單取得的表頭
  const call_getSipHd = useAPI(getSipHd);
  // 取得檢驗單取得的表身
  const call_getSipTb = useAPI(getSipTb);
  // 新增品質檢驗單
  const call_addQo = useAPI(addQo);
  // 抽樣人員下拉選單
  const [s_staffData, set_s_staffData] = useState([]);
  const [s_sip_options, set_s_sip_options] = useState([]);
  // 工單號下拉選單
  const [s_woData, set_s_woData] = useState([]);
  //檢驗項目
  const [s_tableData, set_s_tableData] = useState([]);
  const onSubmit = (values) => {
    // call_addRunSipRec.request({ ...s_chooseInsp_tb, ...values });
    console.log("values = ", values);
    if (s_tableData.length === 0) {
      message.error("查無檢驗項目");
      return;
    }
    call_addQo.request(values);
  };

  useEffect(() => {
    call_getWo.request();
    call_getAllStaffs.request();
  }, []);

  // useAI 取的抽樣人員下拉選單
  useEffect(() => {
    const { status, data, msg } = call_getAllStaffs;
    if (status === "suc") {
      message.success(msg);
      set_s_staffData(
        data?.map((staff) => ({
          label: `${staff.cardID}-${staff.peopleNM}`,
          value: staff.cardID,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getAllStaffs.status]);

  // useAI 取的工單號下拉選單
  useEffect(() => {
    const { status, data, msg } = call_getWo;
    if (status === "suc") {
      message.success(msg);
      set_s_woData(
        data?.map((wo) => ({
          label: wo.woN,
          value: wo.UUID,
          wo,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getWo.status]);

  // useAI 取的檢驗單取得的表頭
  useEffect(() => {
    const { status, data, msg } = call_getSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_sip_options(
        data?.map((sip) => ({
          label: (
            <span>
              {sip.sipID}
              <span className="text-blue-500">&nbsp;版本: &nbsp;</span>
              {sip.version}
            </span>
          ),
          // label: `${sip.sipID} 版本: ${sip.version}`,
          key: sip.dcUUID,
          value: sip.dcUUID,
        })) || []
      );
      console.log("siphd = ", data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getSipHd.status]);

  // useAPI 取的檢驗單取得的表身
  useEffect(() => {
    const { status, data, msg } = call_getSipTb;
    if (status === "suc") {
      message.success(msg);
      console.log("call_getSipTb.data = ", data);
      set_s_tableData(data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getSipTb.status]);

  // useAPI 新增品質檢驗單
  useEffect(() => {
    const { status, msg } = call_addQo;
    console.log("call_addQo = ", call_addQo);
    if (status === "suc") {
      message.success(msg);
      setIsModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQo.status]);

  const tableColumns1 = [
    {
      title: "驗收型態",
      dataIndex: "insptype",
      align: "center",
      // width: "7%",
      render: (text) => (text === "count" ? "計數型" : "計量型"),
    },
    {
      title: "檢驗項目(球號)",
      dataIndex: "inspitem",
      align: "center",
      // width: "7%",
    },
    {
      title: "標準值",
      dataIndex: "SL",
      align: "right",
      // className: "bg-[#f9e9d7ac]",
      // width: "4%",
      // render: (text) => formatAmount(text),
    },
    {
      title: "USL",
      dataIndex: "USL",
      // className: "bg-[#f9e9d7ac]",
      align: "right",
      // width: "4%",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: "LSL",
      dataIndex: "LSL",
      // className: "bg-[#f9e9d7ac]",
      align: "right",
      // width: "4%",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: "UCL",
      dataIndex: "UCL",
      // className: "bg-[#f9e9d7ac]",
      align: "right",
      // width: "4%",
      render: (text) => formatAmount(text, 2),
    },
    {
      title: "LCL",
      dataIndex: "LCL",
      align: "right",
      // className: "bg-[#f9e9d7ac]",
      // width: "4%",
      render: (text) => formatAmount(text, 2),
    },
  ];

  const tableColumns = [
    {
      title: "序",
      dataIndex: "itemno",
      align: "center",
      width: "80px",
      // render: () => <MenuOutlined />,
    },
    // {
    //   title: "自動判定",
    //   width: "100px",
    //   dataIndex: "qcjudge",
    //   align: "center",
    //   render: (text) => {
    //     if (text) {
    //       // G 合格 Y 不合格
    //       return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    // {
    //   title: "合格判定",
    //   width: "100px",

    //   dataIndex: "qcresult",
    //   align: "center",
    //   render: (text) => {
    //     if (text) {
    //       // G 合格 Y 不合格
    //       return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗工具",
      dataIndex: "gpID",
      align: "center",
      width: "200px",
    },
    {
      title: "檢驗要點",
      dataIndex: "inspspec",
      align: "center",
      width: "200px",
    },
    {
      title: "圖號",
      dataIndex: "drawno",
      align: "center",
      width: "200px",
    },
    {
      title: "中值",
      dataIndex: "SL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格上限",
      dataIndex: "USL",
      align: "center",
      width: "150px",
    },
    {
      title: "規格下限",
      dataIndex: "LSL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      align: "center",
      width: "150px",
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      align: "center",
      width: "150px",
    },
    {
      title: "單位",
      width: "100px",
      dataIndex: "UOM",
      align: "center",
    },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        siptype: Cookies.get("siptype"),
      }}
    >
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="檢驗類型" name="siptype">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="工單號"
            name="UUID"
            rules={[{ required: true, message: t("util.util.placeholder_input") + "工單號" }]}
          >
            <Select
              placeholder="請選擇工單號"
              options={s_woData}
              onChange={(_, option) => {
                form.setFieldsValue({
                  PN: option.wo.PN || "",
                  dcUUID: "", // 清空檢驗標準
                });
                call_getSipHd.request({ PN: option.wo.PN || "", siptype: Cookies.get("siptype") });
                // 清空檢驗標準
                set_s_sip_options([]);
                set_s_tableData([]);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="料號" name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="抽樣時間" name="sampleT">
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("util.util.placeholder_select") + "抽樣時間"}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="抽樣人員" name="sampleID">
            <Select options={s_staffData} placeholder="請選擇抽樣人員" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={16}>
          <Form.Item
            label="檢驗標準"
            name="dcUUID"
            rules={[{ required: true, message: t("util.util.placeholder_input") + "檢驗標準" }]}
          >
            <Select
              options={s_sip_options}
              placeholder="請選擇檢驗標準"
              onChange={(dcUUID) => call_getSipTb.request({ dcUUID })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        rowClassName={(record, index) =>
          index % 2 === 1 //隔行變色
            ? "once"
            : "twoe"
        }
      />

      <Divider />
      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end gap-2">
          <Button size="large" onClick={() => setIsModal(false)}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddQoModal;
