import { instance } from "../handlerAPI";
import apiBasic from "../APIBasic";

export const confirm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/confirm`, data);
};

export const cancel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/cancel`, data);
};

export const prefixWorkAPI = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/prefixWork`, data);
};

export const suffixWorkAPI = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/suffixWork`, data);
};

export const getAllNgItem = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllNgItem`, {
    params: _params,
  });
};

export const addNg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/addNg`, data);
};

export const getAllNg = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllNg`, {
    params: _params,
  });
};

export const getAllOkItem = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllOkItem`);
};

export const addOk = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/addOk`, data);
};

export const gostk = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mc/gostk`, data);
};

export const getAllOk = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mc/getAllOk`, {
    params: _params,
  });
};

export const wpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/wpc`, data);
};
export const restart = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/restart`, data);
};
