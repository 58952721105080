import { Space } from "antd";

import IDimg from "../../assets/img/id.png";
import TWimg from "../../assets/img/tw.png";
import USimg from "../../assets/img/us.png";

const Countrys = ({ locale, handlerSystemConfigChange }) => {
  const countries = [
    { name: "zh-TW", img: TWimg },
    { name: "en-US", img: USimg },
    // { name: "vi-VN", img: VIimg },
    { name: "id-ID", img: IDimg },
    // { name: "th-TH", img: THimg },
  ];

  return (
    <Space className="gap-3 min-h-[56px]">
      {countries.map((country, index) => (
        <img
          src={country.img}
          onClick={() => handlerSystemConfigChange("locale", country.name)}
          className={`${
            locale === country.name ? "w-[50px] h-[50px]" : "w-[30px] h-[30px]"
          } cursor-pointer transition-all`}
          alt="country flag"
          key={index}
        />
      ))}
    </Space>
  );
};

export default Countrys;
