import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { Image } from "antd";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import apiBasic from "@/service/APIBasic";

const DrawingModal = ({ thisWpc }) => {
  const [s_pdfInfo, set_s_pdfInfo] = useState({
    pageNumber: 1,
    pdfData: {},
    size: window.innerWidth / 2,
    path: "",
  });
  return (
    // <Image
    //   width="100%"
    //   preview={false}
    //   // src={`${apiBasic.conn}://${apiBasic.url}/${thisWpc?.drawing}`}
    //   src={`https://mes.satdg.com/wos/doc/QMS/SIP/SIP_OQC.WP_5A-DNI-Y230-002-K_001/%E5%B7%A5%E7%A8%8B%E5%9C%96%E7%AF%84%E4%BE%8B_240107.pdf`}
    // />
    <Document
      // file={`https://mes.satdg.com/wos/doc/QMS/SIP/SIP_OQC.WP_5A-DNI-Y230-002-K_001/%E5%B7%A5%E7%A8%8B%E5%9C%96%E7%AF%84%E4%BE%8B_240107.pdf`}
      file={`${apiBasic.conn}://${apiBasic.url}/${thisWpc?.drawing}`}
      // onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page
        width={s_pdfInfo.size}
        pageNumber={s_pdfInfo.pageNumber}
        className="border-solid border-2 flex justify-center items-center border-gray-500/75 drop-shadow-2xl shadow-lg"
      />
    </Document>
  );
};

export default DrawingModal;
