import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MyButton from "@/components/Button";
import { CustomModal } from "@/components/Custom/AntdCustom";
import Text from "@/components/Text";

import ApsWorkOrder from "./ApsWorkOrder";
import ArtificialWorkOrder from "./ArtificialWorkOrder";

export default function WorkOrderSend({ setIsModal }) {
  const { t } = useTranslation();
  // 使用字串判斷是否為初次打開
  // 人工工單 Modal
  const [isArtificialWorkOrder, setIsArtificialWorkOrder] = useState("def");

  const [isApsWorkOrder, setIsApsWorkOrder] = useState("def");

  useEffect(() => {
    isArtificialWorkOrder === "false" && setIsModal(false);
  }, [isArtificialWorkOrder]);

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col span={12} className="flex justify-center items-center">
          <MyButton height={127} width={180} onClick={() => setIsArtificialWorkOrder("true")}>
            <Text level="h2">
              {t("WOS.wlwoc.artificialWorkOrder")}
              {/* 人工工單 */}
            </Text>
          </MyButton>
        </Col>
        <Col span={12} className="flex justify-center items-center">
          <MyButton disabled height={127} width={180} onClick={() => setIsApsWorkOrder("true")}>
            <Text level="h2">
              {t("WOS.wlwoc.apsWorkOrder")}
              {/* APS工單 */}
            </Text>
          </MyButton>
        </Col>
      </Row>

      <CustomModal
        key="isArtificialWorkOrder"
        title={t("WOS.wlwoc.artificialWorkOrder")} //"人工工單"
        visible={isArtificialWorkOrder === "true"}
        onCancel={() => setIsArtificialWorkOrder("false")}
        footer={null}
        width="60%"
      >
        <ArtificialWorkOrder setIsModal={setIsArtificialWorkOrder} />
      </CustomModal>

      <CustomModal
        key="isApsWorkOrder"
        title={t("WOS.wlwoc.apsWorkOrder")} //"APS工單"
        width="70%"
        visible={isApsWorkOrder === "true"}
        onCancel={() => setIsApsWorkOrder("false")}
        footer={null}
      >
        <ApsWorkOrder setIsModal={setIsApsWorkOrder} />
      </CustomModal>
    </div>
  );
}
