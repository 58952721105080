import { Button, Col, Input, Row, Select, Tooltip, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomModal } from "@/components/Custom/AntdCustom";
import SquareLight from "@/components/Light/SquareLight";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getAllWlID, getAllWsID } from "@/service/api/pisAPI";
import { getAllWpc } from "@/service/api/publicAPI";
import { getQo } from "@/service/api/qcAPI";
import { FullscreenOutlined, SearchOutlined } from "@ant-design/icons";

import CustomTable from "../components/Table/Qclist";
import { WlWocContext } from "../index";
import WpcDetailModal from "./QCDetailModal";

export default function QCList({ setCall_getAllWpc, thisWpc, setThisWpc, className, wpcType }) {
  return (
    <QCListCompoment
      setCall_getAllWpc={setCall_getAllWpc}
      thisWpc={thisWpc}
      setThisWpc={setThisWpc}
      className={className}
      wpcType={wpcType}
    />
  );
}

export function QCListCompoment(props) {
  const { setCall_getAllWpc, thisWpc, setThisWpc, className, wpcType, isModal } = props;
  const { t } = useTranslation();
  const { s_siptype, s_showAddQo } = useContext(WlWocContext);
  const call_getQo = useAPI(getQo);
  const [queryString, setQueryString] = useState("");
  const [s_searchData, set_s_searchData] = useState({});
  const call_getWlID = useAPI(getAllWlID);
  const call_getWsID = useAPI(getAllWsID);
  // console.log("call_getWsID", call_getWsID);
  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 10, //回傳筆數

    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const situFArrya = [
    { key: "B", label: "", value: "main" },
    { key: "G", label: "未結案", value: "success" },
    { key: "Y", label: "結案", value: "warning" },
    { key: "R", label: "結案", value: "AV" },
  ];

  const [tableData, setTableData] = useState([]);

  const [isWpcDatailModal, setIsWpcDatailModal] = useState(false);

  const call_getAllWpc = useAPI(getAllWpc);

  const tableColumns = [
    {
      title: "歷程",
      dataIndex: "lifeF",
      width: "60px",
      align: "center",

      render: (lifeF) => {
        const map = {
          "0": "已創建",
          "1": "空",
          "2": "開始檢驗",
          "7": "完成檢驗",
          "8": "最終判定",
          "P": "質控合格",
          "W": "質控特採",
          "R": "質控隔離",
        };
        return map[lifeF];
      },
    },
    {
      title: "合格燈", // "檢驗單號",
      dataIndex: "qcjudge",
      width: "40px",
      align: "center",
      // filters: situFArrya.map((item) => ({
      //   value: item.key,
      //   text: (
      //     <>
      //       <SquareLight size="20px" type={`${item.value}`} />
      //       <span className={` ml-2`}>{item.label}</span>
      //     </>
      //   ),
      // })),
      // onFilter: (value, record) => {
      //   return record.situF.indexOf(value) === 0;
      // },
      render: (str) => {
        console.log("str", str);
        switch (str) {
          case "P":
            return <SquareLight size="20px" type="success" />;
          case "W":
            return <SquareLight size="20px" type="warning" />;
          case "R":
            return <SquareLight size="20px" type="error" />;
          default:
            return <SquareLight size="20px" disabled />;
        }
      },
    },
    {
      title: "檢驗單號",
      dataIndex: "qoN",
      width: "130px",
      align: "center",
    },
    {
      title: t("WOS.util.typeInspection"),
      dataIndex: "siptype",
      width: "60px",
      align: "center",
    },

    {
      title: "工(源)號",
      dataIndex: "woN",
      width: "90px",
      align: "center",
    },
    {
      title: t("util.util.PN"),
      dataIndex: "PN",
      width: "130px",
      align: "center",
      render: (PN) => (
        <Tooltip title={PN}>
          <div className="truncate  w-full">{PN}</div>
        </Tooltip>
      ),
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      width: "160px",
      align: "center",
      render: (pdtNM) => (
        <Tooltip title={pdtNM}>
          <div className="truncate  w-full">{pdtNM}</div>
        </Tooltip>
      ),
    },
    {
      title: t("util.util.pf"),
      dataIndex: "pfID",
      width: "90px",
      align: "center",
      render: (pfID, rowData) => <Tooltip title={rowData.pfNM}>{pfID}</Tooltip>,
    },
    {
      title: t("util.util.ws"),
      dataIndex: "wsID",
      width: "90px",
      align: "center",
      render: (wsID, rowData) => <Tooltip title={rowData.wsNM}>{wsID}</Tooltip>,
    },
    {
      title: t("WOS.wlwoc.We"),
      dataIndex: "wlID",
      width: "60px",
      align: "center",
      render: (wlID, rowData) => <Tooltip title={rowData.wlNM}>{wlID}</Tooltip>,
    },
    {
      title: t("util.util.pwcID"),
      dataIndex: "pwcID",
      width: "60px",
      align: "center",
      render: (pwcID, rowData) => <Tooltip title={rowData.pwcNM}>{pwcID}</Tooltip>,
    },
    {
      title: "計畫產量",
      dataIndex: "planqty",
      width: "60px",
      align: "center",
    },
    {
      title: "良品數",
      dataIndex: "okC",
      width: "40px",
      align: "center",
    },
    {
      title: "不良數",
      dataIndex: "ngC",
      width: "40px",
      align: "center",
    },
  ];

  // 將 call_getAllWpc.request return 上去 方便各個地方調用
  const rtnGetAllWpc = (obj) => {
    console.log("obj", obj);
    const { request } = call_getQo;
    return () => request(obj);
  };

  useEffect(() => {
    call_getWlID.request();
    call_getWsID.request();
  }, []);

  // siptype 改變時、建立檢驗單重新取得資料
  useEffect(() => {
    call_getQo.request({ siptype: s_siptype, ...pageInfo });
  }, [s_siptype, s_showAddQo]);

  useEffect(() => {
    if (call_getQo.status === "suc") {
      setPageInfo((prev) => {
        return { ...prev, ...call_getQo.data.pageInfo };
      });
      setTableData(call_getQo.data.tableData?.map((item) => ({ ...item, key: item.qoN })) || []);
      message.success(call_getQo.msg);
    }
    if (call_getQo.status === "err") {
      setTableData([]);
      // message.error(call_getQo.msg);
    }
  }, [call_getQo.status]);

  // useEffect(() => {
  //   call_getAllWpc.request({ ...pageInfo, queryString: queryString, wpcType: wpcType });
  // }, [pageInfo.pag]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      setPageInfo((prev) => ({ ...prev, ...call_getAllWpc.data.pageInfo }));

      setTableData(
        call_getAllWpc.data.tableData?.map((x) => {
          return {
            ...x,
            key: x.UUID,
            // wlID: Cookies.get("wlID"),
            // wsID: Cookies.get("wsID"),
            linemixStr:
              x.linemix === 1
                ? t("WOS.util.linemix_1")
                : x.linemix === 2
                ? t("WOS.util.linemix_2")
                : t("WOS.util.linemix_0"),
            // wotype: x.wotype === 0 ? "正常工單" : x.wotype === 1 ? "重工工單" : "no Data",
            wotype:
              x.wotype === 0
                ? t("WOS.util.wotype_0")
                : x.wotype === 1
                ? t("WOS.util.wotype_1")
                : "",
            lifeFstr: t(`WOS.util.lifeF_${x.lifeF}`),
          };
        })
      );
      message.success(call_getAllWpc.msg);
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表
      setTableData([]);
      message.error(call_getAllWpc.msg);
    }
    setCall_getAllWpc((other) =>
      rtnGetAllWpc({ ...pageInfo, queryString: queryString, wpcType: wpcType, ...other })
    );
  }, [call_getAllWpc.status]);

  useEffect(() => {
    // 將 queryString,pageInfo 重新 return  call_getAllWpc.request 的參數
    setCall_getAllWpc((other) =>
      rtnGetAllWpc({ ...pageInfo, queryString: queryString, wpcType: wpcType, ...other })
    );
  }, [queryString, pageInfo]);

  useEffect(() => {
    // getAllWpc 更新時 重新取得當前途單最新資料
    if (Object.keys(thisWpc).length > 0) {
      // setThisWpc({});
      // 當前目錄尋找thisWpc
      const rtn = tableData.filter((x) => x.qoN === thisWpc.qoN)[0];
      if (rtn) {
        setThisWpc({ ...thisWpc, ...rtn });
      } else {
        setThisWpc({});
      }
    } else {
      setThisWpc(tableData[0] ? tableData[0] : {});
    }
  }, [tableData]);

  useEffect(() => {
    if (call_getWlID.status === "err") {
      message.error(call_getWlID.msg);
    }
  }, [call_getWlID.status]);

  useEffect(() => {
    if (call_getWsID.status === "err") {
      message.error(call_getWsID.msg);
    }
  }, [call_getWsID.status]);

  return (
    <Row className={className}>
      <Col span={3}>
        {/* 如果是在放大版本不要顯示 */}
        {!isModal && (
          <Text level="h2" type="primary" className=" text-[#7c79d2]">
            {t("WOS.util.checklist") + t("WOS.util.index")}
            {/* 檢單索引 */}
          </Text>
        )}
      </Col>
      <Col span={21} className="mb-1">
        <div className="flex w-full justify-end gap-3">
          <Input
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            onChange={(e) => set_s_searchData({ ...s_searchData, PN: e.target.value })}
            allowClear
            className="w-[300px]"
            placeholder={t("util.util.placeholder_select") + t("util.util.PN")}
          />
          <Input
            size="large"
            onChange={(e) => set_s_searchData({ ...s_searchData, qoN: e.target.value })}
            allowClear
            className="w-[300px]"
            placeholder={t("util.util.placeholder_input") + t("WOS.wlwoc.INSP")}
          />
          <Input
            size="large"
            onChange={(e) => set_s_searchData({ ...s_searchData, woN: e.target.value })}
            allowClear
            className="w-[300px]"
            placeholder={t("util.util.placeholder_select") + "工單號"}
          />

          <Select
            placeholder={t("util.util.placeholder_input") + t("util.util.ws")}
            allowClear
            enterButton
            size="large"
            className="w-[300px]"
            options={
              call_getWsID?.data?.map((x) => ({
                label: x.wsID + "_" + x.wsNM,
                value: x.wsID,
                data: x,
              })) || []
            }
            onChange={(wsID) => set_s_searchData({ ...s_searchData, wsID })}
            // onSearch={() => call_getAllWpc.request({ ...pageInfo, queryString: queryString })}
          />
          <Select
            size="large"
            // onChange={(e) => setQueryString(e.target.value)}
            className="w-[300px]"
            allowClear
            options={
              call_getWlID?.data?.map((x) => ({
                label: x.wlID + "_" + x.wlNM,
                value: x.wlID,
                data: x,
              })) || []
            }
            onChange={(wlID) => set_s_searchData({ ...s_searchData, wlID })}
            placeholder={t("util.util.placeholder_input") + t("WOS.wlwoc.We")}
          />

          <Button
            className="bg-[#7c79d2] border-[#7c79d2]"
            size="large"
            type="primary"
            icon={<SearchOutlined />}
            onClick={() =>
              call_getQo.request({
                ...pageInfo,
                siptype: s_siptype,
                queryString,
                ...s_searchData,
              })
            }
          />
          {/* 如果是在放大版本不要顯示 */}
          {!isModal && (
            <Button
              className="bg-[#7c79d2] border-[#7c79d2]"
              size="large"
              type="primary"
              icon={<FullscreenOutlined />}
              onClick={() => setIsWpcDatailModal(true)}
            />
          )}
        </div>
      </Col>
      {/* <Col span={1} className="flex-end ">
        <Button className="bg-[#7c79d2] border-[#7c79d2]" size="large" type="primary" icon={<FullscreenOutlined />} onClick={() => {}} />
      </Col> */}
      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          // dataSource={tableData}
          dataSource={tableData}
          size="small"
          loading={call_getAllWpc.status === "load"}
          scroll={{ y: isModal ? 500 : 185 }}
          rowClassName={(record, index) =>
            record.qoN === thisWpc?.qoN
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          onRow={(record) => {
            return {
              onClick: () => (record.qoN === thisWpc?.qoN ? setThisWpc({}) : setThisWpc(record)),
            };
          }}
          pagination={{
            total: pageInfo.totalItems,
            current: pageInfo.page,
            pageSize: pageInfo.pageSize,
            onChange: (current) => {
              call_getQo.request({
                ...s_searchData,
                ...pageInfo,
                page: current,
                siptype: s_siptype,
                queryString,
              });
              setPageInfo((prev) => ({ ...prev, page: current }));
            },
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
              </Text>
            ),
          }}
        />
      </Col>
      {/* detailModal */}
      <CustomModal
        key="isWpcDatailModal"
        title={t("WOS.util.wpcList") + t("util.util.detail")}
        // title="途單索引詳情"
        visible={isWpcDatailModal}
        onCancel={() => setIsWpcDatailModal(false)}
        footer={null}
        width={"100%"}
      >
        <WpcDetailModal setIsModal={setIsWpcDatailModal} {...props} />
      </CustomModal>
    </Row>
  );
}
