import { Button, Col, Divider, Input, Modal, Row, Select, Space, message } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomTable } from "@/components/Custom/AntdCustom";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import useStateRef from "@/hooks/useStateRef";
import { aps, getAps } from "@/service/api/wosAPI";
import checkUndefined from "@/util/checkUndefined";

export default function ApsWorkOrder({ setIsModal }) {
  const { t } = useTranslation();
  const { Search } = Input;

  const { Option } = Select;

  const APSCol = [
    {
      title: t("util.util.woN"), //"工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.wlID"), // "線別",
      dataIndex: "logwlID",
      key: "logwlID",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.opcode"), //"單別",
      dataIndex: "wotype",
      key: "wotype",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.PN"), //"料號",
      dataIndex: "PN",
      key: "PN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      key: "planqty",
      render: (str) => checkUndefined(str),
    },
  ];

  const [loginAps, setLoginAps] = useState({});

  const [queryString, setQueryString] = useState("");

  // 全線all 本線 "" 空字串
  const [allWlID, setAllWlID] = useState("all");

  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 10, //回傳筆數
    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const [tableData, setTableData] = useState([]);

  // 使用useRef 來取得最新的值 , 因為Modal.confirm 被關閉時才會setState
  const [chooseLinemix, setChooseLinemix, chooseLinemixRef] = useStateRef();

  const call_getAps = useAPI(getAps);

  const call_aps = useAPI(aps);

  useEffect(() => {
    call_getAps.request({
      ...pageInfo,
      queryString: queryString,
      allWlID: allWlID,
    });
  }, []);

  useEffect(() => {
    if (call_getAps.status === "suc") {
      setPageInfo((prev) => {
        return { ...prev, ...call_getAps.data.pageInfo };
      });
      setTableData(
        call_getAps.data.tableData?.map((x) => {
          return {
            ...x,
            key: x.woN,
            wlID: Cookies.get("wlID"),
            wsID: Cookies.get("wsID"),
            linemixStr:
              x.linemix === 1
                ? t("WOS.util.linemix_1")
                : x.linemix === 2
                ? t("WOS.util.linemix_2")
                : t("WOS.util.linemix_0"),
            // wotype: x.wotype === 0 ? "正常工單" : x.wotype === 1 ? "重工工單" : "no Data",
            wotype:
              x.wotype === 0
                ? t("WOS.util.wotype_0")
                : x.wotype === 1
                ? t("WOS.util.wotype_1")
                : "",
          };
        })
      );
      message.success(call_getAps.msg);
    } else if (call_getAps.status === "err") {
      // 只要失敗就清空途單列表
      setTableData([]);
      message.error(call_getAps.msg);
    }
  }, [call_getAps.status]);

  const wpcCheck = () =>
    Modal.confirm({
      title: (
        <Text level="h3">
          {t("WOS.wlwoc.isRegisterTheWoN")}?{/* 是否對該筆工單進行登錄？ */}
        </Text>
      ),
      content: (
        <div>
          <Text level="h3">
            {t("util.util.placeholder_select") +
              t("WOS.util.linemix") +
              "，" +
              t("WOS.wlwoc.retake") +
              t("util.util.register")}
            {/* 請先選擇混線，再進行登錄 */}
          </Text>
          <Select
            size="large"
            style={{ margin: "10px 10px", width: "300px" }}
            placeholder={t("util.util.placeholder_select") + t("WOS.util.linemix")}
            // "請選擇混線"
            onChange={(e) => setChooseLinemix(e)}
          >
            {/* <Option key="0" value={0}>
              不混線
            </Option> */}
            <Option key="1" value={1}>
              {t("WOS.util.linemix_1")}
              {/* 左線 */}
            </Option>
            <Option key="2" value={2}>
              {t("WOS.util.linemix_2")}
              {/* 右線 */}
            </Option>
          </Select>
        </div>
      ),
      okText: t("util.util.ok"), // "確認",
      okType: "primary",
      cancelText: t("util.util.cancel"), //"取消",
      centered: true,
      width: "600px",
      onOk() {
        if (typeof chooseLinemixRef.current !== "number") {
          message.error(
            t("util.util.placeholder_select") + t("WOS.util.linemix")
            // "請選擇混線"
          );
          return;
        }
        call_aps.request({ ...loginAps, linemix: chooseLinemixRef.current });
      },
    });

  useEffect(() => {
    if (call_aps.status === "suc") {
      message.success(call_aps.msg);
      setIsModal("false");
    } else if (call_aps.status === "err") {
      message.error(call_aps.msg);
    }
  }, [call_aps.status]);

  return (
    <Row gutter={[0, 6]}>
      <Col span={14}></Col>
      <Col span={5}>
        <Select
          onChange={(e) => {
            call_getAps.request({
              ...pageInfo,
              page: 1,
              queryString: queryString,
              allWlID: e,
            });
            setAllWlID(e);
          }}
          value={allWlID}
          style={{ width: "250px" }}
          size="large"
        >
          <Option value="all">
            {t("WOS.wlwoc.allLine")}
            {/* 全線 */}
          </Option>
          <Option value="">
            {t("WOS.wlwoc.thisLine")}
            {/* 本線 */}
          </Option>
        </Select>
      </Col>
      <Col span={5} className="flex justify-center items-center">
        <Search
          placeholder={
            t("util.util.placeholder_input") + t("util.util.woN") + "/" + t("util.util.PN")
          }
          // "請輸入完整的工單/料號"
          allowClear
          enterButton
          size="large"
          onChange={(e) => setQueryString(e.target.value)}
          onSearch={(e) =>
            call_getAps.request({
              ...pageInfo,
              page: 1,
              queryString: e,
              allWlID: allWlID,
            })
          }
        />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={APSCol}
          loading={call_getAps.status === "load"}
          dataSource={tableData}
          rowClassName={(record, index) =>
            record.woN === loginAps?.woN
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.woN === loginAps?.woN) {
                  setLoginAps({});
                } else {
                  setLoginAps(record);
                }
              },
            };
          }}
          pagination={{
            total: pageInfo.totalItems,
            current: pageInfo.currentPage,
            pageSize: pageInfo.pageSize,
            onChange: (current) =>
              call_getAps.request({
                ...pageInfo,
                page: current,
                queryString: queryString,
                allWlID: allWlID,
              }),
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
              </Text>
            ),
          }}
        />
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Space size={20}>
          <Button size="large" onClick={() => setIsModal("false")}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button
            type="primary"
            size="large"
            disabled={Object.keys(loginAps).length === 0}
            loading={call_getAps.status === "load"}
            onClick={wpcCheck}
          >
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
