import React, { useState, useEffect, useRef, useContext } from "react";
import { message, Button, Space, Input, InputNumber, Divider, Form, Table } from "antd";
import { CheckSquareOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Text from "@/components/Text";
// import { addMesNg } from "@/service/apis/APS/mc";
import useAPI from "@/hooks/useAPI";
import { IconNo } from "@/components/Icon/index";
import { addNg } from "@/service/api/mcAPI.js";

const StyleInputNumber = styled(InputNumber)`
  //加減容器
  .ant-input-number-handler {
    height: 100% !important;
    overflow: visible;
  }
  //增減按鈕
  .ant-input-number-handler-up .ant-input-number-handler-down {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    width: 10px !important;
  }
  .ant-input-number-input-wrap {
    height: 60px !important;
  }
  //加減外框容器
  .ant-input-number-handler-wrap {
    width: 150px !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      // 用來判斷目前是改哪一筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "note" ? (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
          />
        ) : (
          <StyleInputNumber
            ref={inputRef}
            controls={{
              upIcon: (
                <Text level="h3">
                  <PlusOutlined />
                </Text>
              ),
              downIcon: (
                <Text level="h3">
                  <MinusOutlined />
                </Text>
              ),
            }}
            // controls={{ upIcon: <Text>{123}</Text>, downIcon: <Text>{456}</Text> }}
            className="bg-green-500"
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={0}
            style={{ width: "100%" }}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap "
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const CustomNgTable = styled(Table)`
  .ant-table .ant-table-small .ant-table-bordered .ant-table-fixed-header {
    border-radius: 0px !important;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: 1px solid #fdfdfd !important;
  }

  .ant-table-cell-row-hover {
    background: #bcbcbc !important;
  }
  .ant-table-thead th.ant-table-cell {
    background-color: #fabbbb !important;
    text-align: center !important;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    padding: 8px;
    /*  更改antd原本設定間距 */
  }
  /* 在表格組件內固定antd寬度 設scroll={{ x: true }} 以固定寬度強制斷行 */
  .ant-table-tbody > tr > td {
    /* padding: 16px; 更改antd原本設定間距 */
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  .ant-table-tbody > tr > td:last-child {
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(1),
  .ant-table-tbody > tr > td:nth-child(2) {
    /* background-color: #ffffff; */
    text-align: center;
  }
`;
const NgModal = ({ set_s_isNgModal, s_editData, s_ngItemOptions, shift }) => {
  const { t } = useTranslation();
  const [s_ngArray, set_s_ngArray] = useState(
    s_ngItemOptions.map((item, index) => {
      return { ...item, sqty: 0, key: index };
    })
  );

  const call_addNg = useAPI(addNg);

  const middleIndex = Math.floor(s_ngArray.length / 2);
  const arrayA = s_ngArray.slice(0, middleIndex);
  const arrayB = s_ngArray.slice(middleIndex);
  console.log(s_editData);
  const totalNgAmount = s_ngArray.reduce((accumulator, currentValue) => {
    accumulator += currentValue.sqty;
    return accumulator;
  }, 0);
  const ngProductCol = [
    {
      title: t("util.util.itemID"), // 項目ID
      dataIndex: "value",
      align: "center",
      render: (value, rowData) => {
        return value.split("_")[0];
      },
    },
    {
      title: t("util.util.itemNM"), // 名稱
      dataIndex: "value",
      align: "center",
      render: (value, rowData) => {
        return value.split("_")[1];
      },
    },
    {
      title: t("WOS.util.qty"), // 數量
      dataIndex: "sqty",
      align: "right",
      editable: true,
      render: (num) => <InputNumber className="w-full" value={num} readOnly={true} />,
      onCell: (record) => ({
        className: "bg-[#FFECEC]",
        record,
        editable: true,
        dataIndex: "sqty",
        title: t("WOS.util.qty"),
        handleSave,
      }),
    },
  ];

  const handleSave = (data, dataIndex, value) => {
    const { sqty } = data;
    //輸入一樣的數字就直接返回
    if (sqty === value) return;

    //防止輸入非數字
    if (isNaN(value)) return;

    //整理更新資料
    const newArrya = s_ngArray.map((item, index) => {
      if (item.label === data.label) {
        return { ...item, sqty: value };
      }
      return item;
    });
    set_s_ngArray(newArrya);
    console.log(
      "要傳給後端的資料 = ",
      {
        ...data,
        [dataIndex]: value,
      },
      dataIndex
    );
  };
  const onSubmit = () => {
    const { UUID, woN } = s_editData;

    const payload = s_ngArray
      .filter((ngItem) => {
        return ngItem.sqty > 0;
      })
      .map((ngItem) => {
        return { UUID, woN, ItemID: ngItem?.value.split("_")[0], ngC: ngItem?.sqty, shift };
      });
    console.log(payload);
    if (payload.length === 0)
      return message.error(`${t("WOS.wlwoc.ng_login")} ${t("WOS.error.equalsZero")}`);

    call_addNg.request(payload);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  useEffect(() => {
    if (call_addNg.status === "suc") {
      message.success(call_addNg.msg);
      set_s_isNgModal(false);
    } else if (call_addNg.status === "err") {
      message.error(call_addNg.msg);
    }
  }, [call_addNg.status]);

  return (
    <div className="flex flex-col w-full ">
      <div className="flex w-full">
        <CustomNgTable
          bordered={true}
          columns={ngProductCol}
          components={components}
          className={`w-1/2`}
          dataSource={arrayA}
          scroll={{ y: 500 }}
          size="small"
          rowClassName={(record, index) =>
            index % 2 === 1 //隔行變色
              ? "bg-[#F6F6F6]"
              : "bg-[##BCBCBC]"
          }
          pagination={false}
        />
        <CustomNgTable
          bordered={true}
          className={`w-1/2`}
          components={components}
          columns={ngProductCol}
          dataSource={arrayB}
          scroll={{ y: 500 }}
          size="small"
          rowClassName={(record, index) =>
            index % 2 === 1 //隔行變色
              ? "bg-[#F6F6F6]"
              : "bg-[##BCBCBC]"
          }
          pagination={false}
        />
      </div>
      <Divider />
      <div className="flex w-full justify-between  ">
        <div className="bg-[#FABBBB] text-[24px] text-[#343434] rounded-[8px]  p-[10px] ">
          {`${t("APS.aps-manage.totalNGQty")}:  ${totalNgAmount}`}
        </div>
        <Space size={10}>
          <Button
            type="default"
            className="h-full w-[75px] pt-2"
            onClick={() => set_s_isNgModal(false)}
          >
            <IconNo className="text-[26px] " />
          </Button>
          <Button
            type="primary"
            className="h-full w-[75px] pt-2"
            // disabled={true}
            onClick={() => {
              onSubmit();
            }}
          >
            <CheckSquareOutlined className="text-[26px]" />
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default NgModal;
