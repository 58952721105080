/**
替換字串中的變數的函數
@param {String} text - 要替換的字串
@param {Array} variables - 包含變數的陣列
@returns {String} - 回傳替換後的字串
*/
const replaceVariable = (text, data, variables) => {
  var _a, _b, _c;
  // 使用正則表達式找到所有變數
  const reg_g = /\$\{(.+?)}/g;
  let result = null;
  let list = [];
  do {
    result = reg_g.exec(text.toString());
    result && list.push(result[1]);
  } while (result);
  let newText = text;
  // 將每個變數替換為對應的值
  for (const index of list) {
    // 將索引值轉換為數字
    const indexNum = parseInt(index, 10);
    // 從變數陣列中取得對應的值
    let variableValue = (_a = data[indexNum - 1]) === null || _a === void 0 ? void 0 : _a.text;
    if (
      ((_b = data[indexNum - 1]) === null || _b === void 0 ? void 0 : _b.keyText) &&
      !variableValue
    ) {
      let value =
        variables[(_c = data[indexNum - 1]) === null || _c === void 0 ? void 0 : _c.keyText];
      if (typeof value === "string" || typeof value === "number") {
        variableValue = value.toString();
      }
    }
    //  || variables[data[indexNum - 1]?.keyText];
    if (!variableValue) {
      // 如果找不到對應的值，則設為空
      variableValue = "";
    }
    // 將變數替換為對應的值
    newText = newText.replace("${" + index + "}", variableValue);
  }
  return newText;
};
/**
 * 引用其他項目固定字串或關鍵字串的內容的函數
 * @param data 包含替換的數據的物件
 * @param item 要引用的 item 物件
 * @param variable 包含變數的物件
 * @returns 回傳修改後的 item 物件
 */
const replaceItemVars = (data, item, variables) => {
  if (item.type === "keyText") {
    // 如果是 keyText，則從 variables 中取得對應的值並替換掉 keyText 的值
    let variableValue = undefined;
    if (typeof item.keyText === "string" || typeof item.keyText === "number") {
      const value = variables[item.keyText];
      if (typeof value === "string" || typeof value === "number") {
        variableValue = value.toString();
      }
    }
    if (!variableValue) {
      // 如果找不到對應的值，則設為空
      variableValue = "";
    }
    return Object.assign(Object.assign({}, item), { keyText: variableValue });
  }
  // 替換掉該 item 的 quoteText 中的變數
  const replacedQuoteText = replaceVariable(item.quoteText || "no Data", data, variables);
  // 如果不是 keyText，則將 quoteText 替換為新的值
  return Object.assign(Object.assign({}, item), { quoteText: replacedQuoteText });
};

export { replaceItemVars, replaceVariable };
