import axios from "axios";
import qs from "qs";
import apiBasic from "./APIBasic";

export let instance = axios.create({ timeout: 10000, baseURL: apiBasic.url });
// axios的全局配置
instance.defaults.headers.post["Content-Type"] = "application/json";
//跨域存取cookies
instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  function (config) {
    if (config.method === "get") {
      config.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: "indices" });
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    //這裡不能get urlstorage，因為這裡是執行後的結果，所以token應該要在執行前就先拿到
    // console.log(response.data);
    if (response) {
      if (response.data.status === 200) {
        const data = {
          status: true,
          msg: response.data.msg,
          data: response.data.data,
        };
        return data;
      } else if (response.data.status === 403) {
        console.log(response.data);
        window.location.assign(`/wos/login`);
      } else {
        const data = {
          status: false,
          msg: response.data ? response.data.msg : "handlerAPI ->伺服器連接失敗",
          data: response.data.data,
        };
        return data;
      }
    }
  },
  (err) => {
    const { response } = err;
    console.log(err);
    if (response) {
      const data = {
        status: false,
        data: "",
        msg: errorHandle(response.status, response.data),
      };
      return data;
    } else {
      const data = {
        status: false,
        data: {},
        msg: "請求逾時",
      };
      return data;
    }
  }
);

const errorHandle = (status, other) => {
  switch (status) {
    case 400:
      return "handlerAPI -> 訊息效驗失敗 400";
    case 401:
      return "handlerAPI ->認證失败 401";
    case 403:
      return "handlerAPI ->403";
    case 404:
      return "handlerAPI ->請求的資源不存在 404";
    default:
      console.log("handlerAPI ->", other);
      return "handlerAPI ->無法辨識的資源";
  }
};
