import { instance } from "../handlerAPI";
import apiBasic from "../APIBasic";

export const batchIn = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/batchIn`, data);
};

export const barcodeOperationWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/barcodeOperationWpc`, {
    params: _params,
  });
};

export const getDoc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDoc`, {
    params: _params,
  });
};
// --------------------------------APS登錄------------------------------------------------------
export const getAps = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAps`, {
    params: _params,
  });
};

export const aps = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/aps`, data);
};
// --------------------------------------------------------------------------------------
// --------------------------------機碼------------------------------------------------------
export const getWe = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWe`, {
    params: _params,
  });
};

export const chooseWe = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/chooseWe`, data);
};

export const getSipID = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSipID`, {
    params: _params,
  });
};

// --------------------------------途單登錄------------------------------------------------------
export const getWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpc`, {
    params: _params,
  });
};

export const sfcWpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/sfcWpc`, data);
};

export const alexCarLogin = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/alexCarLogin`, data);
};
export const alexConfirm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/qc/alexConfirm`, data);
};
// --------------------------------------------------------------------------------------

// --------------------------------途單撤登------------------------------------------------------
export const getAllReturnWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllReturnWpc`, {
    params: _params,
  });
};

export const returnWpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/returnWpc`, data);
};
// --------------------------------------------------------------------------------------

// ---------------------------------------人工工單-----------------------------------------------

export const getPNs = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPNs`);
};

export const getAllWpcset = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWpcset`, {
    params: _params,
  });
};

export const manualWoN = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/manualWoN`, data);
};

// -----------------------報開工---------------------------------------------------------------
export const prepare = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/prepare`, data);
};

// ----------------------- 開始生產 ---------------------------------------------------------------
export const open = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/open`, data);
};

// ----------------------- 結單 ---------------------------------------------------------------

export const close = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/close`, data);
};
// ----------------------- 報離線 ---------------------------------------------------------------

export const down = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/down`, data);
};

export const getOffList = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOffList`, {
    params: _params,
  });
};

// ----------------------- 停工/復工 ---------------------------------------------------------------

export const pause = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/pause`, data);
};

export const resume = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/resume`, data);
};

export const getPauseList = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPauseList`, {
    params: _params,
  });
};

// ----------------------- 報下線 ---------------------------------------------------------------
export const off = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/off`, data);
};

// ----------------------- 報上線 ---------------------------------------------------------------
export const prefix = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/prefix`, data);
};

// ----------------------- 報下線 ---------------------------------------------------------------
export const suffix = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/suffix`, data);
};

// ----------------------- 工時管理 ---------------------------------------------------------------
export const chooseWpcCurrentStaff = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/chooseWpcCurrentStaff`, {
    params: _params,
  });
};

export const getAllStaffs = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStaffs`);
};

export const upStaff = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/upStaff`, data);
};

export const downStaff = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/downStaff`, data);
};

// ----------------------- 異常管理 ---------------------------------------------------------------

// 異常解除 下拉選單
export const getAllAbn = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllAbn`, {
    params: _params,
  });
};
// 異常 下拉選單
export const getAbnList = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAbnList`);
};
// 責任單位
export const getDirList = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDirList`);
};

export const report = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/report`, data);
};

export const resolve = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/resolve`, data);
};

// ----------------------------------------------------------------------------------

export const chooseWpcIdkw = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/chooseWpcIdkw`, {
    params: _params,
  });
};

export const getBatch = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBatch`, {
    params: _params,
  });
};

export const getSplitBatches = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSplitBatches`, {
    params: _params,
  });
};

export const createBatch = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/createBatch`, data);
};
export const splitBatch = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/splitBatch`, data);
};
export const mergeBatch = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/mergeBatch`, data);
};
export const getInOutBatches = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInOutBatches`, {
    params: _params,
  });
};

// -------------------------------委外下拉選單-------------------------------------
export const getOEM = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOEM`, {
    params: _params,
  });
};

export const updateOEM = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOEM`, data);
};

// -------------------------------批號-------------------------------------
export const feedBatch = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/feedBatch`, data);
};

// -------------------------------條碼批號-------------------------------------

export const createRun39 = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/createRun39`, data);
};
export const updateRun39 = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRun39`, data);
};
export const updateRun39PrintC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRun39PrintC`, data);
};

export const getRun39 = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRun39`, {
    params: _params,
  });
};
