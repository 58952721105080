import { Layout, message } from "antd";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

//get basicAPI
import useAPI from "@/hooks/useAPI";
import { initPage } from "@/service/api/publicAPI";
import webOutTime from "@/util/webTimeOut";

import StoreContext from "../Context/StoreContext";
import MyFooter from "./Footer";
import MyHeader from "./Header";
import MyNavbar from "./Navbar";

const { Content } = Layout;

// 找出多個 auth 中 為true的key
function filterObjects(arr) {
  const result = {};
  for (const item of arr) {
    for (const prop in item) {
      if (item[prop]) {
        result[prop] = true;
      }
    }
  }
  return result;
}

const MyLayout = (props) => {
  //navbar ctrl
  //open , close, shrinkClose
  const [navbarState, setNavbarState] = useState("close");

  const call_initPage = useAPI(initPage);

  const { storeData, setStoreData } = useContext(StoreContext);

  let navigate = useNavigate();

  useEffect(() => {
    call_initPage.request();
  }, []);

  // 特殊功能 判斷頁面如果出現mc/qc超時 需回到線頭看板
  useEffect(() => {
    let webTimeOut = setInterval(() => {
      webOutTime("wostk");
    }, 30000);
    return () => {
      clearInterval(webTimeOut);
    };
  }, []);

  useEffect(() => {
    if (call_initPage.status === "suc") {
      Cookies.set("userName", call_initPage.data.peopleNM, {
        expires: 365 * 99,
      });
      // 工站設定auth
      const wosAuth = JSON.parse(Cookies.get("wosAuth"));
      // user auth
      const userAuth = filterObjects(call_initPage.data.auth.map((x) => JSON.parse(x)));

      // 先取重複的部分
      const commonKeys = Object.keys(wosAuth).filter((key) => userAuth.hasOwnProperty(key));
      // 判斷用戶權限 與 工站權限是否都為true
      const auth = commonKeys.reduce((data, curr) => {
        wosAuth[curr] && userAuth[curr] && (data[curr] = true);
        return data;
      }, {});

      let getTheme = Cookies.get("theme") === "dark" ? "dark" : "light";

      const userData = {
        peopleNM: call_initPage.data.peopleNM,
      };
      console.log(auth);
      setStoreData({
        ...storeData,
        userData: userData,
        auth: auth,
        theme: getTheme,
      });
    } else if (call_initPage.status === "err") {
      message.error(call_initPage.msg);
      navigate("/wos/login");
    }
  }, [call_initPage.status]);
  console.log(storeData);
  return (
    <Layout className={props.className}>
      <MyNavbar
        navbarState={navbarState}
        setNavbarState={setNavbarState}
        auth={storeData.auth}
        className="navbar"
        storeData={storeData}
      />
      <MainLayout navbarstate={navbarState}>
        <MyHeader
          className="header"
          navbarState={navbarState}
          setNavbarState={setNavbarState}
          userData={storeData.userData}
        />
        <Content className="content">
          <Outlet />
        </Content>
        <MyFooter className="footer" />
      </MainLayout>
    </Layout>
  );
};

const MainLayout = styled(Layout)`
  transition: 0.3s;
  .header,
  .footer {
    transition: 0.3s;
    z-index: ${(props) => (props.navbarstate === "open" ? "0" : "99")};
    padding-left: ${(props) =>
      props.navbarstate === "open" ? "200px" : props.navbarstate === "close" ? "0px" : "80px"};
  }
  .navbar {
    z-index: 100;
  }
  .content {
    transition: 0.3s;
    /* +16px 表示原本寬度要計算 */
    padding-left: ${(props) =>
      props.navbarstate === "open" ? "216px" : props.navbarstate === "close" ? "0px" : "80px"};
    padding: 8px 16px 0 16px;
    min-height: calc(100vh - 76px - 45px);
  }
`;

export default MyLayout;
