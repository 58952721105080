import styled from "styled-components";

const LightElement = ({ className, type, size = "18px", disabled = false }) => {
  return (
    <span className={className}>
      <span
        className={`ant-badge-status-dot ${
          disabled ? "my-disabled" : "ant-badge-status-processing"
        }`}
      />
    </span>
  );
};

/**
 * @param {string} type "main , error , warning , success"
 * @param {string} size "default 18px , input any px"
 * @param {Bool} disabled - description
 */

const Light = styled(LightElement)`
  .ant-badge-status-dot {
    position: relative;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-color: ${(props) => {
      return props.disabled
        ? props.theme.disabledText
        : props.type === "main"
        ? props.theme.info
        : props.type === "error"
        ? props.theme.error
        : props.type === "warning"
        ? props.theme.warning
        : props.type === "success"
        ? props.theme.success
        : "#fff";
    }};
  }
  .ant-badge-status-processing {
    top: -1px;
    display: inline-block;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    vertical-align: middle;
    border-radius: 50%;
  }
  .ant-badge-status-processing:after {
    background-color: ${(props) => {
      return props.type === "main"
        ? props.theme.info
        : props.type === "error"
        ? props.theme.error
        : props.type === "warning"
        ? props.theme.warning
        : props.type === "success"
        ? props.theme.success
        : "#fff";
    }};
    border: ${(props) => {
      return `1px solid ${
        props.type === "main"
          ? props.theme.info
          : props.type === "error"
          ? props.theme.error
          : props.type === "warning"
          ? props.theme.warning
          : props.type === "success"
          ? props.theme.success
          : "#fff"
      }`;
    }};
  }
`;

export default Light;
