import { Layout, Menu, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import LogoImg from "@/assets/img/logo.png";
import useAPI from "@/hooks/useAPI";
import { logout } from "@/service/api/publicAPI";
import { BarsOutlined, ExportOutlined } from "@ant-design/icons";

import { IconProduction, IconQuality } from "../Icon";

const { Sider } = Layout;

const MyNavbarEle = (props) => {
  let location = useLocation();
  //當前收起狀態 true 為展開
  const [collapsedState, setCollapsedState] = useState(true);
  //collapsedWidth的寬度
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  //是否為shrink狀態
  const [isShrink, setIsShrink] = useState(false);

  const call_logout = useAPI(logout);
  const { t } = useTranslation();

  useEffect(() => {
    if (call_logout.status === "suc") {
      message.success("登出成功");
    } else if (call_logout.status === "err") {
      message.success("登出成功");
    }
  }, [call_logout.status]);

  useEffect(() => {
    //控制navbar伸縮
    if (props.navbarState === "shrinkClose" && !isShrink) {
      props.setNavbarState("close");
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
    if (props.navbarState === "shrinkClose") {
      return;
    } else if (props.navbarState === "open") {
      setCollapsedState(false);
      setCollapsedWidth(200);
    } else if (props.navbarState === "close" && isShrink) {
      props.setNavbarState("shrinkClose");
      setCollapsedState(true);
      setCollapsedWidth(80);
    } else {
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
  }, [props.navbarState, isShrink]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    console.log(props.storeData.userData.peopleNM);
  }, [props.storeData]);

  return (
    <Sider
      className={props.className}
      trigger={null}
      collapsible
      theme="light"
      width={300}
      collapsed={collapsedState}
      collapsedWidth={collapsedWidth} //只在collapsed = true 吃設定
      onClick={stop_propagation}
    >
      <div className="sider-logo-box">
        <img src={LogoImg} />
      </div>
      <div className="sider-menu-box">
        {/* 應付SPC單機版作出的特殊修改 */}
        {location.pathname !== "/spc" && (
          <Menu mode="inline" defaultSelectedKeys={location.pathname}>
            {/* {props?.auth?.dispachManage && (
              <Menu.Item key="/dispach-manage" icon={<IconQuality />}>
                <Link to="dispach-manage">派單管理</Link> 
              </Menu.Item>
            )} */}
            {/* 生產資訊 */}
            {/* <Menu.Item key="/pis" icon={<IconQuality />}>
              <Link to="pis">{t("WOS.util.pis")}</Link>
            </Menu.Item> */}
            {/* 生產控制 */}
            {props?.auth?.WOC && (
              <Menu.Item key="/WlWoc" icon={<IconProduction />}>
                <Link to="WlWoc">{t("WOS.util.wlwoc")}</Link>
              </Menu.Item>
            )}
            {/* {props?.auth?.WOC && (
              <Menu.Item key="/spcII" icon={<IconProduction />}>
                <Link to="spcII">{"統計製程管制"}</Link>
              </Menu.Item>
            )} */}
            {/* 物控作業
            {props?.auth?.MC && (
              <Menu.Item key="/mc" icon={<IconMaterial />}>
                <Link to="mc">{t("WOS.util.mc")}</Link>
              </Menu.Item>
            )} */}
            {/*質控作業*/}
            {/* {props?.auth?.QC && (
              <Menu.Item key="/qc" icon={<IconQuality />}>
                <Link to="qc">{t("WOS.util.qc")}</Link>
              </Menu.Item>
            )} */}

            {/*品質控制*/}
            {props.storeData.userData.peopleNM === "superman" && (
              <Menu.Item key="/new-qualityControl" icon={<IconQuality />}>
                <Link to="new-qualityControl">{t("WOS.util.qualityControl")}</Link>
              </Menu.Item>
            )}

            {/* {props.auth.partsBinding && (
              <Menu.Item key="/parts-binding" icon={<IconMaterial />} disabled>
                <Link to="parts-binding">部品掃碼</Link>
              </Menu.Item>
            )}
            {props?.auth?.ppidp && (
              <Menu.Item key="/ppidp" icon={<IconMaterial />} disabled>
                <Link to="ppidp">成品綁定</Link>
              </Menu.Item>
            )} */}

            {/* 工線派單
            {props?.auth?.dispachManage && (
              <Menu.Item key="/line-dispach-manage" icon={<IconQuality />}>
                <Link to="/line-dispach-manage">{t("WOS.util.line_dispach_manage")}</Link>
              </Menu.Item>
            )} */}

            {/* 生管派工 */}
            {/* {props?.auth?.WOC && (
              <Menu.Item key="/wpc-printer" icon={<IconQuality />}>
                <Link to="wpc-printer">{t("WOS.util.wpcPrinter")}</Link>
              </Menu.Item>
            )} */}
          </Menu>
        )}

        <Menu mode="inline" className="sub-menu">
          <Menu.Item key="changeNavbar" icon={<BarsOutlined />}>
            <Switch
              checked={isShrink}
              onChange={(e) => {
                setIsShrink(e);
              }}
            />
          </Menu.Item>
          <Menu.Item key="logout" icon={<ExportOutlined />} onClick={() => call_logout.request()}>
            {t("util.util.sign_out")}
            {/* 登出 */}
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

const MyNavbar = styled(MyNavbarEle)`
  box-shadow: 5px 0px 3px 2px rgba(124, 124, 124, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  z-index: 999;

  /*  */
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 1px solid ${({ theme }) => theme.componentBg};
  }
  .ant-menu-item-icon,
  .ant-menu-title-content {
    font-size: 19px;
    font-weight: 600;
  }
  .sider-logo-box {
    width: 100%;
    height: 100px;
    box-shadow: 0px 5px 3px 2px rgb(124 124 124 / 10%);
    padding: 16px 24px;
    background-color: ${({ theme }) => theme.componentBg};
  }
  .sider-logo-box img {
    width: 100%;
    display: block;
  }
  .sider-menu-box {
    display: flex;
    flex-flow: column nowrap;
    min-height: calc(100vh - 100px); //總高-圖片高度
  }
  .sub-menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
export default MyNavbar;
