import { Dropdown } from "antd";
import Cookies from "js-cookie";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IconTriangle } from "@/components/Icon/index";
import { formatAmount } from "@/util/format";

import { WlWocContext } from "../index";
import SipCard from "./SipCard";

export default function WoInfoArea({ thisWpc }) {
  const titleColor = "#7C79D2";
  const { t } = useTranslation();
  const { s_siptype, set_s_siptype } = useContext(WlWocContext);
  // 重新選擇 siptype
  const changeSiptype = (data) => {
    const { key } = data;
    // siptype要存在cookie
    Cookies.set("siptype", key, { expires: 365 * 99 });
    // 重新取得cookie要重新 reload, 所以做成 state
    set_s_siptype(key);
  };

  const siptype_options = [
    { label: "進料檢驗", key: "IQC", siptype_NM: "進料檢驗" },
    { label: "生產自檢", key: "PSI", siptype_NM: "生產自檢" },
    { label: "首件檢驗", key: "FAI", siptype_NM: "首件檢驗" },
    { label: "製中檢驗", key: "IPQC", siptype_NM: "製中檢驗" },
    { label: "過站檢驗", key: "SQC", siptype_NM: "過站檢驗" },
    { label: "終品檢驗", key: "FQC", siptype_NM: "終品檢驗" },
    { label: "出貨檢驗", key: "OQC", siptype_NM: "出貨檢驗" },
    { label: "儀校標準", key: "ICS", siptype_NM: "儀校標準" },
    // { label: "統計製程管制", key: "SPC", siptype_NM: "統計製程管制" },
  ];

  // UI 用來顯示的文字
  const siptype_NM = siptype_options.find((x) => x.key === s_siptype)?.siptype_NM || "";

  return (
    <div
      className={`
        flex w-full min-h-[250px] rounded-[10px] bg-white
        mt-1
        border-2 border-solid border-[#D9D9D9] border-t-[10px] border-t-[#7C79D2] 
      `}
      style={{ boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.15);" }}
    >
      <section className="flex-1">
        <h1 className="flex text-[26px] py-3 px-5 border-[#D9D9D9] bg-[#AAD6FE] border-b-2 border-r-2 border-solid">
          <h1 className="flex-1 flex-center border-[#D9D9D9] border-r-2 border-solid">
            {thisWpc?.wlNM}
          </h1>
          <h1 className="flex-1 flex-center">{thisWpc?.wsNM}</h1>
        </h1>
        <div className="flex flex-col flex-1 bg-blue-50 border-[#D9D9D9] border-r-2 border-solid p-2">
          <section className="flex">
            <div>
              <h4 className="text-lg text-[#868686]">工單號</h4>
              <h2 className="text-[24px] text-[#2364E0]">{thisWpc.woN}</h2>
            </div>
            <div className="ml-12">
              <h4 className="text-lg text-[#868686]">工站</h4>
              <div className="flex">
                <h2 className="text-[24px] text-[#2364E0]">{thisWpc.wsID}</h2>
              </div>
            </div>
          </section>
          <div className="flex">
            <div>
              <h4 className="text-lg text-[#868686]">料號</h4>
              <h2 className="text-[24px]">{thisWpc.PN}</h2>
            </div>
          </div>
          <div className="flex">
            <div>
              <h4 className="text-lg text-[#868686]">品名/規格</h4>
              <h2 className="text-[24px]">{thisWpc.pdtNM}</h2>
            </div>
          </div>
          <div className="flex">
            <div className="w-full">
              <h4 className="text-lg text-[#868686] invisible">機碼/車間</h4>
              <h2 className="text-[24px] bg-green-500">
                REAL : {formatAmount(thisWpc?.inC)} / Plan : {formatAmount(thisWpc?.planqty)} (PCS)
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="flex-1 pt-2 flex items-center flex-col">
        <div className="w-[90%] h-[125px]">
          <SipCard
            siptype={s_siptype}
            siptype_NM={siptype_NM}
            extra={
              <Dropdown
                menu={{ items: siptype_options, onClick: changeSiptype }}
                trigger={["click"]}
              >
                <IconTriangle className="text-white cursor-pointer text-[25px]" />
              </Dropdown>
            }
          />
        </div>
        <section className="self-start pl-7 pt-1">
          <div>
            <h4 className={`text-lg text-[#7C79D2]`}>檢驗單號</h4>
            <h2 className="text-[30px]">{thisWpc?.qoN}</h2>
          </div>
          <div className="flex">
            <div className="mr-8">
              <h4 className={` text-lg text-[#7C79D2]`}>檢驗標準</h4>
              <h2 className="text-[26px]">{thisWpc.sipID} </h2>
            </div>
            <div className="ml-3 text-center">
              <h4 className={` text-lg text-[#7C79D2]`}>版本</h4>
              <h2 className="text-[30px]">{thisWpc.version}</h2>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
