import { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Space,
  message,
  InputNumber,
  Select,
  Modal,
  Spin,
} from "antd";
import DisabledBox from "@/components/DisabledBox/index";
import Text from "@/components/Text/index";
import CustomModal from "@/components/Custom/CustomModal";
import styled from "styled-components";
import checkUndefined from "@/util/checkUndefined";
import Light from "@/components/Light/index";
import { CustomTable } from "@/components/Custom/AntdCustom";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { barcodeOperationWpc } from "@/service/api/wosAPI";
import { getWpcPwcID } from "@/service/api/publicAPI";
import { prepare, open, alexCarLogin, alexConfirm } from "@/service/api/wosAPI";
import { getMoldID } from "@/service/api/dispachManage";

import MC from "./ModalComponent/MC";

const CustomSearch = styled(Input.Search)`
  .ant-input-lg {
    font-size: 31px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
  }

  .ant-input {
    background-color: #eb8282 !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;

const initBarcodeObj = {
  woN: undefined,
  PN: undefined,
  wsID: undefined,
};

// 亞獵士特製版
export default function BarcodeInstruction({ setIsModal }) {
  const { t } = useTranslation();
  const ref_customSearch = useRef(null);

  const [s_enterText, set_s_enterText] = useState("");

  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );
  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  // show
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState(initBarcodeObj);
  //modol控制
  const [is_wpcLoginModal, set_is_wpcLoginModal] = useState(false);
  const [is_prepareToOpenModal, set_is_prepareToOpenModal] = useState(false);
  const [is_MC, set_is_MC] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_pageInfo, set_s_pageInfo] = useState({
    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const [s_searchData, set_s_searchData] = useState({
    page: 1, //第一頁
    pageSize: 100, //回傳筆數
    queryString: undefined,
  });

  const [s_chooseData, set_s_chooseData] = useState({
    qty: undefined, // 選擇的數量
    pwcID: undefined, // 選擇的PwcID
    mold: {}, //選擇的模具
  });

  const [is_prepareToOpenApiStatus, set_is_prepareToOpenApiStatus] = useState(false);
  const [is_openToCloseApiStatus, set_is_openToCloseApiStatus] = useState(false);

  const [s_thisWpc, set_s_thisWpc] = useState({});

  const call_barcodeOperationWpc = useAPI(barcodeOperationWpc); //table data
  const call_getWpcPwcID = useAPI(getWpcPwcID); // 呼叫getWpcPwcID API的自訂hook
  const call_alexCarLogin = useAPI(alexCarLogin); // 呼叫alexCarLogin API的自訂hook
  const call_getMoldID = useAPI(getMoldID); //取的模具下拉選單

  // barcodeInstruction 函式用於處理條碼指令
  const barcodeInstruction = () => {
    // 根據不同的 lifeF 值執行對應的函式
    const lifeFMap = {
      "0": () => set_is_wpcLoginModal(true),
      "1": () => set_is_prepareToOpenModal(true),
      "2": () => openToCloseOnSubmit(true),
      "4": () => openToCloseOnSubmit(true),
    };

    // 如果 s_thisWpc 存在且 lifeFMap 有對應的函式，則執行該函式
    if (s_thisWpc && lifeFMap[s_thisWpc.lifeF]) {
      lifeFMap[s_thisWpc.lifeF]();
    } else {
      // 否則顯示需要手動操作的錯誤訊息
      message.error(t("WOS.error.currentHistoryRequiresManualOperation"));
    }
  };

  // 途單登錄
  const wpcLogin = () => {
    if (!s_chooseData.qty || s_chooseData.qty < 0) {
      message.error(
        t("util.util.placeholder_input") + t("WOS.wlwoc.openqty")
        // "請輸入開單數量"
      );
      return;
    }
    // if (s_thisWpc.planqty + s_thisWpc.prefixplanqty - s_thisWpc.wpcC < s_chooseData.qty) {
    //   message.error("開單數量不能超過已開數量");
    //   return;
    // }
    if (!s_chooseData.pwcID || s_chooseData.pwcID === "") {
      message.error(
        t("util.util.please") + t("util.util.render") + t("WOS.util.pwcID")
        // "請選擇工作中心"
      );
      return;
    }
    // return;
    call_alexCarLogin.request([
      {
        ...s_thisWpc,
        ...s_chooseData,
        newBatchID: s_barcodeObj.batchID,
      },
    ]);
  };

  // prepareToOpenOnSubmit 函式用於生產準備到開始生產的操作
  const prepareToOpenOnSubmit = async () => {
    set_is_prepareToOpenApiStatus(true);

    // 如果未選擇模具，則顯示錯誤訊息並返回
    // if (!s_chooseData?.mold?.moldID) {
    //   message.error("請選擇模具");
    //   return;
    // }

    // 合併數據
    const postData = {
      ...s_thisWpc,
      ...s_chooseData.mold,
    };

    // 呼叫 報開工 函式
    const prepareResult = await prepare([postData]);
    if (!prepareResult.status) {
      message.error(prepareResult.msg);
      return;
    }

    // 呼叫 開始生產 函式
    const openResult = await open([postData]);
    if (!openResult.status) {
      message.error(openResult.msg);
      return;
    }

    call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
    set_s_thisWpc({});
    set_is_prepareToOpenModal(false);
    // 清除 mold 為下一次做準備
    set_s_chooseData((prev) => ({ ...prev, mold: {} }));
    message.success(openResult.msg);
    set_is_prepareToOpenApiStatus(false);
  };

  // openToCloseOnSubmit 函式用於從開始生產提交到報完工狀態
  const openToCloseOnSubmit = async () => {
    if (s_thisWpc.okC <= 0) {
      // message.error("請先進行物控登錄");
      message.error(t("WOS.error.mcLoginFirst"));
      return;
    }
    Modal.confirm({
      title: <Text level="h3">{t("WOS.util.lifeF_7") + t("util.util.ok")}</Text>,
      content: (
        <div>
          <Text level="h3">
            {t("WOS.util.checkWoNFirst")}
            <span style={{ color: "red" }}> {s_thisWpc.woN} </span>
            {t("WOS.util.batchID")}
            <span style={{ color: "red" }}> {s_thisWpc.batchID} </span>、
          </Text>
          <Text level="h3">
            {t("WOS.util.okC")}
            <span style={{ color: "red" }}> {s_thisWpc.okC} </span>
            {t("WOS.util.ngC")}
            <span style={{ color: "red" }}> {s_thisWpc.ngC} </span>，{t("WOS.util.verified")}，
            {t("WOS.util.after")}
            <span style={{ color: "red" }}> {t("WOS.util.lifeF_7")} </span>。
          </Text>
        </div>
      ),
      okText: t("util.util.ok"),
      okType: "primary",
      cancelText: t("util.util.cancel"),
      centered: true,
      width: "800px",
      onOk: async () => {
        set_is_openToCloseApiStatus(true);

        const closeResult = await alexConfirm(s_thisWpc);
        console.log("@@@", closeResult);
        if (!closeResult.status) {
          message.error(closeResult.msg);
          return;
        }
        call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
        set_s_thisWpc({});
        message.success(closeResult.msg);
        set_is_openToCloseApiStatus(false);
      },
    });
  };

  // 副作用：初始化時自動聚焦輸入框和呼叫 getMoldID 函式
  useEffect(() => {
    ref_customSearch.current.focus();
  }, []);

  useEffect(() => {
    call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (is_prepareToOpenModal) call_getMoldID.request(s_thisWpc);
  }, [is_prepareToOpenModal]);

  useEffect(() => {
    if (call_barcodeOperationWpc.status === "suc") {
      set_s_pageInfo((prev) => ({ ...prev, ...call_barcodeOperationWpc.data.pageInfo }));

      // const filterBatch = call_barcodeOperationWpc.data.tableData?.filter(
      //   (x) => x.batchID === s_barcodeObj.batchID || x.batchID === ""
      // );
      const filterBatch = call_barcodeOperationWpc.data.tableData;

      if (filterBatch.length === 0) {
        set_s_tableData([]);
        set_s_pageInfo({
          totalItems: 0,
          currentPage: 0,
          pageItemNumber: 0,
          startItemNumber: 0,
          endItemNumber: 0,
        });
        message.error(t("WOS.wlwoc.noData"));
      } else {
        set_s_tableData(
          filterBatch.map((x) => {
            return {
              ...x,
              key: x.UUID,
              linemixStr:
                x.linemix === 1
                  ? t("WOS.util.linemix_1")
                  : x.linemix === 2
                  ? t("WOS.util.linemix_2")
                  : t("WOS.util.linemix_0"),
              wotypeStr:
                x.wotype === 0
                  ? t("WOS.util.wotype_0")
                  : x.wotype === 1
                  ? t("WOS.util.wotype_1")
                  : t("WOS.wlwoc.nodata"),
              lifeFstr: t(`WOS.util.lifeF_${x.lifeF}`),
            };
          })
        );
        message.success(call_barcodeOperationWpc.msg);
      }
    } else if (call_barcodeOperationWpc.status === "err") {
      // 只要失敗就清空途單列表
      set_s_tableData([]);
      set_s_pageInfo({
        totalItems: 0,
        currentPage: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        endItemNumber: 0,
      });
      message.error(call_barcodeOperationWpc.msg);
    }
  }, [call_barcodeOperationWpc.status]);

  useEffect(() => {
    if (is_wpcLoginModal) call_getWpcPwcID.request(s_thisWpc);
  }, [is_wpcLoginModal]);

  // 這邊的主要是對條碼進行解析和處理
  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
        ${t("WOS.wlwoc.noWoNScan")}
        ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }
      console.log(parseBarcode);
      set_s_chooseData((prev) => ({ ...prev, qty: parseBarcode.batchbom }));

      call_barcodeOperationWpc.request({ ...s_searchData, ...parseBarcode });
      // 設定 s_alertMsg 為 s_showBarcode 的值
      set_s_alertMsg(
        `${t("util.util.woN")}：${parseBarcode.woN ?? t("WOS.util.none")}
          ，${t("util.util.PN")}：${parseBarcode.PN ?? t("WOS.util.none")}，${t(
          "WOS.util.batchID"
        )}：${
          parseBarcode.batchID ?? t("WOS.util.none")
          //無
        }，
        ${t("WOS.util.batchbom")}
        ：${parseBarcode.batchbom ?? t("WOS.util.none")}`
      );

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
      ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      set_s_tableData([]);
    }
  }, [is_barcodeInput]);

  useEffect(() => {
    if (call_getWpcPwcID.status === "suc") {
      set_s_chooseData((prev) => {
        prev.pwcID =
          call_getWpcPwcID?.data.length === 1 ? `${call_getWpcPwcID?.data[0].pwcID}` : undefined; // 如果只有一筆資料，將pwcID設定為"pwcID"，否則為undefined
        return { ...prev };
      });
    }
    if (call_getWpcPwcID.status === "err") {
      // message.error(call_getWpcPwcID.msg); // 顯示錯誤訊息
    }
  }, [call_getWpcPwcID.status]);

  useEffect(() => {
    if (call_alexCarLogin.status === "suc") {
      set_is_wpcLoginModal(false);
    } else if (call_alexCarLogin.status === "err") {
      message.error(call_alexCarLogin.msg); // 顯示錯誤訊息
    }
  }, [call_alexCarLogin.status]);

  const tableColumns = [
    {
      title: t("util.util.situF"), //狀態
      dataIndex: "situF",
      width: "60px",
      align: "center",
      render: (str) => {
        switch (str) {
          case "B":
            return <Light size="20px" type="main" />;
          case "G":
            return <Light size="20px" type="success" />;
          case "Y":
            return <Light size="20px" type="warning" />;
          case "R":
            return <Light size="20px" type="error" />;
          default:
            return <Light size="20px" disabled />;
        }
      },
    },
    {
      title: t("util.util.woN"), //工單號
      dataIndex: "woN",
      width: "280px",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("WOS.util.batchID"), //批號
      dataIndex: "batchID",
      width: "230px",
    },
    {
      title: t("util.util.lifeF"), //歷程,
      dataIndex: "lifeFstr",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.PN"), //料號,
      dataIndex: "PN",
      width: "260px",
      align: "center",
      className: "row-min-width col-white-space",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.pdtNM"), //品名,
      dataIndex: "pdtNM",
      width: "280px",
      className: "row-min-width col-white-space",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      align: "right",
      render: (str) => checkUndefined(str),
    },

    {
      title: t("util.util.okC"), //良品數量,
      dataIndex: "okC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.ngC"), //不良數量,
      dataIndex: "ngC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
  ];

  return (
    <DisabledBox>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Alert
            message={
              <Text level="h3">
                {
                  t("WOS.util.focusText") //請掃入條碼
                }
              </Text>
            }
            description={
              <Text
                level="h3"
                type="error"
                style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
              >
                {s_alertMsg}
              </Text>
            }
            type="info"
          />
        </Col>
        <Col span={24}>
          <CustomSearch
            ref={ref_customSearch}
            autoFocus
            enterButton={t("WOS.util.focusText")} //請掃入條碼
            size="large"
            placeholder={s_focusText}
            value={s_enterText}
            onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
            onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
            onChange={(e) => {
              set_s_enterText(e.target.value);
            }}
            onSearch={() => {
              set_s_enterText("");
              set_s_showBarcode(s_enterText);
              set_is_barcodeInput(true);
            }}
          />
        </Col>
        <Col span={24}>
          <CustomTable
            columns={tableColumns}
            dataSource={s_tableData}
            size="small"
            loading={call_barcodeOperationWpc.status === "load"}
            scroll={{ y: 185 }}
            rowClassName={(record, index) =>
              record.UUID === s_thisWpc?.UUID
                ? "clickRowStyle"
                : index % 2 === 1 //隔行變色
                ? "once"
                : "twoe"
            }
            onRow={(record) => {
              return {
                onClick: () =>
                  record.UUID === s_thisWpc?.UUID ? set_s_thisWpc({}) : set_s_thisWpc(record),
              };
            }}
            pagination={{
              total: s_pageInfo.totalItems,
              current: s_searchData.page,
              pageSize: s_searchData.pageSize,
              onChange: (current, size) =>
                set_s_searchData((prev) => {
                  // 如果有更動pageSize 回第一頁
                  let checkPage = prev.pageSize !== size ? 1 : current;
                  return { ...prev, page: checkPage, pageSize: size };
                }),
              showTotal: (total, range) => (
                <Text level="h4" vice>
                  {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                  {t("util.util.showTotal", {
                    total: total,
                    rangeFirst: range[0],
                    rangeEnd: range[1],
                  })}
                </Text>
              ),
            }}
          />
        </Col>
        <Col span={24} className="text-right">
          <Space size={20}>
            <Button
              size="large"
              onClick={() => set_is_MC(true)}
              danger
              disabled={
                Object.keys(s_thisWpc).length === 0 ||
                (s_thisWpc.lifeF !== "2" && s_thisWpc.lifeF !== "4")
              }
            >
              {/* 物控登錄 */}
              {t("WOS.wlwoc.mc_login")}
            </Button>
            <Button size="large" onClick={() => setIsModal(false)}>
              {t("util.util.cancel")}
              {/* 取消 */}
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={Object.keys(s_thisWpc).length === 0}
              onClick={barcodeInstruction}
              loading={is_openToCloseApiStatus}
            >
              {t("util.util.ok")}
              {/* 確認 */}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* 工單進行登錄 */}
      <CustomModal
        key="prepare"
        title={
          <Text level="h3">
            {t("WOS.wlwoc.isRegisterTheWoN")}
            {/* 是否對該筆工單進行登錄？ */}
          </Text>
        }
        visible={is_wpcLoginModal}
        width="600px"
        onCancel={() => set_is_wpcLoginModal(false)}
        footer={null}
        afterClose={() => {
          set_s_thisWpc({});
          call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
        }}
      >
        <Spin spinning={call_alexCarLogin.status === "load"}>
          <Text level="h3">
            {t("WOS.util.batchID") + ":"}
            {/* 批號： */}
            <span style={{ color: "red" }}>{checkUndefined(s_barcodeObj.batchID)}</span>
          </Text>
          <Space size={0}>
            <Text level="h3" className="inline">
              {t("WOS.wlwoc.openqty") + ":"}
              {/* 開單數量： */}
            </Text>
            <InputNumber
              size="large"
              style={{ margin: "5px 0px", width: "300px" }}
              placeholder={t("WOS.wlwoc.chooseDataQty") + ":"}
              value={s_chooseData.qty}
              onChange={(e) => set_s_chooseData((prev) => ({ ...prev, qty: e }))}
            />
          </Space>
          <Space size={0} className="pb-6">
            <Text level="h3" className="inline">
              {t("WOS.util.pwcID")}:{/* 工作中心： */}
            </Text>
            <Select
              showSearch
              allowClear
              placeholder={t("WOS.util.pwcID")}
              // "工作中心"
              filterOption={(inputValue, option) =>
                (option.label + option.value).includes(inputValue)
              }
              size="large"
              style={{ margin: "5px 0px", width: "300px" }}
              options={
                call_getWpcPwcID?.data?.map((x) => ({
                  label: `${x.pwcID}_${x.pwcNM}`,
                  value: x.pwcID,
                })) || []
              }
              value={s_chooseData.pwcID}
              onChange={(e) => set_s_chooseData((prev) => ({ ...prev, pwcID: e }))}
            />
          </Space>
          <Space block className="ant-modal-footer w-full flex justify-end">
            <Button size="large" onClick={() => set_is_prepareToOpenModal(false)}>
              {t("util.util.cancel")}
              {/* 取消 */}
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={wpcLogin}
              loading={call_alexCarLogin.status === "load"}
            >
              {t("util.util.ok")}
              {/* 確認 */}
            </Button>
          </Space>
        </Spin>
      </CustomModal>
      {/* 開始生產確認 */}
      <CustomModal
        key="prepareToOpen"
        title={
          <Text level="h3">
            {t("WOS.util.lifeF_2") + t("util.util.ok")}
            {/* 開始生產確認 */}
          </Text>
        }
        visible={is_prepareToOpenModal}
        width="600px"
        onCancel={() => set_is_prepareToOpenModal(false)}
        footer={null}
        afterClose={() => {
          set_is_prepareToOpenModal(false);
          set_s_thisWpc({});
          call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
        }}
      >
        <Spin spinning={is_prepareToOpenApiStatus}>
          <Text level="h3">
            {t("util.util.woN") + ":"}
            {/* 工單號 */}
            <span style={{ color: "red" }}> {s_thisWpc.woN}</span>
          </Text>
          <Text level="h3">
            {t("WOS.util.batchID") + ":"}
            {/* 批號 */}
            <span style={{ color: "red" }}> {s_barcodeObj.batchID}</span>
          </Text>

          <Text level="h3">
            {t("WOS.util.planqty") + ":"}
            {/* 計畫產量 */}
            <span style={{ color: "red" }}> {s_thisWpc.planqty}</span>
          </Text>
          <Space size={0} className="pb-6">
            <Text level="h3" className="inline">
              {t("util.util.mold")}:{/* 模具 */}
            </Text>
            <Select
              showSearch
              allowClear
              filterOption={(inputValue, option) =>
                (option.label + option.value).includes(inputValue)
              }
              size="large"
              style={{ margin: "5px 0px", width: "300px" }}
              options={
                call_getMoldID?.data?.map((x) => ({
                  label: `${x.moldID}_${x.moldNM}`,
                  value: x.moldID,
                  data: x,
                })) || []
              }
              value={s_chooseData?.mold?.moldID}
              onChange={(_, data) =>
                set_s_chooseData((prev) => ({ ...prev, mold: data?.data ?? {} }))
              }
            />
          </Space>
          <Space block className="ant-modal-footer w-full flex justify-end">
            <Button size="large" onClick={() => set_is_prepareToOpenModal(false)}>
              {t("util.util.cancel")}
              {/* 取消 */}
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={prepareToOpenOnSubmit}
              loading={is_prepareToOpenApiStatus}
            >
              {t("util.util.ok")}
              {/* 確認 */}
            </Button>
          </Space>
        </Spin>
      </CustomModal>
      {/* 物控作業 */}
      <CustomModal
        key="is_MC"
        title={t("WOS.util.mc")} // 物控作業
        visible={is_MC}
        onCancel={() => set_is_MC(false)}
        footer={null}
        width="50%"
        afterClose={() => {
          set_s_thisWpc({});
          call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj });
        }}
      >
        <MC
          setIsModal={set_is_MC}
          thisWpc={s_thisWpc}
          call_getAllWpc={() =>
            call_barcodeOperationWpc.request({ ...s_searchData, ...s_barcodeObj })
          }
        />
      </CustomModal>
    </DisabledBox>
  );
}
