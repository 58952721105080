import { Layout, PageHeader, Select } from "antd";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { MenuFoldOutlined } from "@ant-design/icons";

import SystemContext from "../Context/StoreContext";
import Text from "../Text/index";
import ThemeButton from "../ThemeButton";
import Countrys from "./Countrys";

const { Header } = Layout;

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    width: 1px;
    background-color: #d9d9d9;
  }
`;

const MyHeaderEle = (props) => {
  const { Option } = Select;

  const [thisWl, setThisWl] = useState("");

  const [isWlSelectdisabled, setIsWlSelectdisabled] = useState(false);

  const [wlOptons, setWlOptions] = useState([]);

  const [thisWs, setThisWs] = useState("");

  const [isWsSelectdisabled, setIsWsSelectdisabled] = useState(false);

  const [wsOptons, setWsOptions] = useState([]);

  const { c_systemConfig, set_c_systemConfig, c_userData } = useContext(SystemContext);
  // const [thislinemix, setThislinemix] = useState(Cookies.get("linemix"));

  useEffect(() => {
    setThisWs(Cookies.get("wsID"));
    setThisWl(Cookies.get("wlID"));

    const allWsWl = JSON.parse(localStorage.getItem("allWsWl")) || [];

    const thisWsObj = allWsWl?.find((item) => item.wsID.wsID === Cookies.get("wsID"));

    console.log(thisWsObj);

    setWsOptions(
      allWsWl.map((x) => (
        <Option key={x.wsID.wsID} value={x.wsID.wsID} data={x}>
          {x.wsID.wsID}－{x.wsID.wsNM}
        </Option>
      ))
    );

    setWlOptions(
      thisWsObj?.wlIDs
        ? thisWsObj.wlIDs.map((x) => (
            <Option key={x.wlID} value={x.wlID} data={x}>
              {x.wlID}－{x.wlNM}
            </Option>
          ))
        : []
    );

    setIsWlSelectdisabled(thisWsObj?.wlIDs && thisWsObj.wlIDs.length > 1 ? false : true);
    setIsWsSelectdisabled(allWsWl.length > 1 ? false : true);
  }, []);

  const change_sider_state = (e) => {
    stop_propagation(e);
    if (props.navbarState === "close" || props.navbarState === "shrinkClose") {
      props.setNavbarState("open");
    } else {
      props.setNavbarState("close");
    }
  };
  const handlerSystemConfigChange = (type, value) => {
    console.log({ type, value });
    // return;
    set_c_systemConfig((prev) => {
      prev[type] = value;
      return { ...prev };
    });
  };

  const stop_propagation = (e) => {
    e?.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const closeSider = () => {
      props.setNavbarState("close");
    };

    if (props.navbarState === "open") {
      window.addEventListener("click", closeSider);
    }

    return () => {
      window.removeEventListener("click", closeSider);
    };
  }, [change_sider_state]);

  return (
    <Header className={props.className}>
      <PageHeader
        onBack={change_sider_state}
        backIcon={<MenuFoldOutlined className="header-icon-size" />}
        className="page-header"
        title={<Text level="h2">生產執行系統MES</Text>}
        extra={[
          <Text level="h3" key="userName">
            {props.userData.peopleNM}
          </Text>,
          <Line key="line3" />,
          <Text level="h3" key="num">
            V 1.6.9
          </Text>,
          <Line key="line8" />,
          <Text level="h3" key="plant">
            {Cookies.get("plantNM")}
          </Text>,
          <Line key="line1" />,
          <ThemeButton key="theme-button" />,
          <Line key="line4" />,
          <Countrys
            key="Countrys"
            locale={c_systemConfig.locale}
            handlerSystemConfigChange={handlerSystemConfigChange}
          />,
        ]}
      />
    </Header>
  );
};

const MyHeader = styled(MyHeaderEle)`
  box-shadow: 0px 5px 3px 2px rgba(124, 124, 124, 0.1);
  background-color: #ffffff;
  height: 80px;
  line-height: 80px;
  padding: 0;
  .page-header {
    padding: 8px 24px;
  }
  .header-icon-size {
    font-size: 2rem;
  }
  .ant-page-header-heading-extra {
    display: flex;
    align-items: center;
  }
`;

export default MyHeader;
