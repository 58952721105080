import styled from "styled-components";

const DisabledBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    content: "";
    /* background-color: ${({ theme }) => theme.borderColor}; */
    background: transparent;
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    text-align: center;
    content: "";
  }
`;

export default DisabledBox;
