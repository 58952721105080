import { Button, Tabs } from "antd";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Text from "@/components/Text";
import { WlWocContext } from "@/pages/NewQualityControl/index";
import { getRunTb } from "@/service/api/qcAPI";
import { AlignLeftOutlined, FullscreenOutlined, UndoOutlined } from "@ant-design/icons";
import { ExclamationOutlined } from "@ant-design/icons";

// import ModuleAreaTable from "./ModuleAreaTable";
import ModuleAreaTable from "./Tableindex";

const { TabPane } = Tabs;

const ModuleAreaStyle = styled.div`
  cursor: ${({ s_isInspection }) => `${s_isInspection ? "default" : "not-allowed"}`};
  opacity: ${({ s_isInspection }) => `${s_isInspection ? 1 : 0.7}`};
  height: 375px;
  padding: 20px;
  .ant-tabs-content,
  .ant-tabs-tabpane {
  }
`;

export default function ModuleArea({ thisWpc, processManagementAuth, call_getAllWpc }) {
  // 工站設定auth
  const wosAuth = Cookies.get("wosAuth") ? JSON.parse(Cookies.get("wosAuth")) : {};

  const { t } = useTranslation();

  return (
    <ModuleAreaStyle
      s_isInspection={thisWpc.lifeF === "2"}
      className={`flex justify-center w-full ${thisWpc.lifeF === "2" ? "" : "disabled"}   `}
    >
      <ModuleAreaTable
        flag={"HD"}
        // className={"w-full"}
        // thisWpc={thisWpc}
        // setThisWpc={setThisWpc}
        // setCall_getAllWpc={setCall_getAllWpc}
        // wpcType="qc"
      />
    </ModuleAreaStyle>
  );
}
