import { Tabs } from "antd";
import styled from "styled-components";
import { fontSize } from "@/styles/BasicSetting";

const CustomTabs = styled(Tabs)`
  padding: 8px 1px 14px 1px;
  .ant-tabs-tab {
    padding: 8px 16px 0px 16px !important;
  }
  .ant-tabs-tab-btn {
    font-size: ${fontSize.h3};
    font-weight: 600;
  }
`;

export default CustomTabs;
