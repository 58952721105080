import { useState, useEffect } from "react";
import { Form, Row, Col, Divider, Space, Button, message, Input, InputNumber, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import Text from "@/components/Text/index";
import useAPI from "@/hooks/useAPI";
import { splitBatch, getSplitBatches } from "@/service/api/wosAPI";
import { useTranslation } from "react-i18next";
export default function Create({ set_s_showModal, thisWpc }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_splitBatch = useAPI(splitBatch);
  const call_getSplitBatches = useAPI(getSplitBatches);

  const onSubmit = async (values) => {
    call_splitBatch.request(values);
  };

  const formListAuth = {
    validator: async (_, formTbData) => {
      // 驗證規則
      const reC = form.getFieldValue("reC");
      const total = formTbData.reduce((data, item) => item.bulkqty && data + item.bulkqty, 0);
      console.log(formTbData);
      if (!formTbData || formTbData.length === 0) {
        return Promise.reject(new Error(t("WOS.error.addAtLeastOne"))); //"請至少新增一筆資料"
      }
      if (total > reC) {
        return Promise.reject(new Error(t("WOS.error.batchQtyExceedRemainingQty"))); //"批號數量不得大於剩餘可用數量"
      }
      return Promise.resolve();
    },
  };

  useEffect(() => {
    call_getSplitBatches.request();
  }, []);

  useEffect(() => {
    if (call_splitBatch.status === "suc") {
      message.success(call_splitBatch.msg);
      set_s_showModal(false);
    } else if (call_splitBatch.status === "err") {
      message.error(call_splitBatch.msg);
    }
  }, [call_splitBatch.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ batches: [] }}>
      <Form.Item name="bdmbatchbom" noStyle />
      <Form.Item name="UUID" noStyle />
      <Form.Item name="woN" noStyle />

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item
            label={t("WOS.wlwoc.batchID")}
            name="batchID"
            shouldUpdate
            rules={[{ require: true }]}
          >
            {/*"原批號"*/}
            <Select
              className="w-100"
              showSearch
              options={call_getSplitBatches.data?.map((x) => ({
                // label: "【批號】" + x.batchID + "，【工單號】" + x.woN + "，【可用數量】" + x.qty, // qty 可用數量
                label:
                  `【${t("util.util.batchno")}】` +
                  x.batchID +
                  `，【${t("util.util.woN")}】` +
                  x.woN +
                  `，【${t("WOS.util.available_qty")}】` +
                  x.qty, // qty 可用數量
                value: x.batchID,
                data: x,
              }))}
              onChange={(_, data) =>
                form.setFieldsValue({
                  inC: data.data.inC,
                  reC: data.data.qty,
                  bdmbatchbom: data.data.bdmbatchbom,
                  UUID: data.data.UUID,
                  woN: data.data.woN,
                  batches: [],
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("WOS.util.inC")} name="inC">
            {/*投產數量*/}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("WOS.util.remaining") + t("WOS.util.available_qty")} name="reC">
            {/*剩餘可用數量*/}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("WOS.wlwoc.bdmbatchbom")} name="bdmbatchbom">
            {/*"滿包數量" */}
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider className="m-0 p-0" />
        </Col>
        <Col span={24}>
          <Form.Item noStyle shouldUpdate>
            {({}) => {
              const getFormData = form.getFieldsValue();
              return (
                getFormData.batchID && (
                  <Form.List name="batches" shouldUpdate rules={[formListAuth]}>
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {!!fields.length && (
                          <Row align="middle">
                            <Col span={2}>
                              <Text level="h5">
                                {t("util.util.SN")}
                                {/* 序 */}
                              </Text>
                            </Col>
                            <Col span={10}>
                              <Text level="h5">
                                {/* 批號 */}
                                {t("util.util.batchno")}
                              </Text>
                            </Col>
                            <Col span={10}>
                              <Text level="h5">
                                {t("util.util.batchno") + t("WOS.util.qty")}
                                {/* 批號數量 */}
                              </Text>
                            </Col>
                          </Row>
                        )}
                        {fields.map((field, index) => (
                          <Row key={field.key} gutter={[12, 0]}>
                            <Form.Item noStyle {...field} name={[field.name, "bdmbatchbom"]} />
                            <Col span={2}>
                              <Text level="h5" style={{ marginLeft: 4 }}>
                                {index + 1}
                              </Text>
                            </Col>
                            <Col span={10}>
                              <Form.Item {...field} name={[field.name, "batchID"]}>
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item
                                {...field}
                                name={[field.name, "bulkqty"]}
                                rules={[
                                  {
                                    validator: async (_, value) => {
                                      const formData = form.getFieldsValue();
                                      if (value === 0) {
                                        return Promise.reject(
                                          new Error(t("WOS.error.batchnoQtyLessZero"))
                                        ); //"批號數量不得為0"
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  },
                                ]}
                              >
                                <InputNumber className="w-100" min={1} />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <MinusCircleOutlined
                                style={{ fontSize: "1.3rem", marginTop: 5 }}
                                onClick={() => remove(field.name)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <div className="flex justify-center items-center">
                          <Form.ErrorList errors={[errors]} />
                        </div>
                        <Form.Item>
                          <Button
                            size="large"
                            type="primary"
                            onClick={() =>
                              add({
                                bdmbatchbom: getFormData.bdmbatchbom,
                                bulkqty: 0,
                              })
                            }
                          >
                            {t("util.util.add")}
                            {/* 新增 */}
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24} className="ant-modal-footer">
          <Button size="large" onClick={() => set_s_showModal(false)}>
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button type="primary" size="large" htmlType="submit">
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
