import { CloseCircleOutlined, CheckCircleFilled } from "@ant-design/icons";

import { ReactComponent as Press } from "@/assets/icon/icon-press.svg";
import { ReactComponent as Sun } from "@/assets/icon/icon-sun.svg";
import { ReactComponent as Moon } from "@/assets/icon/icon-moon.svg";
import { ReactComponent as Arrow } from "@/assets/icon/icon-arrow.svg";
import { ReactComponent as MoldTime } from "@/assets/icon/icon-moldTime.svg";
import { ReactComponent as ProductionTime } from "@/assets/icon/icon-productionTime.svg";
import { ReactComponent as ProductionStartTime } from "@/assets/icon/icon-productionStartTime.svg";
import { ReactComponent as ProductionPrepareTime } from "@/assets/icon/icon-productionPrepareTime.svg";
import { ReactComponent as ProductionAchievementRate } from "@/assets/icon/icon-productionAchievementRate.svg";
import { ReactComponent as TripDetails } from "@/assets/icon/icon-tripDetails.svg";
import { ReactComponent as Triangle } from "@/assets/icon/icon-triangle.svg";
import { ReactComponent as ScanBarcode } from "@/assets/icon/icon-scanBarcode.svg";

import { ReactComponent as AlertWarning } from "@/assets/icon/alertwarning.svg";

import { ReactComponent as Production } from "@/assets/icon/icon-productionControl.svg";
import { ReactComponent as Material } from "@/assets/icon/icon-materialControl.svg";
import { ReactComponent as Quality } from "@/assets/icon/icon-qualityControl.svg";

import { ReactComponent as GoodProduct } from "@/assets/icon/icon-goodProduct.svg";
import { ReactComponent as NoGoodProduct } from "@/assets/icon/icon-noGoodProduct.svg";
import { ReactComponent as Printer } from "@/assets/icon/icon-labelPrinting.svg";
import { ReactComponent as Setting } from "@/assets/icon/setting.svg";

import { ReactComponent as Won } from "@/assets/icon/icon-won.svg";
import { ReactComponent as Pn } from "@/assets/icon/icon-pn.svg";
import { ReactComponent as PdtNM } from "@/assets/icon/icon-pdtnm.svg";
import { ReactComponent as PlanQty } from "@/assets/icon/icon-planQty.svg";
import { ReactComponent as OpenT } from "@/assets/icon/icon-openT.svg";
import { ReactComponent as Mhc } from "@/assets/icon/icon-mhc.svg";
import { ReactComponent as NgC } from "@/assets/icon/icon-ngc.svg";
import { ReactComponent as Okc } from "@/assets/icon/icon-okc.svg";
import { ReactComponent as Act } from "@/assets/icon/icon-act.svg";
import { ReactComponent as Ppe } from "@/assets/icon/icon-ppe.svg";
import { ReactComponent as Tar } from "@/assets/icon/icon-tar.svg";
import { ReactComponent as Qty } from "@/assets/icon/icon-qty.svg";
import { ReactComponent as No } from "@/assets/icon/NO.svg";
import { ReactComponent as Station } from "@/assets/icon/icon-station.svg";
import { ReactComponent as Scanning } from "@/assets/icon/icon-scanning.svg";
import { ReactComponent as TransparentBackground } from "@/assets/icon/TransparentBackground.svg";
import { ReactComponent as Confirm } from "@/assets/icon/icon-confirm.svg";
import { ReactComponent as addQrcode } from "@/assets/icon/icon-addQrcode.svg";

//參照antd icon 結構
// interface iconPropsType {
//   className?: string
//   onClick?: React.MouseEventHandler<HTMLSpanElement>
//   Icon?: any
//   style?: CSS.Properties
// }

const IconBox = (props) => {
  return (
    <span
      role="img"
      aria-label="icon-press"
      className={`anticon ${props.className}`}
      onClick={props.onClick}
      style={props.style}
    >
      {<props.Icon style={{ color: props.style?.color }} />}
    </span>
  );
};
export const IconTransparentBackground = (props) => (
  <IconBox {...props} Icon={<TransparentBackground />} />
);
export const IconConfirm = (props) => <IconBox component={Confirm} {...props} />;
export const IconScanning = (props) => <IconBox {...props} Icon={Scanning} />;

export const IconStation = (props) => <IconBox {...props} Icon={Station} />;

export const IconTriangle = (props) => <IconBox {...props} Icon={Triangle} />;
export const IconSetting = (props) => <IconBox {...props} Icon={Setting} />;

export const IconPress = (props) => <IconBox {...props} Icon={Press} />;

export const IconSun = (props) => <IconBox {...props} Icon={Sun} />;

export const IconMoon = (props) => <IconBox {...props} Icon={Moon} />;

export const IconArrow = (props) => <IconBox {...props} Icon={Arrow} />;

export const IconPrinter = (props) => <IconBox {...props} Icon={Printer} />;
export const IconAlertWarning = (props) => <IconBox {...props} Icon={AlertWarning} />;

// WpcCodeInfo Component
export const IconProductionTime = (props) => <IconBox {...props} Icon={ProductionTime} />;

export const IconProductionPrepareTime = (props) => (
  <IconBox {...props} Icon={ProductionPrepareTime} />
);

export const IconProductionStartTime = (props) => <IconBox {...props} Icon={ProductionStartTime} />;

export const IconMoldTime = (props) => <IconBox {...props} Icon={MoldTime} />;

export const IconProductionAchievementRate = (props) => (
  <IconBox {...props} Icon={ProductionAchievementRate} />
);
export const IconScanBarcode = (props) => <IconBox {...props} Icon={ScanBarcode} />;

export const IconTripDetails = (props) => <IconBox {...props} Icon={TripDetails} />;

export const IconProduction = (props) => <IconBox {...props} Icon={Production} />;
export const IconMaterial = (props) => <IconBox {...props} Icon={Material} />;
export const IconQuality = (props) => <IconBox {...props} Icon={Quality} />;

export const IconGoodProduct = (props) => <IconBox {...props} Icon={GoodProduct} />;
export const IconNoGoodProduct = (props) => <IconBox {...props} Icon={NoGoodProduct} />;

export const IconWon = (props) => <IconBox {...props} Icon={Won} />;

export const IconPn = (props) => <IconBox {...props} Icon={Pn} />;

export const IconPdtNM = (props) => <IconBox {...props} Icon={PdtNM} />;

export const IconPlanQty = (props) => <IconBox {...props} Icon={PlanQty} />;

export const IconOpenT = (props) => <IconBox {...props} Icon={OpenT} />;

export const IconMhc = (props) => <IconBox {...props} Icon={Mhc} />;

export const IconNgC = (props) => <IconBox {...props} Icon={NgC} />;

export const IconOkc = (props) => <IconBox {...props} Icon={Okc} />;

export const IconAct = (props) => <IconBox {...props} Icon={Act} />;

export const IconPpe = (props) => <IconBox {...props} Icon={Ppe} />;

export const IconQty = (props) => <IconBox {...props} Icon={Qty} />;

export const IconTar = (props) => <IconBox {...props} Icon={Tar} />;

export const IconAddQrcode = (props) => <IconBox {...props} Icon={addQrcode} />;
// 紅色叉叉
export const IconClose = (props) => <IconBox component={CloseCircleOutlined} {...props} />;
// 藍色勾勾
export const IconCheck = (props) => (
  <IconBox component={CheckCircleFilled} className="text-[#71aff8]" {...props} />
);
export const IconNo = (props) => <IconBox Icon={No} {...props} />;
