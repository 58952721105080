import { Table } from "antd";
import styled from "styled-components";
import { fontSize } from "@/styles/BasicSetting";

const CustomTableStyle = styled(Table)`
  /* table 表投靠中 */
  .ant-table-thead > tr > th {
    text-align: center !important;
  }

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    font-size: ${fontSize.h4};
    white-space: nowrap;
    font-weight: 600;
    padding: 4px;
  }
  .ant-table-cell {
    font-size: ${fontSize.h5};
    font-weight: 600;
  }

  /* // table 顯示頁碼的地方 */
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.h4};
  }

  /* // table pagination center */
  .ant-pagination-total-text {
    display: flex;
    align-items: center;
  }

  // 只留間隔線條
  .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-right: 1px solid #c4c4c4;
  }

  .ant-table-selection-extra {
    top: -8px;
  }
  /* pagination 靠左 */
  /* .ant-pagination-total-text {
    flex: 1;
  } */

  /* 取消線條 */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border: none !important;
  }

  .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
  .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child {
    border-right: none !important;
  }

  .once {
    background-color: ${({ theme }) => theme.tableOnce};
  }
  .twoe {
    background-color: ${({ theme }) => theme.tableTwoe};
  }

  .clickRowStyle {
    background-color: ${({ theme }) => theme.tableOnClick};
  }
`;

const CustomTable = (props) => {
  return <CustomTableStyle bordered={props.Bordered} {...props} />;
};

export default CustomTable;
