import { useState, useEffect } from "react";
import { ResetStyle, GlobalStyle } from "./styles/globalStyles";
import { ThemeProvider } from "styled-components";
import { I18nextProvider } from "react-i18next";
import i18n from "./Language/i18n";
import zhTW from "antd/lib/locale/zh_TW";
import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import Highcharts from "highcharts/highstock";

import Cookies from "js-cookie";

import moment from "moment";
import "moment/locale/zh-tw";
import packageLan from "./Language/package/index";

import MyRoutes from "./routes";

//初始值
import StoreContext from "./components/Context/StoreContext";

//antd theme
import { light, dark, change_antd_theme } from "./styles/antdTheme/theme";
//styled theme
import { styledLight, styledDark } from "./styles/styledTheme";

// 權限在Layout
const defaultStoreConfig = {
  loading: false,
  auth: {
    ABNRPT: false,
    MC: false,
    MRB: false,
    NG: false,
    OEM: false,
    PDT: false,
    QC: false,
    QualityControl: false,
    SFC: false,
    WOC: false,
  },
  userData: {
    peopleNM: "",
  },
  wlArr: [],
};

function App() {
  const [storeConfig, setStoreConfig] = useState(defaultStoreConfig);
  const [c_systemConfig, set_c_systemConfig] = useState({
    locale: "zh-TW",
  });
  useEffect(() => {
    i18n.changeLanguage(c_systemConfig.locale);
  }, [c_systemConfig.locale]);
  //default setting
  useEffect(() => {
    moment.locale("zh_tw");
  }, []);

  // highChart 中文化
  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        viewFullscreen: "全屏",
        contextButtonTitle: "圖表導出菜單",
        decimalPoint: ".",
        downloadJPEG: "下載JPEG圖片",
        downloadPDF: "下載PDF文件",
        downloadPNG: "下載PNG文件",
        downloadSVG: "下載SVG文件",
        drillUpText: "返回 {series.name}",
        loading: "加載中",
        months: [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
          "十二月",
        ],
        noData: "沒有數據",
        numericSymbols: ["千", "兆", "G", "T", "P", "E"],
        printChart: "打印圖表",
        resetZoom: "恢復縮放",
        resetZoomTitle: "恢復圖表",
        shortMonths: [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
          "十二月",
        ],
        thousandsSep: ",",
        weekdays: ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期天"],
      },
    });
  }, []);

  // theme change
  useEffect(() => {
    if (storeConfig.theme === "light" || storeConfig.theme === "") {
      change_antd_theme(light);
      Cookies.set("theme", "light", { expires: 365 * 99 });
    } else if (storeConfig.theme === "dark") {
      change_antd_theme(dark);
      Cookies.set("theme", "dark", { expires: 365 * 99 });
    }
  }, [storeConfig.theme]);

  return (
    <div className="App">
      <ResetStyle />
      <GlobalStyle />
      <I18nextProvider i18n={i18n}>
        <ConfigProvider 
         locale={packageLan[c_systemConfig.locale]}
        >
          <StoreContext.Provider
            value={{
              storeData: storeConfig,
              setStoreData: setStoreConfig,
              c_systemConfig: c_systemConfig,
              set_c_systemConfig: set_c_systemConfig,
            }}
          >
            <ThemeProvider theme={storeConfig.theme === "dark" ? styledDark : styledLight}>
              <MyRoutes storeContext={storeConfig} />
            </ThemeProvider>
          </StoreContext.Provider>
        </ConfigProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
