import { login } from "@/service/api/publicAPI";

import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, message, Select } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import loginbgSvg from "@/assets/img/loginbg.svg";
import Logo from "@/assets/img/logo.png";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginbgSvg});
  background-size: cover;
  //background-repeat: no-repeat;
  background-position: center 110px;
  background-position: left;
  background-repeat: no-repeat;
  background-color: rgb(40, 98, 139);
  position: relative;
  //display: flex;
  //justify-content: flex-end;
  //align-self: center;
  //overflow :hidden;

  .logo {
    width: 100%;
  }

  .logoImg {
    height: 100%;
    width: 100%;
  }

  .logoSvg {
    position: absolute;
    top: 150px;
    left: 80px;
    height: 80px;
    width: 250px;
    z-index: 10;
  }

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 130px;
  left: 70px;
  width: 650px;
  height: 70%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    width: 550px;
  }
`;
const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 12px;
  font-family: Microsoft JhengHei;
  text-align: center;
`;
const StyledForm = styled(Form)`
  // 客製化輸入框寬度設定100
  .w-100 {
    width: 100%;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 5px !important;
  }

  .ant-input,
  .ant-select-selector {
    font-size: 20px;
  }

  .ant-select-selector {
    height: 46px !important;
  }

  .ant-input-suffix {
    font-size: 20px;
    color: #bebebe;
  }

  .ant-input::placeholder {
    color: #bebebe;
    font-size: 20px;
  }

  .ant-select-selector {
    border-radius: 5px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }
`;

function Login() {
  const [form] = Form.useForm();

  let navigate = useNavigate();

  const [buttonLodaing, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    setButtonLoading(true);
    console.log(values);
    const res = await login(values);
    setButtonLoading(false);
    if (res.status) {
      Cookies.set("wsID", res.data.wsIDs[0].wsID.wsID, { expires: 365 * 99 });
      Cookies.set("wsNM", res.data.wsIDs[0].wsID.wsNM, { expires: 365 * 99 });

      Cookies.set("wlID", res.data.wsIDs[0].wlIDs ? res.data.wsIDs[0].wlIDs[0]?.wlID : "", {
        expires: 365 * 99,
      });
      localStorage.setItem("allWsWl", JSON.stringify(res.data.wsIDs));
      Cookies.set("wosAuth", res.data.wsIDs[0].wsID.auth, {
        expires: 365 * 99,
      });

      Cookies.set("plantID", "prd", { expires: 365 * 99 });
      Cookies.set("plantNM", "正式版", { expires: 365 * 99 });

      message.success(res.msg);
      navigate("/");
    } else {
      message.error(res.msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //form.resetFields();
  };

  return (
    <Wrapper>
      {/* <LogoSvg className="logoSvg" /> */}
      <div className="logoSvg">
        <img src={Logo} className="logoImg" />
      </div>
      <Box>
        <div>
          <Title>帳戶登入-設備報工</Title>
          <StyledForm
            form={form}
            className="login-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size="large"
          >
            <Form.Item
              name="account"
              rules={[{ required: true, message: "請輸入正確的用戶名或郵件" }]}
            >
              <Input placeholder="請輸入帳號" />
            </Form.Item>
            <Form.Item name="pwd" rules={[{ required: true, message: "請輸入正確的密碼" }]}>
              <Input.Password size="large" placeholder="請輸入密碼" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
                loading={buttonLodaing}
              >
                登入
              </Button>
            </Form.Item>
          </StyledForm>
        </div>
      </Box>
    </Wrapper>
  );
}
export default Login;
