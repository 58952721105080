import { useEffect, useState } from "react";
import { Row, Col, Typography, Alert, Button, Space } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import TestPDF from "@/assets/pdf/pdfTest.pdf";
import MyButton from "@/components/Button/index";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { getDoc } from "@/service/api/wosAPI";
import useAPI from "@/hooks/useAPI";
import apiBasic from "@/service/APIBasic";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const { Title } = Typography;

export default function PDFview({ set_s_pdfModal, thisWpc, doctype }) {
  const [s_pdfInfo, set_s_pdfInfo] = useState({
    pageNumber: 1,
    pdfData: {},
    size: window.innerWidth /2,
    path: "",
  });

  const call_getDoc = useAPI(getDoc);

  const onDocumentLoadSuccess = ({ _pdfInfo }) => {
    set_s_pdfInfo((prevInfo) => ({ ...prevInfo, pdfData: _pdfInfo }));
  };

  const adjustPage = (adjustment) => {
    set_s_pdfInfo((prev) => ({
      ...prev,
      pageNumber: Math.max(prev.pageNumber + adjustment, 1),
    }));
  };

  const adjustSize = (adjustment) => {
    set_s_pdfInfo((prev) => ({
      ...prev,
      size: Math.max(prev.size + adjustment, 1),
    }));
  };

  useEffect(() => {
    call_getDoc.request({ PN: thisWpc.PN, doctype: doctype });
  }, []);

  useEffect(() => {
    if (call_getDoc.status === "suc") {
      const path = `${apiBasic.conn}://${apiBasic.url}/${call_getDoc.data.path}`;
      console.log("path：", path);
      set_s_pdfInfo((prev) => ({ ...prev, path: path }));
    }
  }, [call_getDoc.status]);

  return (
    <Row gutter={[12, 12]}>
      {s_pdfInfo.path === "" ? (
        <Col span={24}>
          <Alert message="No PDF file." type="error" size="large" />
        </Col>
      ) : (
        <>
          <Col span={24} className="flex justify-center items-center">
            <Space>
              <Button
                size="large"
                type="danger"
                icon={<MinusCircleOutlined className="text-[25px]" />}
                onClick={() => adjustSize(-100)}
              />
              <Title className="m-0" level={1}>
                {s_pdfInfo.pageNumber}/{s_pdfInfo.pdfData.numPages}
              </Title>
              <Button
                size="large"
                type="primary"
                icon={<PlusCircleOutlined className="text-[25px]" />}
                onClick={() => adjustSize(100)}
              />
            </Space>
          </Col>
          <Col span={3} className="flex justify-center items-center">
            <MyButton
              width={150}
              height={80}
              color="#3f51b5"
              size="large"
              type="primary"
              onClick={() => adjustPage(-1)}
              disabled={s_pdfInfo.pageNumber === 1}
            >
              <ArrowLeftOutlined className="text-[35px]" />
            </MyButton>
          </Col>
          <Col span={18} className="flex justify-center">
            <Document file={s_pdfInfo.path} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                width={s_pdfInfo.size}
                pageNumber={s_pdfInfo.pageNumber}
                className="border-solid border-2 border-gray-500/75 drop-shadow-2xl shadow-lg"
              />
            </Document>
          </Col>
          <Col span={3} className="flex justify-center items-center">
            <MyButton
              width={150}
              height={80}
              color="#3f51b5"
              size="large"
              type="primary"
              onClick={() => adjustPage(1)}
              disabled={s_pdfInfo.pageNumber === s_pdfInfo.pdfData.numPages}
            >
              <ArrowRightOutlined className="text-[35px]" />
            </MyButton>
          </Col>
        </>
      )}
    </Row>
  );
}
