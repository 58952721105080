import { Col, Row } from "antd";

import BMS from "./Module/BMS/index";

export default function AlexModuleArea({
  thisWpc,
  processManagementAuth,
  call_getAllWpc,
  set_call_chooseWpcCurrentStaff,
}) {
  return (
    <Row className="m-4">
      <Col span={24}>
        <BMS
          thisWpc={thisWpc}
          processManagementAuth={processManagementAuth}
          call_getAllWpc={call_getAllWpc}
        />
      </Col>
    </Row>
  );
}
