import { Button, Col, Divider, Form, Input, Row, Select, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { getAllAbn, resolve } from "@/service/api/wosAPI";

const { Option } = Select;
const { TextArea } = Input;

export default function Resolve({ thisWpc, setIsModal }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_getAllAbn = useAPI(getAllAbn);

  const call_resolve = useAPI(resolve);

  useEffect(() => {
    call_getAllAbn.request();
  }, []);

  useEffect(() => {
    if (call_resolve.status === "suc") {
      message.success(call_resolve.msg);
      setIsModal(false);
    } else if (call_resolve.status === "err") {
      message.error(call_resolve.msg);
    }
  }, [call_resolve.status]);

  const submit = async () => {
    const formData = await form.validateFields();
    call_resolve.request({ ...formData, ...thisWpc });
  };

  return (
    <Row>
      <Col span={24}>
        <Form form={form} name="resolveForm" layout="vertical" size="large">
          <Row gutter={[12, 12]}>
            <Col span={6}>
              <Form.Item label={t("WOS.util.abnSN")} name="SN" rules={[{ required: true }]}>
                <Select
                  showSearch
                  options={
                    call_getAllAbn?.data?.map((x) => ({
                      label: x.wlID + "_" + x.eventID + "_" + x.eventNM,
                      value: x.SN,
                      data: x,
                    })) || []
                  }
                  onChange={(value, option) => {
                    form.setFieldsValue(option.data);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={18}></Col>

            <Col span={12}>
              <Form.Item label={t("util.util.note")} name="rptmsg">
                <TextArea rows={6} disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={t("WOS.util.rptitemMsg")} name="rlsmsg">
                <TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Button
          type="primary"
          size="large"
          loading={call_resolve.status === "load"}
          onClick={submit}
        >
          {t("util.util.ok")}
          {/* 確認 */}
        </Button>
      </Col>
    </Row>
  );
}
