import { Button, Form, Input, InputNumber, Radio, Row, Select, message } from "antd";
import { render } from "bwip-js";
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { CustomTable } from "@/components/Custom/AntdCustom";
import SquareLight from "@/components/Light/SquareLight";
import Text from "@/components/Text";
// import { multipleIDtoName } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { addQoRec, getRunRec, qcresultQoRec } from "@/service/api/qcAPI";
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

// import { getDoc } from "@/service/apis/ADM/edm";
// import { getPF } from "@/service/apis/ADM/publicAPI";

// util component
// import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
// import { AddButton } from "@/components/PDS/Buttons";

// import Issued from "./Issued";
// import Notify from "./Notify";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [s_editing, set_s_editing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  // console.log("record = ", record);
  useEffect(() => {
    if (s_editing) {
      inputRef.current.focus();
    }
  }, [s_editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    set_s_editing(!s_editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async (dataIndex) => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      console.log("dataIndex = ", dataIndex);
      console.log("values[dataIndex] = ", values[dataIndex]);
      console.log("record = ", record);
      // 用來判斷目前是改哪一筆資料,
      // dataIndex 是欄位名稱
      // values[dataIndex] 是 dataIndex 改後的值
      // record 是修改的那筆資料
      handleSave(record, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = s_editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex === "realvalue" || dataIndex === "okC" || dataIndex === "ngC" ? (
          <InputNumber
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            min={0}
            style={{ width: "100%" }}
          />
        ) : dataIndex === "qcresult" ? (
          <Radio.Group
            ref={inputRef}
            // onchange={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
            className="flex"
          >
            <Radio value="G">合格</Radio>
            <Radio value="Y">不合格</Radio>
          </Radio.Group>
        ) : dataIndex === "isgetvalue" ? (
          <Radio.Group
            ref={inputRef}
            // onchange={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
            style={{ width: "100%" }}
            className="flex"
          >
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        ) : (
          <Input
            ref={inputRef}
            onPressEnter={() => save(dataIndex)}
            onBlur={() => save(dataIndex)}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap min-h-[25px] hover:cursor-pointer hover:border hover:border-solid hover:border-gray-300 hover:rounded-md hover:transition-all hover:duration-200 hover:ease-in-out"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        invisible
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
export default function TB({ c_lightData, is_full }) {
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  const call_getRunRec = useAPI(getRunRec);
  const call_addQoRec = useAPI(addQoRec);
  const call_qcresultQoRec = useAPI(qcresultQoRec);
  const handleSave = (data, dataIndex, value) => {
    //防止輸入非數字
    // if (isNaN(value)) return;
    console.log(data, dataIndex, value);
    console.log(
      "要傳給後端的資料 = ",
      {
        ...data,
        [dataIndex]: value,
      },
      dataIndex,
      value
    );
    // 編輯的資料
    const addData = {
      ...data,
      [dataIndex]: value,
      // 表頭資料
      qoN: c_lightData.qoN,
      itemno: c_lightData.itemno,
      insptype: c_lightData.insptype,
    };
    if (dataIndex === "qcresult") {
      call_qcresultQoRec.request(addData);
    } else {
      call_addQoRec.request(addData);
    }
  };
  // const newTableData = s_tableData.map((item) => {
  //   if (item.recno === data.recno) {
  //     return {
  //       ...item,
  //       [dataIndex]: value,
  //     };
  //   }
  //   return item;
  // });
  // set_s_tableData(newTableData);
  // };

  useEffect(() => {
    call_getRunRec.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    const { status, msg } = call_addQoRec;
    if (status === "suc") {
      message.success(msg);
      call_getRunRec.request(c_lightData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQoRec.status]);
  useEffect(() => {
    const { status, msg } = call_qcresultQoRec;
    if (status === "suc") {
      message.success(msg);
      call_getRunRec.request(c_lightData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_qcresultQoRec.status]);

  useEffect(() => {
    const { data, status, msg } = call_getRunRec;
    if (status === "suc") {
      console.log("call_getRunRec.data = ", data);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          key: x.itemno + x.qoN,
          UUID: x.itemno + x.qoN,
          UOM: c_lightData.UOM,
          UCL: c_lightData.UCL,
          LCL: c_lightData.LCL,
        })) || []
      );
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getRunRec.status]);
  // 計數型的欄位
  const count_tableColumns = [
    {
      title: "序",
      dataIndex: "recno",
      align: "center",

      width: "80px",
      // render: () => <MenuOutlined />,
    },
    {
      title: "良品數",
      width: "200px",
      dataIndex: "okC",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "okC",
        title: "良品數",
        handleSave: handleSave,
      }),
      // 等 i18N
    },
    {
      title: "不良數",
      width: "200px",
      dataIndex: "ngC",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "ngC",
        title: "不良數",
        handleSave: handleSave,
      }),
      // 等 i18N
    },
    {
      title: "是否取值",
      width: "150px",
      dataIndex: "isgetvalue",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "isgetvalue",
        title: "是否取值",
        handleSave: handleSave,
      }),
      render: (text) => {
        return <SquareLight size="20px" type={text === true ? "success" : "warning"} />;
      },
    },
    {
      title: "自動判定",
      width: "150px",
      dataIndex: "qcjudge",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "檢驗結果",
      width: "200px",
      dataIndex: "qcresult",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "qcresult",
        title: "檢驗結果",
        handleSave: handleSave,
      }),
      render: (text) => {
        if (text) {
          console.log("檢驗結果 = ", text);
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
  ];
  // 計量型的欄位
  const measure_tableColumns = [
    {
      title: "序",
      dataIndex: "recno",
      align: "center",
      width: "80px",
      // render: () => <MenuOutlined />,
    },
    {
      title: "測量值", // 序
      dataIndex: "realvalue",
      align: "center",
      // width: "100px",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "realvalue",
        title: "測量值",
        handleSave: handleSave,
      }),
    },
    {
      title: "單位",
      // width: "100",
      dataIndex: "UOM",
      align: "center",
    },
    {
      title: "管制上限",
      // width: "100%",
      dataIndex: "UCL",
      align: "center",
    },
    {
      title: "管制下限",
      // width: "100%",
      dataIndex: "LCL",
      align: "center",
    },
    {
      title: "是否取值",
      width: "150px",
      dataIndex: "isgetvalue",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "isgetvalue",
        title: "是否取值",
        handleSave: handleSave,
      }),
      render: (text) => {
        return <SquareLight size="20px" type={text === true ? "success" : "warning"} />;
      },
    },
    {
      title: "自動判定",
      width: "150px",

      dataIndex: "qcjudge",
      align: "center",
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
    {
      title: "合格判定",
      width: "200px",
      dataIndex: "qcresult",
      align: "center",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "qcresult",
        title: "合格判定",
        handleSave: handleSave,
      }),
      render: (text) => {
        if (text) {
          // G 合格 Y 不合格
          return <SquareLight size="20px" type={text === "G" ? "success" : "warning"} />;
        } else {
          return "";
        }
      },
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Text level={"h5"} className={`-mb-1 mt-2`}>
          檢驗記錄
        </Text>

        <div className="flex justify-between ">
          {/* <Button
            className={`bg-[#c44b4b] border-[#c44b4b] self-center mr-3`}
            size="middle"
            type="primary"
            // icon={<FullscreenOutlined />}
            onClick={() => {}}
          >
            {"匯入資料"}
          </Button> */}

          <Button
            className="bg-amber-500 border-amber-500 text-white
            hover:bg-amber-400 hover:border-amber-400
            focus:bg-amber-500 focus:border-amber-500"
            size="middle"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              // console.log("s_tableData.length = ", s_tableData.length + 1);
              const new_rec = {
                recno: s_tableData.length + 1,
                UOM: c_lightData.UOM,
                UCL: c_lightData.UCL,
                LCL: c_lightData.LCL,
                insptype: c_lightData.insptype,
                inspitem: c_lightData.inspitem,
                inspkeynote: c_lightData.inspkeynote,
                isgetvalue: true,
                qcresult: "",
                // 以下為計數型的欄位，寫在一起沒關係，多傳的欄位後端不會接收
                okC: c_lightData.insptype === "count" ? 0 : null,
                ngC: c_lightData.insptype === "count" ? 0 : null,
              };
              // console.log("c_lightData = ", c_lightData);
              // console.log("new_rec = ", new_rec);
              set_s_tableData((prev) => [...prev, new_rec]);
            }}
          />
        </div>
      </div>
      <CustomTable
        // rowClassName={""}
        className="w-full"
        // is_full 放大版使用
        scroll={{ y: is_full ? 700 : 130 }}
        // dataSource={[c_lightData, c_lightData]}
        dataSource={s_tableData}
        rowClassName={(record, index) =>
          index % 2 === 1 //隔行變色
            ? "once w-full"
            : "twoe w-full"
        }
        columns={c_lightData.insptype === "count" ? count_tableColumns : measure_tableColumns}
        pagination={false}
        components={components}
      />
    </>
  );
}
