import apiBasic from "../APIBasic";

const wsConn = apiBasic.conn === "http" ? "ws" : "wss";

export const staffSocket = `${wsConn}://${apiBasic.url}/api/staffSocket`;

export const openSocket = `${wsConn}://${apiBasic.url}/api/openSocket`;

export const boardSocket = `${wsConn}://${apiBasic.url}/api/board/boardSocket`;

export const scanSocket = `${wsConn}://${apiBasic.url}/api/scanner/scanSocket`;

export const getSpcDetailSocket = `${wsConn}://${apiBasic.url}/api/spc/getSpcDetail`;
