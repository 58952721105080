import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// import { addDoc, updateDoc } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import ModuleAreaTable from "@/pages/NewQualityControl/ModuleArea/Tableindex";
import { UploadOutlined } from "@ant-design/icons";

const ModalContainer = ({ c_lightData, set_s_isShowModal }) => {
  return <ModuleAreaTable flag={"HD"} is_full={true} />;
};

export default ModalContainer;
