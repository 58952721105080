import { createGlobalStyle } from "styled-components";

export const ResetStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont,'Noto Sans TC', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
address, caption, cite, code, dfn, em, strong, th, var, b {
  font-weight: normal;
  font-style: normal;
}
abbr, acronym {
  border: 0;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  text-size-adjust: 100%;
  box-sizing: border-box;
}
body {
    line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote {
  &:before,   &:after {
    content: '';
    content: none;
  }
}
q {
  &:before,   &:after {
    content: '';
    content: none;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption, th {
  text-align: left;
}
textarea {
  resize: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  padding: 0;
  border: none;
  background: none;
}
/* 滾動條樣式 */
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;

}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
  border-radius: 10px;
}

`;

// *default font-size equal 1 rem*/
export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size:14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  }

  .flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .pdt-rate-text span{
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
 
  .ant-form-item-explain-error{
    font-size:22px;
  }
  .ant-input,.ant-btn,.ant-picker{
    border-radius: 5px !important;
  }
  .ant-empty-description, .ant-input[disabled], .ant-input .ant-input-disabled{
    
    color: #000  !important;
  }
  .ant-input-disabled::placeholder{
    color: #000  !important;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000  !important;
  }

  .ant-input .ant-input-disabled ,.ant-input-affix-wrapper-disabled{
    color:rgba(0, 0, 0, 1) !important;
  }

  .ant-form-item-label>label{
    font-size: 21px !important;
  }

  .ant-divider-horizontal{
    margin: 12px 0 !important;
  }
  
  .ant-message {
    font-weight: 600;
    font-size:40px !important;
    color: #595959 !important;
  }
  .ant-message .anticon {
    font-weight: 600;
    font-size:40px !important;
  }
  .ant-message .ant-message-notice-content{
    background-color: #FFDD79 !important;
  }
  

  .w-100{
    width:100% ;
  }
  .w-90{
    width:90% ;
  }
  .w-80{
    width:80% ;
  }
  .w-50{
    width:50% ;
  }

  .h-100{
    height:100% ;
  }
  .h-80{
    height:80% ;
  }
  .h-50{
    height:50% ;
  }
  .m-0{
    margin: 0 !important;
  }
  .m-8{
    margin: 8px;
  }
  .mt-10{
    margin-top: 10px;
  }
  .mt-20{
    margin-top: 20px;
  }
  .mt-30{
    margin-top: 30px;
  }
  .mt-35{
    margin-top: 35px;
  }
  .ml-8{
    margin-left: 8px;
  }
  .align-l{
    text-align: left;
  }
  .align-r{
    text-align: right;
  }
  .pl-5{
    padding-left: 5px;
  }
  .pr-5{
    padding-right: 5px;
  }
  .pr-16{
    padding-right:16px;
  }
  .pl-16{
    padding-left: 16px;
  }
`;
