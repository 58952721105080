import { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { CustomModal } from "@/components/Custom/AntdCustom";
import MyButton from "@/components/Button";
import Text from "@/components/Text";

import WpcLogin from "./WpcLogin";
import WpcLogout from "./WpcLogout";
import { useTranslation } from "react-i18next";

export default function WpcSend({ setIsModal }) {
  const { t } = useTranslation();

  const [isWpcLogin, setIsWpcLogin] = useState(false);

  const [isWpcLogout, setIsWpcLogout] = useState("def");

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col span={12} className="flex justify-center items-center">
          <MyButton height={127} width={180} onClick={() => setIsWpcLogin(true)}>
            <Text level="h2">
          
              {"SCAN WO"}
            
            </Text>
          </MyButton>
        </Col>
        <Col span={12} className="flex justify-center items-center">
          <MyButton disabled height={127} width={180} onClick={() => setIsWpcLogout("true")}>
            <Text level="h2">
          
              {"NEW WO"}
           
            </Text>
          </MyButton>
        </Col>
      </Row>

      <CustomModal
        key="isWpcLogin"
        title={"SCAN WO"}
        // "途單登錄"
        visible={isWpcLogin}
        onCancel={() => setIsWpcLogin(false)}
        footer={null}
        width="80%"
        afterClose={() => setIsModal(false)}
      >
        {isWpcLogin && <WpcLogin setIsModal={setIsWpcLogin} />}
      </CustomModal>

      <CustomModal
        key="isWpcLogout"
        title={"NEW WO"} //"途單撤登"
        visible={isWpcLogout === "true"}
        onCancel={() => setIsWpcLogout("false")}
        footer={null}
        width="80%"
        afterClose={() => setIsModal(false)}
      >
        <WpcLogout setIsModal={setIsWpcLogout} />
      </CustomModal>
    </div>
  );
}
