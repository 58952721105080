import { Button, Col, PageHeader, Row } from "antd";
import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CustomModal } from "@/components/Custom/AntdCustom";
import Text from "@/components/Text";

import ButtonArea from "./ButtonArea"; //主操作區
import DrawingModal from "./Modal/DrawingModal";
import ModuleArea from "./ModuleArea"; //模組管理
import PrepareArea from "./PrepareArea"; //良品&不良品登錄
import QCList from "./QCList/index";
import WoInfoArea from "./WoInfoArea"; //途單資訊

const WlWocStyle = styled.div`
  //  background-color:gray;
  //每個區塊外面的邊框
  .normal-component {
    background-color: ${({ theme }) => theme.componentBg};
    margin: 8px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
    z-index: 10;
  }
  .nobg-normal-component {
    margin: 8px;
    border-radius: 8px;
    z-index: 10;
  }
  .special-component {
    margin: 8px;
    /* padding: 8px 16px 0px 16px; */
    border-radius: 8px;
    z-index: 10;
  }
`;

export const WlWocContext = createContext({});

const QualityControl = () => {
  const { t } = useTranslation();
  const [thisWpc, setThisWpc] = useState({});
  // 是否開啟「檢驗開單」的 modal
  const [s_showAddQo, set_s_showAddQo] = useState(false);
  // 接受 rtn 上來的 call_getAllWpc.req
  const [call_getAllWpc, setCall_getAllWpc] = useState([]);
  const [s_siptype, set_s_siptype] = useState(Cookies.get("siptype"));
  const [s_isInspection, set_s_isInspection] = useState(false);
  const [s_isFianlInspection, set_s_isFianlInspection] = useState(false);
  const [s_isShowDrawing, set_s_isShowDrawing] = useState(false);

  const [processManagementAuth, setProcessManagementAuth] = useState({
    okProductsLoginButton: true,
    noProductsLoginButton: true,
    mcComfirmButton: true,
    mcCancelButton: true,
    prefixWorkButton: true,
    suffixWorkButton: true,
  });

  const headerExtra = [
    {
      name: "新開檢驗",
      className: "border-[#C00000 ] bg-[#C00000]",
      onClick: () => setThisWpc({}),
    },
    // {
    //   name: "抽樣程序",
    //   className: "mr-2",
    //   onClick: () => alert("抽樣程序"),
    // },
    // {
    //   name: "允收水準",
    //   className: "mr-2",
    //   onClick: () => alert("允收水準"),
    // },
    // {
    //   name: "抽樣計畫",
    //   className: "mr-2",
    //   onClick: () => alert("抽樣計畫"),
    // },
    {
      name: "圖面預覽",
      className: "mr-2 border-[#C00000 ] bg-[#C00000]",
      onClick: () => set_s_isShowDrawing(true),
    },
    // {
    //   name: "SIP預覽",
    //   className: "border-[#71AFF8] text-[#71AFF8]",
    //   onClick: () => alert("SIP預覽"),
    // },
  ];
  // 生成 headerExtra
  const generateHeaderExtra = () => {
    return headerExtra.map((item) => (
      <Button
        size="small"
        type="primary"
        className={`h-[40px] border-[#B4C7E7 ] bg-[#B4C7E7]
          ${item.className}
        `}
        onClick={item.onClick}
      >
        <Text level="h4">{item.name}</Text>
      </Button>
    ));
  };

  // 在這邊判斷按鈕權限
  useEffect(() => {
    // 如果thisWpc 有這個權限 就用它的
    // true 代表 disabled , false 代表不 disabled
    switch (thisWpc.lifeF) {
      case "L":
        setProcessManagementAuth({
          passButton: false,
          waiverButton: false,
          quarantineButton: false,
          confirmButton: true,
          cancelButton: true,
        });
        break;
      case "P":
        setProcessManagementAuth({
          passButton: false,
          waiverButton: true,
          quarantineButton: true,
          confirmButton: false,
          cancelButton: false,
        });
        break;
      case "Q":
        setProcessManagementAuth({
          passButton: true,
          waiverButton: true,
          quarantineButton: false,
          confirmButton: true,
          cancelButton: false,
        });
        break;
      case "W":
        setProcessManagementAuth({
          passButton: true,
          waiverButton: false,
          quarantineButton: true,
          confirmButton: false,
          cancelButton: false,
        });
        break;
      case "G":
        setProcessManagementAuth({
          passButton: true,
          waiverButton: true,
          quarantineButton: true,
          confirmButton: true,
          cancelButton: true,
        });
        break;
      default:
        setProcessManagementAuth({
          passButton: true,
          waiverButton: true,
          quarantineButton: true,
          confirmButton: true,
          cancelButton: true,
        });
    }
  }, [thisWpc]);
  // siptype
  return (
    <WlWocStyle>
      <WlWocContext.Provider
        value={{
          s_siptype,
          set_s_siptype,
          s_showAddQo,
          set_s_showAddQo,
          thisWpc: thisWpc, // 最下面的table => c_lightData
          c_call_getAllWpc: call_getAllWpc, //
          processManagementAuth: processManagementAuth,
          s_isInspection: s_isInspection, // 是否檢驗中
          set_s_isInspection: set_s_isInspection,
          s_isFianlInspection: s_isFianlInspection, // 是否最終檢驗
          set_s_isFianlInspection: set_s_isFianlInspection,
        }}
      >
        <PageHeader
          className="px-2"
          title={
            <Text level="h2" className=" bg-[#7c79d2] text-white">
              {t("WOS.util.qc")}
            </Text>
          }
          extra={generateHeaderExtra()}
        />

        <Row justify="center" align="">
          <Col span="12">
            <div className="normal-component">
              <ButtonArea thisWpc={thisWpc} />
            </div>
            <div className="normal-component">
              <ModuleArea
                thisWpc={thisWpc}
                processManagementAuth={processManagementAuth}
                call_getAllWpc={call_getAllWpc}
              />
            </div>
          </Col>

          <Col span="12">
            <WoInfoArea thisWpc={thisWpc} />

            <PrepareArea
              thisWpc={thisWpc}
              call_getAllWpc={call_getAllWpc}
              processManagementAuth={processManagementAuth}
            />
          </Col>

          <Col span="24">
            <QCList
              thisWpc={thisWpc}
              setThisWpc={setThisWpc}
              setCall_getAllWpc={setCall_getAllWpc}
              wpcType="qc"
            />
          </Col>
        </Row>
        <CustomModal
          title={"圖面預覽"}
          width="80%"
          open={s_isShowDrawing}
          footer={null}
          onCancel={() => set_s_isShowDrawing(false)}
        >
          <DrawingModal thisWpc={thisWpc} set_s_isShowDrawing={set_s_isShowDrawing} />
        </CustomModal>
      </WlWocContext.Provider>
    </WlWocStyle>
  );
};

export default QualityControl;
