import { Tabs } from "antd";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CustomTabs from "@/components/Custom/CustomTab";

import AbnormalTab from "./AbnormalTab";
import StartTab from "./StartTab";

const { TabPane } = Tabs;

const ButtonAreaStyle = styled.div``;

export default function ButtonArea() {
  const { ASSY, INJE } = JSON.parse(Cookies.get("wosAuth"));

  const { t } = useTranslation();
  return (
    <ButtonAreaStyle>
      <CustomTabs size="large">
        {INJE && (
          <TabPane
            tab={`INJE ${t("WOS.util.lifeF_B")}`}
            // "報開工"
            key="1"
          >
            <StartTab ASSY={ASSY} INJE={INJE} />
          </TabPane>
        )}
        {ASSY && (
          <TabPane
            tab={`ASSY ${t("WOS.util.lifeF_B")}`}
            // "報開工"
            key="2"
          >
            <StartTab ASSY={ASSY} INJE={INJE} />
          </TabPane>
        )}

        <TabPane
          tab={t("WOS.wlwoc.abcHandle")}
          // "異常處理"
          key="4"
        >
          <AbnormalTab />
        </TabPane>
      </CustomTabs>
    </ButtonAreaStyle>
  );
}
