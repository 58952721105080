import { Button, Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";

import { CustomTable } from "@/components/Custom/AntdCustom";
import checkUndefined from "@/util/checkUndefined";

export default function WpcDetailModal({ setIsModal, thisWpc }) {
  const { t } = useTranslation();
  const wpcDetailsTableCol = [
    {
      title: t("util.util.woN"), //"工單號",
      dataIndex: "woN",
      key: "woN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.wpcset"), // "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.wpcSN"), //"途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.prefix"), // "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.suffix"), //"後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      key: "planqty",
      render: (str) => checkUndefined(str),
    },
    {
      //planQty - d_planQty - d_inC
      title: t("WOS.util.goal") + t("WOS.util.qty"), //"目標產量",
      dataIndex: "goalqty",
      key: "goalqty",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.d_planqty"), // "前拆數量",
      dataIndex: "d_planqty",
      key: "d_planqty",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.d_inC"), //"後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.offfix"), // "下線碼",
      dataIndex: "offfix",
      key: "offfix",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.woMix"), // "多單碼",
      dataIndex: "woMix",
      key: "woMix",
      render: (num) => checkUndefined(num),
    },
    {
      title: t("util.util.lineMix"), //"混線碼",
      dataIndex: "lineMix",
      key: "lineMix",
      render: (num) => checkUndefined(num),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <CustomTable columns={wpcDetailsTableCol} dataSource={[thisWpc]} pagination={false} />
      </Col>
      <Divider />
      <Col span={24} className="text-right">
        <Button size="large" onClick={() => setIsModal(false)}>
          {t("util.util.close")}
          {/* 關閉 */}
        </Button>
      </Col>
    </Row>
  );
}
