export const formatAmount = (amount, num = 0) => {
  if (amount === null || amount === undefined) return "0";

  amount = parseFloat(amount);
  if (isNaN(amount)) return "0";

  return amount % 1 === 0
    ? // 如果amount是整數，不需要保留小數點
      amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : // 否則，保留小數點至第二位
      amount.toFixed(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
