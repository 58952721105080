import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, message } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getPwcID, getWlID, getWsID } from "@/service/api/dispachManage";
import { getAllWpcset, getPNs, manualWoN } from "@/service/api/wosAPI";

export default function ArtificialWorkOrder({ setIsModal }) {
  const { Option } = Select;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_getPNs = useAPI(getPNs);
  const call_getAllWpcset = useAPI(getAllWpcset);
  //call 人工工單
  const call_manualWoN = useAPI(manualWoN);
  const call_getPwcID = useAPI(getPwcID);
  const call_getWlID = useAPI(getWlID);
  const call_getWsID = useAPI(getWsID);

  const [s_pwcIDOpions, set_s_pwcIDOpions] = useState([]);

  //form 日期綁定 不能選擇今天之前
  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  const formSub = async () => {
    let getFrom = await form.validateFields();
    getFrom.planqty = getFrom.planqty - 0;
    getFrom.pwcID = getFrom.pwcID.join("|");
    getFrom["issueday"] = moment();
    console.log("人工工單", getFrom);
    call_manualWoN.request(getFrom);
  };

  useEffect(() => {
    call_getPNs.request();
    call_getPwcID.request();
    call_getWlID.request();
    call_getWsID.request();
  }, []);

  useEffect(() => {
    if (call_getAllWpcset.status === "suc") {
      message.success(call_getAllWpcset.msg);
    } else if (call_getAllWpcset.status === "err") {
      message.error(call_getAllWpcset.msg);
    }
  }, [call_getAllWpcset.status]);

  useEffect(() => {
    if (call_manualWoN.status === "suc") {
      message.success(call_manualWoN.msg);
      setIsModal("false");
    } else if (call_manualWoN.status === "err") {
      message.error(call_manualWoN.msg);
    }
  }, [call_manualWoN.status]);

  useEffect(() => {
    if (call_getWlID.status === "err") {
      message.error(call_getWlID.msg);
    }
  }, [call_getWlID.status]);

  return (
    <Form form={form} name="artificialWorkOrderForm" layout="vertical" size="large">
      {/* 不顯示欄位 */}
      <Form.Item name="wpcset" noStyle />
      <Form.Item name="wpcSN" noStyle />
      <Row justify="center" gutter={[24, 0]}>
        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("util.util.ws")}
                {/* 工站 */}
              </Text>
            }
          >
            <Input placeholder={Cookies.get("wsID")} disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {/* 工線 */}
                {t("util.util.wl")}
              </Text>
            }
          >
            <Input placeholder={Cookies.get("wlID")} disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.util.pwcID")}
                {/* 工作中心 */}
              </Text>
            }
            name="pwcID"
            shouldUpdate
            rules={[
              { required: true, message: t("util.util.placeholder_select") + t("WOS.util.pwcID") }, // "請選擇工作中心"
            ]}
          >
            <Select
              mode="multiple"
              placeholder={t("util.util.placeholder_select") + t("WOS.util.pwcID")}
              // "選擇工作中心"
              options={s_pwcIDOpions}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {/* 計畫產量 */}
                {t("WOS.util.planqty")}
              </Text>
            }
            name="planqty"
            rules={[
              {
                required: true,
                message: t("util.util.placeholder_input") + t("WOS.util.planqty"),
                //"請輸入計畫產量",
                validator: async (_, value) => {
                  if (!value) {
                    throw new Error(t("util.util.placeholder_input") + t("WOS.util.planqty")); //"請輸入計畫產量",
                  } else if (isNaN(Number(value)) || Number(value) < 1) {
                    throw new Error("計畫產量必須大於0且為數字");
                  }
                },
              },
            ]}
          >
            <Input placeholder={t("util.util.placeholder_input") + t("WOS.util.planqty")} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("util.util.woN")}
                {/* 工單號 */}
              </Text>
            }
            name="woN"
            rules={[
              { required: true, message: t("util.util.placeholder_input") + t("util.util.woN") },
            ]}
          >
            <Input placeholder={t("util.util.placeholder_input") + t("util.util.woN")} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.util.wotype")}

                {/* 單別 */}
              </Text>
            }
          >
            <Input placeholder={t("WOS.util.wotype_0")} disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item noStyle name="planhead" />

          <Form.Item
            label={
              <Text level="h4">
                {t("util.util.PN")}
                {/* 料號 */}
              </Text>
            }
            name="PN"
            rules={[
              { required: true, message: t("util.util.placeholder_select") + t("util.util.PN") },
            ]}
          >
            <Select
              showSearch
              placeholder={t("util.util.placeholder_select") + t("util.util.PN")}
              // "請選擇料號"
              optionFilterProp="children"
              onChange={(e, rowData) => {
                form.setFieldsValue({
                  pdtNM: rowData.data.pdtNM,
                  PN: rowData.data.PN,
                  wpcset: undefined,
                  planhead: undefined,
                  stdmh: undefined,
                  stdwh: undefined,
                  pwcID: undefined,
                });
                if (e) {
                  call_getAllWpcset.request(rowData.data);
                }
              }}
            >
              {call_getPNs.data?.map((x, i) => {
                return (
                  <Option key={`${i}+${x.wpcset}+${x.PN}`} value={`${x.PN}-${x.wpcset}`} data={x}>
                    {x.PN}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.PN !== currentValues.PN}
          >
            {() => (
              <Form.Item
                label={
                  <Text level="h4">
                    {t("util.util.pdtNM")}
                    {/* 品名 */}
                  </Text>
                }
                name="pdtNM"
              >
                <Input
                  placeholder={t("util.util.placeholder_select") + t("util.util.pdtNM")}
                  // "請選擇料號"
                  disabled
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("util.util.placeholder_select") + t("WOS.util.wpcset")}
                {/* 途程組 */}
              </Text>
            }
            name="wpcset"
            rules={[
              { required: true, message: t("util.util.placeholder_select") + t("WOS.util.wpcset") }, //"請選擇途程組" }
            ]}
          >
            <Select
              showSearch
              placeholder={t("util.util.placeholder_select") + t("WOS.util.wpcset")}
              onChange={(value, data) => {
                if (data.data.length > 0) {
                  const pwcID = data.data[0]?.pwcID;
                  const _pwcID = pwcID.split("|");
                  set_s_pwcIDOpions(
                    call_getPwcID.data?.reduce((data, curr) => {
                      pwcID.includes(curr.pwcID) &&
                        data.push({
                          label: curr.pwcID + "_" + curr.pwcNM,
                          value: curr.pwcID,
                          data: curr,
                        });
                      return data;
                    }, []) || []
                  );
                  form.setFieldsValue({ ...data.data[0], pwcID: _pwcID });
                }
              }}
              options={
                call_getAllWpcset.data?.map((x) => ({
                  label: `${x.wpcHd.wpcsetNM} / ${x.wpcs[0].stdmh} / ${x.wpcs[0].stdwh} / ${x.wpcs[0].planhead}`,
                  value: x.wpcHd.wpcset,
                  data: x.wpcs,
                })) || []
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.wlwoc.stdmh")}
                {/* 標準機時 */}
              </Text>
            }
            name="stdmh"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.wlwoc.stdmh")}
                {/* 標準工時 */}
              </Text>
            }
            name="stdwh"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.wlwoc.planhead")}
                {/* 計畫人數 */}
              </Text>
            }
            name="planhead"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.wlwoc.issueday")}
                {/* 發佈日期 */}
              </Text>
            }
            name="issueday"
          >
            <Input placeholder={moment(new Date()).format("yyyy-MM-DD")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <Text level="h4">
                {t("WOS.wlwoc.dueday")}
                {/* 預計完成日期 */}
              </Text>
            }
            name="dueday"
            rules={[
              {
                required: true,
                message: t("util.util.placeholder_select") + t("WOS.wlwoc.dueday"),
                // "請選擇完成日期"
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("util.util.placeholder_select") + t("WOS.wlwoc.dueday")}
              // "請選擇完成日期"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={24} className="text-right">
          <Space size={20}>
            <Button onClick={() => setIsModal("false")}>
              {t("util.util.cancel")}
              {/* 取消 */}
            </Button>
            <Button type="primary" onClick={formSub} loading={call_manualWoN.status === "load"}>
              {t("util.util.ok")}
              {/* 確認 */}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
