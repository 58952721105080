import { useState, useEffect, useRef } from "react";
import { Row, Col, PageHeader, message, Input, Button, Space } from "antd";
import CustomTable from "@/components/Custom/CustomTable";
import styled from "styled-components";
import checkUndefined from "@/util/checkUndefined";

import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getAllWpc } from "@/service/api/publicAPI";
import moment from "moment";
import { prepare } from "@/service/api/wosAPI";
import { useTranslation } from "react-i18next";

export default function BatchPrepare({ setIsModal }) {
  const { t } = useTranslation();
  const input_ref = useRef(null);
  const [s_searchData, set_s_searchData] = useState({
    queryString: undefined,
    lifeF: `'1'`, //後端要的 出事找後端
    page: 1,
    pageSize: 50,
  });
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_selectedRows, set_s_selectedRows] = useState([]);
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const call_getAllWpc = useAPI(getAllWpc);
  const call_prepare = useAPI(prepare);

  const getData = () => call_getAllWpc.request(s_searchData);

  const onSelectChange = (_, newSelectedRows) => {
    set_s_selectedRows(newSelectedRows);
  };
  const rowSelection = {
    selectedRowKeys: s_selectedRows.map((x) => x.key),
    onChange: onSelectChange,
  };

  const tableCol = [
    {
      title: t("util.util.wlID"), // "線別",

      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("util.util.woN"), //"工單號",
      dataIndex: "woN",
      width: "150px",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.batchno"), //"批號",
      dataIndex: "batchID",
      width: "130px",
    },
    {
      title: t("util.util.lifeFT"), //"歷程",
      dataIndex: "lifeFstr",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.lifeFT"), //"時間",
      dataIndex: "lifeFT",
      key: "lifeFT",
      align: "center",
      render: (str) => {
        return checkUndefined(moment(str).format("YYYY-MM-DD HH:mm:ss"));
      },
    },
    {
      title: t("util.util.opcode"), //"單別",
      dataIndex: "wotypeStr",
      align: "center",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.PN"), //"料號",
      dataIndex: "PN",
      width: "260px",
      align: "cneter",
      className: "row-min-width col-white-space",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.pdtNM"), //"品名",
      dataIndex: "pdtNM",
      width: "280px",
      className: "row-min-width col-white-space",
      render: (str) => {
        return checkUndefined(str);
      },
    },
    {
      title: t("util.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    // {
    //   title: "目標產量",
    //   dataIndex: "goalqty",
    //   align: "right",
    //   render: (str) => checkUndefined(str),
    // },
    {
      title: t("util.util.inC"), //"投產數量",
      dataIndex: "inC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.batchC"), //"批號拆分量",
      dataIndex: "batchC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.qty"), //"可用數量",
      dataIndex: "qty",
      align: "right",
      onCell: (rowData) => ({
        style: { background: "#f9e9d7ac", color: rowData.qty === 0 ? "red" : "none" },
      }),
      render: (str) => checkUndefined(str),
    },
  ];

  useEffect(() => {
    input_ref.current.focus();
  }, []);

  useEffect(() => {
    getData();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      message.success(call_getAllWpc.msg);
      set_s_tableData(
        call_getAllWpc.data.tableData.map((x, i) => ({
          ...x,
          key: i,
          lifeFstr: t(`WOS.util.lifeF_${x.lifeF}`),
        }))
      );
      set_s_pageInfo(call_getAllWpc.data.pageInfo);
    } else if (call_getAllWpc.status === "err") {
      message.error(call_getAllWpc.msg);
      set_s_tableData([]);
    }
  }, [call_getAllWpc.status]);

  useEffect(() => {
    if (call_prepare.status === "suc") {
      message.success(call_prepare.msg);
      setIsModal(false);
    } else if (call_prepare.status === "err") {
      message.error(call_prepare.msg);
    }
  }, [call_prepare.status]);

  return (
    <Row gutter={[12, 12]} className="text-right">
      <Col span={24}>
        <Space>
          <Input.Search
            ref={input_ref}
            enterButton={t("util.util.search")}
            // "查詢"
            placeholder={t("util.util.placeholder_input") + t("util.util.woN") + t("util.util.PN")}
            // "請輸入工單號或料號"
            onPressEnter={getData}
            value={s_searchData.queryString}
            onChange={(e) => set_s_searchData((prev) => ({ ...prev, queryString: e.target.value }))}
            style={{ width: "300px" }}
          />
        </Space>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableCol}
          dataSource={s_tableData}
          rowSelection={rowSelection}
          scroll={{ y: 600 }}
          size="small"
          pagination={{
            total: s_pageInfo.totalItems,
            current: s_pageInfo.page,
            pageSize: s_pageInfo.pageSize,
            onChange: (current) => set_s_pageInfo({ ...s_pageInfo, page: current }),
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
              </Text>
            ),
          }}
          rowClassName={(record, index) =>
            record.light
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
        />
      </Col>
      <Col span={24}>
        <Space size={20}>
          <Button size="large">
            {t("util.util.cancel")}
            {/* 取消 */}
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => call_prepare.request(s_selectedRows)}
            disabled={s_selectedRows.length === 0}
          >
            {t("util.util.ok")}
            {/* 確認 */}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
