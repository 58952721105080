import { useState, useEffect, useRef } from "react";
import { Row, Col, Input, Divider, Space, Button, message, Modal, Select, Spin } from "antd";
import styled from "styled-components";

import Text from "@/components/Text";
import { CustomModal } from "@/components/Custom/AntdCustom";
import useAPI from "@/hooks/useAPI";
import { confirm } from "@/service/api/qcAPI";
import { getAllWpc } from "@/service/api/publicAPI";
import { IconScanBarcode } from "@/components/Icon";
import { close } from "@/service/api/wosAPI";
import numberWithCommas from "@/util/numberWithCommas";

import { useTranslation } from "react-i18next";

const Box = styled.div`
  background-color: ${({ theme }) => theme.componentBg};
  height: 208px;
  margin-top: 5px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 16%) 2px 6px 20px;
  z-index: 10;
`;

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 0;
    margin: auto;
    height: 120px;
    width: 2px;
    background-color: #d9d9d9;
  }
`;

const CustomSearch = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-lg {
    border: none !important;
  }
  .ant-input-suffix {
    position: absolute;
    right: 40px;
    top: 17%;
    color: red;
    /* width: 100px; */
    /* height: 100px; */
    font-size: 30px;
    z-index: 9999;
  }
  .ant-input-lg {
    font-size: 31px !important;
    border: none !important;
    padding-left: 4px !important;
  }
  .ant-input {
    padding-left: 20px !important;
  }
  .ant-btn {
    height: 62px;
    font-size: 23px;
    font-weight: 600;
    display: none;
  }
  input::placeholder {
    color: #000 !important;
    font-weight: 600;
  }
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .ant-input-wrapper:focus {
    border: none !important;
  }
  .ant-input-affix-wrapper::before {
    border: none !important;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-input {
    background-color: #eb8282 !important;
    border-radius: 9999px !important;
  }
  .ant-input:focus {
    color: #000 !important;
    background-color: #73cc7f !important;
  }
`;
const initBarcodeObj = {
  woN: null,
  PN: null,
  wsID: null,
};
export default function Close() {
  const { t } = useTranslation();
  const searchRef = useRef(null);

  const [s_enterText, set_s_enterText] = useState("");

  const [s_focusText, set_s_focusText] = useState(
    t("WOS.wlwoc.pleaseScanBarCode")
    // "請掃入條碼"
  );

  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  // show
  const [s_alertMsg, set_s_alertMsg] = useState(
    t("WOS.wlwoc.noBarcode")
    // "暫無條碼"
  );
  //barcode分解出來的OBJ
  const [s_barcodeObj, set_s_barcodeObj] = useState({});

  const [s_isCheckModal, set_s_isCheckModal] = useState(false); // 是否顯示檢查對話框的狀態

  const [s_thisWpc, set_s_thisWpc] = useState({});

  const call_getAllWpc = useAPI(getAllWpc);

  const call_close = useAPI(close);
  const call_confirm = useAPI(confirm);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      message.success(call_getAllWpc.msg);
      set_s_alertMsg(call_getAllWpc.msg);
      // console.log(call_getAllWpc.data.tableData[0]);
      set_s_thisWpc(call_getAllWpc.data.tableData[0]);
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表

      message.error(call_getAllWpc.msg);
    }
  }, [call_getAllWpc.status]);

  // 這邊的主要是對條碼進行解析和處理
  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        set_s_alertMsg(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        // set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        set_s_alertMsg(`
          ${t("WOS.wlwoc.noWoNScan")}
          ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      set_s_alertMsg(`${t("WOS.wlwoc.barcodeParsingFailure")}
        ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      // set_s_tableData([]);
    }
  }, [is_barcodeInput]);

  useEffect(() => {
    if (Object.keys(s_barcodeObj).length > 0) {
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    }
  }, [s_barcodeObj]);

  useEffect(() => {
    if (call_confirm.status === "suc") {
      message.success(call_confirm.msg);
      set_s_thisWpc({});
    } else if (call_confirm.status === "err") {
      message.error(call_confirm.msg);
    }
  }, [call_confirm.status]);
  useEffect(() => {
    if (call_close.status === "suc") {
      message.success(call_close.msg);
      if (s_barcodeObj.opcode !== "3310") {
        call_confirm.request(s_thisWpc);
        return;
      }
      set_s_thisWpc({});
    } else if (call_close.status === "err") {
      message.error(call_close.msg);
    }
  }, [call_close.status]);

  const closeCheck = () => {
    Modal.confirm({
      title: (
        <Text level="h3">
          {t("WOS.wlwoc.isBillTheWoN")}?{/* 是否對該筆工單進行結單？ */}
        </Text>
      ),
      content: (
        <div className="w-full">
          <Text level="h3" className="whitespace-pre-wrap">
            {t("WOS.wlwoc.PleaseMakeSureTheProductionProcedureIsCorrectFirst")}，
            {t("WOS.wlwoc.retake")}
            {t("WOS.wlwoc.statement")}
          </Text>
        </div>
      ),
      okText: t("util.util.ok"), //"確認",
      okType: "primary",
      cancelText: t("util.util.cancel"), //"取消",
      centered: true,
      width: "600px",
      onOk() {
        call_close.request(s_thisWpc);
      },
    });
  };
  return (
    <Spin spinning={call_close.status === "load" || call_confirm.status === "load"}>
      <Row gutter={[12, 6]}>
        <Col span={24}>
          <CustomSearch
            ref={searchRef}
            autoFocus
            size="large"
            allowClear
            placeholder={s_focusText}
            suffix={<IconScanBarcode className="text-[26px]" />}
            value={s_enterText}
            onFocus={() => set_s_focusText(t("WOS.util.focusText"))}
            onBlur={() => set_s_focusText(t("WOS.wlwoc.pleaseClickThisInputBoxFirst"))}
            onChange={(e) => {
              set_s_enterText(e.target.value);
            }}
            onSearch={() => {
              set_s_enterText("");
              set_s_showBarcode(s_enterText);
              set_is_barcodeInput(true);
            }}
          />
        </Col>
        <Col span={24} className="mb-5">
          <Row className=" w-full  mb-5">
            <div className="bg-[#cedee9] w-full ">
              <Text
                level="h3"
                type="error"
                style={{ width: "100%", whiteSpace: "normal", wordWrap: "break-word" }}
              >
                <span className="text-[#6C6C6C] font-bold">{t("util.util.systemReport")}:</span>
                {s_thisWpc.lifeF ? ` ${t(`WOS.util.lifeF_${s_thisWpc.lifeF}`)},` : ""}
                {s_alertMsg}
              </Text>
            </div>
          </Row>

          <Row className="bg-[#F0F9FF]">
            <Col span={12}>
              <Row className="w-full flex ">
                <Col span={24}>
                  <div className="flex justify-start  w-full  ">
                    <div className="flex      w-[35%]">
                      <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                        "util.util.wo"
                      )}`}</div>
                      <div className="whitespace-normal   text-[30px]"> :</div>
                    </div>
                    <span className="text-black  self-center text-[25px] font-bold  ">
                      {s_thisWpc.woN ? `${s_thisWpc.woN} ${s_thisWpc.opcode}` : ""}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="flex justify-start  w-full  ">
                    <div className="flex      w-[35%]">
                      <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                        "util.util.PN"
                      )}`}</div>
                      <div className="whitespace-normal  text-[30px]"> :</div>
                    </div>
                    <span className="text-black self-center text-[25px] font-bold  ">
                      {s_thisWpc.PN ?? ""}
                    </span>
                  </div>
                </Col>
                <Col span={24} className=" ">
                  <div className="flex justify-start  w-full  ">
                    <div className="flex      w-[35%]">
                      <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                        "util.util.custPN"
                      )} `}</div>
                      <div className="whitespace-normal  text-[30px]"> :</div>
                    </div>
                    <span className="text-[#39883d] self-center text-[25px] font-bold  ">
                      {s_thisWpc.dealPN ?? ""}
                    </span>
                  </div>
                </Col>
                <Col span={24} className=" ">
                  <div className="flex justify-start  w-full  ">
                    <div className="flex      w-[35%]">
                      <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                        "util.util.pdtNM"
                      )}`}</div>
                      <div className="whitespace-normal  text-[30px]"> :</div>
                    </div>
                    <span className="text-[#3eb390]   w-[50%]  self-center text-[25px] font-bold  ">
                      {s_thisWpc.pdtNM ?? ""}
                    </span>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="flex justify-start  w-full  ">
                    <div className="flex      w-[35%]">
                      <div className="whitespace-pre-wrap  w-[80%] ml-2 font-bold  text-[30px]">{`${t(
                        "util.util.planqty"
                      )}`}</div>
                      <div className="whitespace-normal  text-[30px]"> :</div>
                    </div>
                    <span className="text-[#3e4ab3] self-center text-[25px] font-bold  ">
                      {s_thisWpc.planqty ? `${s_thisWpc.planqty}` : ""}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Box className="bg-[#FFF8F2]">
                <Row justify="center" align="middle" gutter={[128, 0]} className="h-full">
                  <Col span={5} className="flex  flex-col justify-center items-center">
                    <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                      {t("WOS.wlwoc.inC")}
                    </Text>
                    <span className="text-[32px] text-[#4A83D1]">
                      {numberWithCommas(s_thisWpc?.inC) ?? 0}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col justify-center items-center ">
                    <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                      {t("WOS.wlwoc.ok_count")}
                    </Text>
                    <span className="text-[32px] font-bold text-[#77D160]">
                      {numberWithCommas(s_thisWpc?.okC ?? 0)}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col justify-center items-center ">
                    <Text level="h2" className="w-[150px] text-center whitespace-pre-line ">
                      {t("WOS.wlwoc.ng_count")}
                    </Text>
                    <span className="text-[32px] text-[#F37E7E]">
                      {numberWithCommas(s_thisWpc?.ngC ?? 0)}
                    </span>
                  </Col>
                  <Line />
                  <Col span={5} className="flex flex-col justify-center items-center ">
                    <Text level="h2" className="w-[170px] text-center whitespace-pre-line ">
                      {t("WOS.util.bal")}
                    </Text>
                    <span className="text-[32px] text-gray-300">
                      {numberWithCommas(s_thisWpc?.bal ?? 0)}
                    </span>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Col>

        <Divider />
        <Col span={24} className="text-right">
          <Space size={20}>
            <Button
              type="primary"
              size="large"
              className="h-[50px]"
              disabled={
                !(
                  Object.keys(s_thisWpc).length !== 0 &&
                  ["2", "3", "4", "8", "B"].includes(s_thisWpc.lifeF)
                )
              }
              loading={call_close.status === "load" || call_confirm.status === "load"}
              onClick={closeCheck}
            >
              <Text level="h4"> {"WO CLOSE"}</Text>
            </Button>
          </Space>
        </Col>

        <CustomModal
          key="prepare"
          onCancel={() => set_s_isCheckModal(false)}
          footer={null}
          title={
            <Text level="h3">
              {t("WOS.wlwoc.isBillTheWoN")}?{/* 是否對該筆工單進行登錄？ */}
            </Text>
          }
          visible={s_isCheckModal}
          width="600px"
          // onCancel={() => set_s_isCheckModal(false)}
        >
          <div>
            <Spin spinning={call_close.status === "load" || call_confirm.status === "load"}>
              <Row gutter={[0, 20]}>
                <Col span={24} className="">
                  <div className="w-ful bg-red-500">
                    <Text level="h3" className="whitespace-pre-wrap">
                      {t("WOS.wlwoc.PleaseMakeSureTheProductionProcedureIsCorrectFirst")}
                    </Text>
                    <Text level="h3" className="whitespace-pre-wrap">
                      ，{t("WOS.wlwoc.retake")}
                    </Text>
                    <Text level="h3" className="whitespace-pre-wrap">
                      {t("WOS.wlwoc.statement")}
                      {/* 結單 */}
                    </Text>
                  </div>
                </Col>
                <Col span={24} className="text-right">
                  <Space size={20}>
                    {/* <Button size="large" onClick={() => setIsModal(false)}>
            {t("util.util.cancel")}
   
          </Button> */}
                    <Button
                      type="primary"
                      size="large"
                      disabled={
                        !(
                          Object.keys(s_thisWpc).length !== 0 &&
                          ["2", "3", "4", "8", "B"].includes(s_thisWpc.lifeF)
                        )
                      }
                      loading={call_close.status === "load" || call_confirm.status === "load"}
                      onClick={async () => {
                        // call_wpc.request([{ ...s_thisWpc, wlID: s_We }]);
                        const call_close = await close([s_thisWpc]);

                        if (call_close.status) {
                          message.success(call_close.msg);

                          return;
                        }
                        if (!call_close.status) {
                          message.error(call_close.msg);

                          return;
                        }
                      }}
                    >
                      {/* {t("util.util.ok")} */}
                      {"WO CLOSE 報完工"}
                      {/* 確認 */}
                    </Button>
                  </Space>
                </Col>
              </Row>

              <Space size={0}>
                {/* <Text level="h3" className="inline">
              {t("WOS.util.pwcID")}
       
            </Text>
            <Select
              showSearch
              allowClear
              placeholder={t("WOS.util.pwcID")}
            
              filterOption={(inputValue, option) =>
                (option.label + option.value).includes(inputValue)
              }
              size="large"
              style={{ margin: "5px 0px", width: "300px" }}
              options={
                call_getWpcPwcID?.data?.map((x) => ({
                  label: `${x.pwcID}_${x.pwcNM}`,
                  value: x.pwcID,
                })) || []
              }
              value={s_chooseData.pwcID}
              onChange={(e) => set_s_chooseData((prev) => ({ ...prev, pwcID: e }))}
            /> */}
              </Space>
            </Spin>
          </div>
        </CustomModal>
      </Row>
    </Spin>
  );
}
