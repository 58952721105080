import { Layout } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import Logo from "@/assets/img/logo.png";

import Text from "../Text";

const { Footer } = Layout;

const MainFooter = styled(Footer)`
  background-color: ${({ theme }) => theme.pageBg};
  border-top: 0.1px solid rgb(124 124 124 / 10%);
  padding: 8px 24px;
  height: 45px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Time = (timeProps) => {
  const theme = useTheme();

  const [time, setTime] = useState(moment().format("yyyy-MM-DD\u00A0\u00A0HH:mm"));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(moment().format("yyyy-MM-DD\u00A0\u00A0HH:mm:ss"));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Text level="h2" color={theme.timeColor}>
      {time}
    </Text>
  );
};

const MyFooter = (props) => {
  return (
    <div className={props.className}>
      <MainFooter>
        <img src={Logo} alt="富鴻齊科技" style={{ height: "90%" }} />
        <Time />
      </MainFooter>
    </div>
  );
};
export default MyFooter;
