import { Col, Input, Row, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomModal, CustomTable } from "@/components/Custom/AntdCustom";
import { IconScanBarcode } from "@/components/Icon";
import Text from "@/components/Text";
import useAPI from "@/hooks/useAPI";
import { getAllWpc } from "@/service/api/publicAPI";
import checkUndefined from "@/util/checkUndefined";

import WpcDetailModal from "./WpcDetailModal";

export default function WpcList({ setCall_getAllWpc, thisWpc, setThisWpc, className, wpcType }) {
  const { Search } = Input;
  const { t } = useTranslation();
  const [s_enterText, set_s_enterText] = useState("");
  //儲存用
  const [s_showBarcode, set_s_showBarcode] = useState("");
  // 控制effect用
  const [is_barcodeInput, set_is_barcodeInput] = useState(false);
  const [s_barcodeObj, set_s_barcodeObj] = useState({});
  const initBarcodeObj = {
    woN: null,
    PN: null,
    wsID: null,
  };
  const [s_chooseData, set_s_chooseData] = useState({
    qty: undefined, // 選擇的數量
    pwcID: undefined, // 選擇的PwcID
  });
  const [queryString, setQueryString] = useState("");

  const [pageInfo, setPageInfo] = useState({
    page: 1, //第一頁
    pageSize: 10, //回傳筆數

    // server res
    totalItems: 0,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 0,
    endItemNumber: 0,
  });

  const [tableData, setTableData] = useState([]);

  const [isWpcDatailModal, setIsWpcDatailModal] = useState(false);

  const call_getAllWpc = useAPI(getAllWpc);

  const tableColumns = [
    // {
    //   title: t("util.util.status"), // "狀態",
    //   dataIndex: "situF",
    //   width: "80px",
    //   align: "center",
    //   render: (str) => {
    //     switch (str) {
    //       case "B":
    //         return <Light size="20px" type="main" />;
    //       case "G":
    //         return <Light size="20px" type="success" />;
    //       case "Y":
    //         return <Light size="20px" type="warning" />;
    //       case "R":
    //         return <Light size="20px" type="error" />;
    //       default:
    //         return <Light size="20px" disabled />;
    //     }
    //   },
    // },
    {
      title: t("WOS.util.wotype"), //"單別",
      dataIndex: "opcode",
      width: "100px",
      align: "center",
      render: (str, data) => {
        const opcodeStr = {
          3310: "INJE",
          3320: "ASSY",
          3330: "WIPP",
        };
        return opcodeStr[str];
      },
    },
    {
      title: t("WOS.util.wo"), //"工單",
      dataIndex: "woN",
      width: "150px",
      // render: (str) => (str),
    },
    {
      title: t("WOS.util.lifeF"), //"歷程"
      dataIndex: "lifeFstr",
      align: "center",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.lifeFT"), //時間
      dataIndex: "lifeFT",
      key: "lifeFT",
      align: "center",
      render: (str) => (
        <div>
          <div>{checkUndefined(moment(str).format("MM-DD"))}</div>
          <div>{checkUndefined(moment(str).format("HH:mm"))}</div>
        </div>
      ),
    },
    {
      title: t("WOS.util.We"), // "機碼",
      dataIndex: "wlID",
      width: "90px",
      align: "center",
    },
    {
      title: t("WOS.util.pdtRate"), //"pdtRate",
      dataIndex: "pdtRate",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    // {
    //   title: "混線",
    //   dataIndex: "linemixStr",
    //   key: "linemixStr",
    //   width: "90px",
    //   align: "center",
    //   render: (str) => {
    //     return checkUndefined(str);
    //   },
    // },

    // {
    //   title: t("util.util.batchno"), //"批號",
    //   dataIndex: "batchID",
    //   width: "130px",
    // },

    // {
    //   title: t("WOS.util.opcode"), //"單別"
    //   dataIndex: "wotypeStr",
    //   align: "center",
    //   render: (str) => checkUndefined(str),
    // },
    {
      title: t("WOS.util.PN"), //"料號",
      dataIndex: "PN",
      width: "200px",
      align: "center",
      className: "row-min-width col-white-space",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.custPN"), //"客戶料號",
      dataIndex: "dealPN",
      width: "200px",
      align: "left",
      className: "row-min-width col-white-space",
      render: (str) => checkUndefined(str),
    },
    // {
    //   title: t("util.util.pdtNM"), //"品名",
    //   dataIndex: "pdtNM",
    //   width: "280px",
    //   className: "row-min-width col-white-space",
    //   render: (str) => checkUndefined(str),
    // },
    {
      title: t("WOS.util.planqty"), //"計畫產量",
      dataIndex: "planqty",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    // {
    //   title: "目標產量",
    //   dataIndex: "goalqty",
    //   align: "right",
    //   render: (str) => checkUndefined(str),
    // },
    {
      title: t("WOS.util.inC"), //"投產數量",
      dataIndex: "inC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.okC"), //"ok數量",
      dataIndex: "okC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("WOS.util.ngC"), //"ng數量",
      dataIndex: "okC",
      align: "right",
      render: (str) => checkUndefined(str),
    },
    {
      title: t("util.util.mcC"), //"ok數量",
      // dataIndex: "okC",
      align: "right",
      render: (str, data) => checkUndefined(data.okC - data.ngC),
    },
    {
      title: t("WOS.util.prepareT"), //"ok數量",
      dataIndex: "prepareT",
      align: "right",
      render: (str) => (
        <div>
          <div>{checkUndefined(moment(str).format("MM-DD"))}</div>
          <div>{checkUndefined(moment(str).format("HH:mm"))}</div>
        </div>
      ),
    },
    {
      title: t("WOS.util.closeT"), //"ok數量",
      dataIndex: "closeT",
      align: "right",
      render: (str) => (
        <div>
          <div>{checkUndefined(moment(str).format("MM-DD"))}</div>
          <div>{checkUndefined(moment(str).format("HH:mm"))}</div>
        </div>
      ),
    },

    // {
    //   title: t("WOS.util.batchC"), // "批號拆分量",
    //   dataIndex: "batchC",
    //   align: "right",
    //   render: (str) => checkUndefined(str),
    // },
    // {
    //   title: t("WOS.util.qty"), //"可用數量",
    //   dataIndex: "qty",
    //   align: "right",
    //   onCell: (rowData) => ({
    //     style: { background: "#f9e9d7ac", color: rowData.qty === 0 ? "red" : "none" },
    //   }),
    //   render: (str) => checkUndefined(str),
    // },
    // {
    //   title: t("WOS.util.wpc"), //"途程",
    //   dataIndex: "action",
    //   width: "90px",
    //   // className: "row-last-col-width",
    //   align: "center",
    //   render: (_, recode) => (
    //     <DetailsButton
    //       // 點擊 "途程" 應該阻止冒泡
    //       onClick={(event) => {
    //         thisWpc?.UUID === recode.UUID && event.stopPropagation();
    //         setIsWpcDatailModal(true);
    //       }}
    //     />
    //   ),
    // },
  ];

  // 將 call_getAllWpc.request return 上去 方便各個地方調用
  const rtnGetAllWpc = (obj) => {
    const { request } = call_getAllWpc;
    return () => request(obj);
  };
  useEffect(() => {
    try {
      if (!is_barcodeInput) return;
      set_is_barcodeInput(false);
      if (!s_showBarcode || s_showBarcode === "") {
        message.error(`${t("WOS.wlwoc.noBarcode")}`); //暫無條碼
        set_s_barcodeObj(initBarcodeObj);
        // set_s_tableData([]);
        return;
      }

      // 將 s_showBarcode 這邊要parse
      const parseBarcode = JSON.parse(s_showBarcode);

      if (!parseBarcode) {
        //非工單掃碼
        message.error(`
          ${t("WOS.wlwoc.noWoNScan")}
          ，${s_showBarcode}`);
        set_s_barcodeObj(initBarcodeObj);
        return;
      }
      console.log(parseBarcode);
      set_s_chooseData((prev) => ({ ...prev, qty: parseBarcode.batchbom }));

      // call_barcodeOperationWpc.request({ ...s_searchData, ...parseBarcode });
      // 設定 s_alertMsg 為 s_showBarcode 的值
      // set_s_alertMsg(
      //   `${t("util.util.woN")}：${parseBarcode.woN ?? t("WOS.util.none")}
      //       ，${t("util.util.PN")}：${parseBarcode.PN ?? t("WOS.util.none")}，${t(
      //     "WOS.util.batchID"
      //   )}：${
      //     parseBarcode.batchID ?? t("WOS.util.none")
      //     //無
      //   }，
      //     ${t("WOS.util.batchbom")}
      //     ：${parseBarcode.batchbom ?? t("WOS.util.none")}`
      // );

      // 使用函式更新 s_barcodeObj 狀態
      set_s_barcodeObj((prev) => ({
        ...prev,
        ...parseBarcode,
      }));
    } catch (error) {
      message.error(`${t("WOS.wlwoc.barcodeParsingFailure")}
        ，${s_showBarcode}`); // 條碼解析失敗
      set_s_barcodeObj(initBarcodeObj);
      // set_s_tableData([]);
    }
  }, [is_barcodeInput]);

  // 將 call_getAllWpc.request return 上去 方便各個地方調用
  useEffect(() => {
    call_getAllWpc.request({ ...pageInfo, queryString: queryString, wpcType: wpcType });
  }, []);

  useEffect(() => {
    call_getAllWpc.request({ ...pageInfo, queryString: queryString, wpcType: wpcType });
  }, [pageInfo.page]);

  useEffect(() => {
    if (call_getAllWpc.status === "suc") {
      setPageInfo((prev) => ({ ...prev, ...call_getAllWpc.data.pageInfo }));
      setTableData(
        call_getAllWpc.data.tableData?.map((x) => {
          return {
            ...x,
            key: x.UUID,
            // wlID: Cookies.get("wlID"),
            // wsID: Cookies.get("wsID"),
            linemixStr:
              x.linemix === 1
                ? t("WOS.util.linemix_1")
                : x.linemix === 2
                ? t("WOS.util.linemix_2")
                : t("WOS.util.linemix_0"),
            // wotype: x.wotype === 0 ? "正常工單" : x.wotype === 1 ? "重工工單" : "no Data",
            wotype:
              x.wotype === 0
                ? t("WOS.util.wotype_0")
                : x.wotype === 1
                ? t("WOS.util.wotype_1")
                : "",
            lifeFstr: t(`WOS.util.lifeF_${x.lifeF}`),
          };
        })
      );
      message.success(call_getAllWpc.msg);
    } else if (call_getAllWpc.status === "err") {
      // 只要失敗就清空途單列表
      setTableData([]);
      message.error(call_getAllWpc.msg);
    }
    setCall_getAllWpc((other) =>
      rtnGetAllWpc({ ...pageInfo, queryString: queryString, wpcType: wpcType, ...other })
    );
  }, [call_getAllWpc.status]);

  useEffect(() => {
    // 將 queryString,pageInfo 重新 return  call_getAllWpc.request 的參數
    setCall_getAllWpc((other) =>
      rtnGetAllWpc({ ...pageInfo, queryString: queryString, wpcType: wpcType, ...other })
    );
  }, [queryString, pageInfo]);

  useEffect(() => {
    // getAllWpc 更新時 重新取得當前途單最新資料
    if (Object.keys(thisWpc).length > 0) {
      // 當前目錄尋找thisWpc
      const rtn = tableData.filter((x) => x.UUID === thisWpc.UUID)[0];
      if (rtn) {
        setThisWpc({ ...thisWpc, ...rtn });
      } else {
        setThisWpc({});
      }
    } else {
      setThisWpc(tableData[0] ? tableData[0] : {});
    }
  }, [tableData]);

  useEffect(() => {
    if (Object.keys(s_barcodeObj).length > 0) {
      // console.log(s_barcodeObj);
      // console.log({
      //   wpcType: "",
      //   queryString: s_barcodeObj.jobN,
      //   opcode: s_barcodeObj.opcode,
      // });
      call_getAllWpc.request({
        wpcType: "woc",
        queryString: s_barcodeObj.jobN,
        opcode: s_barcodeObj.opcode,
      });
    }
  }, [s_barcodeObj]);

  return (
    <Row className={className}>
      <Col span={15}>
        <Text level="h2" type="primary">
          {t("WOS.util.Waybill") + t("WOS.util.index")}
          {/* 途單索引 */}
        </Text>
      </Col>
      <Col span={9} className="  flex justify-between items-center">
        <Search
          // ref={searchRef}
          placeholder={
            // t("WOS.util.focusText")
            // + "/" + t("util.util.PN")
            "SCAN WO"
          }
          // placeholder={s_focusText}
          value={s_enterText}
          allowClear
          enterButton={<IconScanBarcode className="text-[17px]" />}
          autoFocus
          size="large"
          // value={s_searchData.queryString}
          onChange={(e) => {
            set_s_enterText(e.target.value);
          }}
          onSearch={() => {
            set_s_enterText("");
            set_s_showBarcode(s_enterText);
            set_is_barcodeInput(true);
          }}
          // onSearch={() =>
          //   call_getWpc.request({
          //     ...pageInfo,
          //     ...s_searchData,
          //   })
          // }
        />
        {/* <Search
          placeholder={
            t("util.util.placeholder_input") +
            t("WOS.util.complete") +
            t("util.util.wo") +
            "/" +
            t("util.util.batchno")
            //請輸入完整個工單/批號
          }
          allowClear
          //  enterButton
          size="large"
          onChange={(e) => setQueryString(e.target.value)}
          onSearch={() =>
            call_getAllWpc.request({ ...pageInfo, wpcType: wpcType, queryString: queryString })
          }
        /> */}
        <Search
          placeholder={
            // t("util.util.placeholder_input") +
            // t("WOS.util.complete") +
            // t("util.util.wo") +
            // "/" +
            // t("util.util.batchno")
            //請輸入完整個工單/批號
            "INPUT WO"
          }
          allowClear
          //  enterButton={<IconScanBarcode className="text-[23px]"/>}
          enterButton
          size="large"
          onChange={(e) => setQueryString(e.target.value)}
          onSearch={() =>
            call_getAllWpc.request({ ...pageInfo, wpcType: wpcType, queryString: queryString })
          }
        />
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          dataSource={tableData}
          size="small"
          loading={call_getAllWpc.status === "load"}
          scroll={{ y: 185 }}
          rowClassName={(record, index) =>
            record.UUID === thisWpc?.UUID
              ? "clickRowStyle"
              : index % 2 === 1 //隔行變色
              ? "once"
              : "twoe"
          }
          onRow={(record) => {
            return {
              onClick: () => (record.UUID === thisWpc?.UUID ? setThisWpc({}) : setThisWpc(record)),
            };
          }}
          pagination={{
            total: pageInfo.totalItems,
            current: pageInfo.page,
            pageSize: pageInfo.pageSize,
            onChange: (current, size) =>
              setPageInfo((prev) => {
                // 如果有更動pageSize 回第一頁
                let checkPage = prev.pageSize !== size ? 1 : current;
                return { ...prev, page: checkPage, pageSize: size };
              }),
            showTotal: (total, range) => (
              <Text level="h4" vice>
                {t("util.util.showTotal", {
                  total: total,
                  rangeFirst: range[0],
                  rangeEnd: range[1],
                })}
                {/* 共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項 */}
              </Text>
            ),
          }}
        />
      </Col>
      {/* detailModal */}
      <CustomModal
        // title="途單索引詳情"
        key="isWpcDatailModal"
        title={t("WOS.util.wpcList") + t("util.util.detail")}
        visible={isWpcDatailModal}
        onCancel={() => setIsWpcDatailModal(false)}
        footer={null}
        width="80%"
      >
        <WpcDetailModal setIsModal={setIsWpcDatailModal} thisWpc={thisWpc} />
      </CustomModal>
    </Row>
  );
}
