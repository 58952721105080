const defaultColor = {
  error: "#FF0000", //錯誤
  reError: "#699F5B", // 錯誤回復
  warning: "#FFB300", //警告
  success: "#1BCF00", //成功
  info: "#0E7CF6",
  textShadow: "rgb(0 0 255 / 50%)", //文字陰影
};

export const styledLight = {
  primary: "#7ab1f4",
  focus: "#0000FF",
  timeColor: "#00009A",
  textColor: "rgba(0, 0, 0, 0.9)",
  reTextColor: "rgba(255, 255, 255, 0.9)",
  componentBg: "rgb(255, 255, 255)",
  specialComponentBg: "rgba(48,48,48,0.06)",
  tableOnce: "rgba(239, 239, 239, 1)",
  tableTwoe: "rgba(248, 248, 248, 1)",
  tableOnClick: "rgba(30, 150, 183, 0.22)",
  disabledBg: "#EBEEF1",
  disabledText: "#C4C4C4",
  borderColor: "rgba(0, 0, 0, 0.06)", //線條顏色
  ...defaultColor,
};

export const styledDark = {
  primary: "#5995BC",
  focus: "#FFFF00",
  timeColor: "#FFFF00",
  textColor: "rgba(255, 255, 255, 0.9)",
  reTextColor: "rgba(0, 0, 0, 0.9)",
  componentBg: "#424242",
  specialComponentBg: "rgba(48,48,48,1)",
  tableOnce: "#303030",
  tableTwoe: "#424242",
  tableOnClick: "rgba(162,162,132,0.58)",
  disabledBg: "#545454",
  disabledText: "#959595",
  borderColor: "#6C6C6C", //線條顏色
  ...defaultColor,
};
